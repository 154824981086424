export default function Structure(code) {
  const structure = {
    error: {
      theme: "structure error",
      overview: "structure error",
    },
    101: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) extends the digestive range down to the R LEVEL. The underlying tone is positive however the expectation is that the market can produce a break down to the R LEVEL which would be a buying opportunity. If the DP supports price action and the market starts to build positive structure, it is a sign of strength targeting the upper CriticalRange extreme or UP (UPSIDE PIVOT). Trading below the DP (DOWNSIDE PIVOT) is expected to be more of a soft sideways trade down to the R LEVEL. A break below the R LEVEL is more likely a “false” negative signal setting up a REVERSAL BUY opportunity.`,
    },
    102: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) creates a support range with the DP (DOWNSIDE PIVOT) that defines the lower extreme of this technical state. The sentiment bias will maintain an underlying positive tone with all trading above the DP-DT1 R LEVEL price band. Expectations are for prices to find support in this zone for a rally targeting the upper resistance band at the UP. A break below the DT1 R LEVEL is a sign of weakness that has the contract vulnerable to a negative transitional shift.`,
    },
    103: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL to DP (DOWNSIDE PIVOT) range defines the lower support extreme of this technical state. Anticipate an underlying positive sentiment bias with all trading above the DP-R LEVEL price band. If the market is going to keep its legs under it, it will build a base above here with the expectation for a rally targeting the upper resistance band at the UP. A break below the R LEVEL is a sign of weakness that signals a break in market structure and has the contract vulnerable to a negative transitional shift.`,
    },
    104: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The Sentiment Bias (R LEVEL) that is equal to the lower CriticalRange Support extreme [DP-DOWNSIDE PIVOT] defines the expected low point for any digestive trade. This market state, although digestive in nature, will have a positive bias above this inflection point. If the market is going to maintain a firm trade, it will build a base above the DP R LEVEL for a rally targeting the upper Critical Range extreme at the UP. The positive sentiment will provide encouragement to any UP (UPSIDE PIVOT) BREAKOUT but the sideways nature of the state must be respected as extended follow through is unlikely. A break below the DP R LEVEL is a sign of weakness that has the contract vulnerable to a negative transitional shift. Don’t fight negative price structure below the DP R LEVEL. `,
    },
    105: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL in the middle of the CriticalRange signals a potential turning point in the current market state. This is a dynamic signature state with the potential to spark a low volatility “one-way” trade for the balance of the session or a sideways rotation around the CriticalRange mid-point. Look for the MKT’s ability to build structure or not as the “tell”. The important thing to note about this market structure is that a decision is about to be made. Identifying which theme is going to dominate will typically happen early in the session. An optimal tightening of the “coil” will typically happen within the CR+ and CR-. Held price structure after a breach of either of these parameters is a “tell” that the Hedge theme is dominating and the MKT may be ready to breakout.`,
    },
    106: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (Upside Pivot) gives the market a negative tone below this price point. Typically in this market structure bias, price is searching for its lower digestive extreme. Use caution. Difficult stop and go trading conditions are expected for any new negative price action. A rise above the R LEVEL is expected to produce a more definitive positive shift in opinion with a good chance of producing a transition higher. The key to any transitional signal will be the market’s ability to maintain both price and market structure.`,
    },
    107: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+ `,
      overview: `The R LEVEL to UP (UPSIDE PIVOT) range defines the upper extreme of this technical state providing a negative sentiment bias below this price band. Typically in this structure, the market is searching for its lower digestive extreme with this zone acting as the lid for the negative tone. Use caution on any new press lower as difficult stop and go trading conditions are expected. A rise above the R LEVEL is expected to produce a more definitive positive shift in opinion with a good chance of sparking a transition higher. If a transition is going to occur, the price action will maintain positive price and market structure above the UP.`,
    },
    108: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) creates a range that defines the upper extreme of this technical state providing a negative sentiment bias below the R LEVEL. Typically in this behavioral framework, the market is searching for its lower digestive extreme with this zone acting as a hard upper lid. Price action will usually define either the UP or the R LEVEL as the digestive “lid” with the R LEVEL the primary resistance. Use caution on any new press lower as difficult stop and go trading conditions are expected. A rise above the R LEVEL is expected to produce a more definitive positive shift in opinion with a good chance of producing a transition higher. If a transition is going to occur, the price action will maintain positive price and market structure.`,
    },
    109: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) extends the digestive zone up to the R LEVEL. The underlying tone is negative however the expectation is that the market can produce a rally up to the R LEVEL which would be a selling opportunity. One “tell” to look for that sets up a “corrective” rally up to R LEVEL is a positive transitional signal through the DIR (DIRECTIONAL).  If the UP contains price action and the market starts to build negative structure, it is a sign of weakness targeting the lower CriticalRange extreme or DP (DOWNSIDE PIVOT). Trading above the UP (UPSIDE PIVOT) is expected to be more of a firm sideways trade up to the R LEVEL. A breakout above the R LEVEL is more likely a “false” positive signal setting up a REVERSAL SELL opportunity. Any negative price structure below the DIR has the potential to feed on itself and spark a transitional trade.`,
    },
    111: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The market has produced a positive signal against underlying negative momentum. The current structure has the sentiment bias R LEVEL below the DT1 (DOWNSIDE TARGET #1) which is an indication that the market may have one squeeze lower before the positive signal takes effect. This is due to the fact that the real buying energy is below the market at the R LEVEL. A break in positive structure at higher levels can turn into nice counter bias “corrective” trades that target the DP or R LEVEL. Remember, negative emotion above the R LEVEL are opportunities to FADE after the emotion subsides. Only below the R LEVEL is the positive signal negated.`,
    },
    112: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The market has produced a positive signal against underlying negative momentum. The sentiment bias below the market at the DT1 (DOWNSIDE TARGET #1) is an indication that the positive signal has not run its course and will remain in effect above the R Level. BUY opportunities are a better option within the DP-DT1 R LEVEL range, as the positive corrective signal will remain in effect above this price band. On the short side, SELL REVERSAL strategies are the best as the MKT is “probing” higher. Any early session strength that fails is vulnerable to a break down to the R LEVEL support. Use the DIR (DIRECTIONAL) failure to confirm a negative shift after a higher trade. A BREAKOUT below the R LEVEL does have the potential to produce a counter signal sell-off however follow through is most likely limited to the DT1 as the NEUTRAL part of this trade state must be respected.`,
    },
    113: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The MKT has produced a positive signal in a NEUTRAL trading environment. Follow through of the positive signal is questionable with the most likely outcome being a difficult sideways trade. The R LEVEL below the market is a “tell” that there is a real potential for a counter signal break that will bottom out above the R LEVEL. Keep leverage light as difficult conditions are expected.`,
    },
    114: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The MKT is in a NEUTRAL CORRECTIVE position with the most likely outcome being a difficult sideways trade. The negative momentum has been broken, so beware of getting sucked into "false" failure especially early in the session. There is the potential for a sharp, short covering rally so don't FADE a rise that continues to hold structure. The R LEVEL plays a key role in this technical state as it defines the condition of the current “corrective” move and determines the strength or weakness of the corrective signal. In this signature state, the R LEVEL is below the market which implies that the corrective action is just a buying opportunity above the R LEVEL. A breakdown below the R LEVEL however and the expectation is that the negative momentum will try to assert itself. `,
    },
    115: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `Technically the MKT is in a NEUTRAL POSITIVE SHIFT market state with momentum balanced, putting the MKT back "on the fence". Opportunity exists on both sides of the MKT with the potential of being a turning point session. The most likely outcome of this state is for a difficult sideways trade, however with the R LEVEL at the DIRECTIONAL positive structure must be respected. If the MKT is going to make a move, it should just "go" and not look back. Any false breakout is a “tell” that the trade will turn digestive. `,
    },
    116: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL plays a key role in this technical state as it defines the condition of the current “corrective” move and determines the strength or weakness of the corrective signal. In this signature state, the R LEVEL is above the market which implies that the corrective action is just a selling opportunity and all negative signals below the R LEVEL should be accepted with an emphasis at the R LEVEL. The MKT is in a NEUTRAL CORRECTIVE position with the most likely outcome being a difficult sideways trade. The negative momentum has been broken, so beware of getting sucked into "false" failure especially early in the session. There is the potential for sharp short covering rallies so don’t FADE a rise that continues to hold structure. Wait for an exhaustion shift in momentum before stepping in front of an emotional rise.`,
    },
    117: {
      theme: `SENTIMENT BIAS - NEGATIVE -R=CRX+`,
      overview: `The MKT has produced a positive signal in a NEUTRAL trading environment. Follow through of the positive signal is questionable with the most likely outcome being a difficult sideways trade. The R LEVEL above the market is the high point that the momentum is expected to exhaust. Keep leverage light as difficult conditions are anticipated. A counter signal negative session has more follow through potential than a positive breakout. The key to any countersignal opportunity is to participate in a momentum shift at the initial turn as the neutral trading characteristics can make momentum breakout signals more expensive.`,
    },
    118: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL plays a key role in this technical state as it defines the condition of the current “corrective” move and determines the strength or weakness of the corrective signal. In this signature state the R LEVEL is above the market which implies that the corrective action is just a selling opportunity and all negative signals below the R LEVEL should be accepted with an emphasis at the R LEVEL. The MKT is in a NEUTRAL CORRECTIVE position with the most likely outcome being a difficult sideways trade. The negative momentum has been broken, so beware of getting sucked into "false" failure especially early in the session. There is the potential for sharp short covering rallies so don’t FADE a rise that continues to hold structure. Wait for an exhaustion shift in momentum before stepping in front of an emotional rise.`,
    },
    119: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The MKT has produced a positive signal in a NEUTRAL trading environment. Follow through of the positive signal is questionable with the most likely outcome being a difficult sideways trade. The R LEVEL above the market is the expected high point that the momentum is anticipated to exhaust. Keep leverage light as difficult conditions are more likely in this market state. A negative counter signal session has more follow through potential than a positive breakout. The key to any countersignal opportunity is to participate in a momentum shift at the initial turn as the neutral trading characteristics can make momentum breakout signals more expensive.`,
    },
    121: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `Technically the MKT has produced a positive signal in a TRANSITIONAL NEUTRAL market state. If the signal is real it should influence the trade period from the get go and start to build positive structure. A good “tell” to the MKT finding its legs is a breach of the prior session’s high. If the integrity of the prior session's high remains intact, it is less likely to follow through and a sideways trade developing. A breach of the previous highs and dips should be more aggressively considered. Roll with any new strength and leverage up if good risk parameters present themselves. The potential to get in at the beginning of a new move higher is real. The R LEVEL below the DT1 (DOWNSIDE TARGET #1) is a sign that the market may need to go lower before it can transition higher. The R LEVEL defines the expected low point for any negative “squeeze”. Expectations are for any negative action to stabilize above here and begin to build positive trend structure. This is a dangerous emotional trading environment. MKT reactions go a little further than you expect in either direction so anticipate this in your position and risk management.`,
    },
    122: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `Technically the MKT is in NEUTRAL TRANSITION and has produced a positive signal which should influence the MKT today if the signal is valid.  If the integrity of the prior session's high remains intact, it is less likely to do so with the MKT returning to a sideways trade. Roll with any new strength and leverage up if good risk parameters present themselves. The potential to get in at the beginning of a new move higher is real. The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) defines the low point for any selling pressure if the positive signal is going to play out. Expectations are for any negative action to stabilize in this area and begin to build positive trend structure. This can be a dangerous emotional trading environment. MKT reactions go a little further than you expect in either direction so anticipate this in your position and risk management.`,
    },
    123: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `Technically the MKT is in NEUTRAL TRANSITION and has produced a positive signal which should influence the MKT today if the signal is valid.  If the integrity of the prior session's high remains intact, it is less likely to do so with the MKT returning to a sideways trade. Roll with any new strength and leverage up if good risk parameters present themselves. The potential to get in at the beginning of a new move higher is real. The R LEVEL creates a support band with the DP which defines the low point for any selling pressure if the positive signal is going to play out. Expectations are for any negative action to stabilize in this area and begin to build positive trend structure. This can be an emotional trading environment with MKT reactions going a little further than you expect in either direction, so anticipate this in your position and risk management.`,
    },
    124: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The MKT in the NEUTRAL POSITIVE TRANSITION signature state with the R LEVEL equal to the DP (DOWNSIDE PIVOT) is basically saying that the positive signal is in “play” above the DP R LEVEL. Expectations are for the MKT to create positive trend structure above this price point. Long entries near the R LEVEL should apply the most leverage however if the MKT is that good it will be a gift and a one shot “touch and go” opportunity. A good “tell” to the session tone is the prior session’s high. If the integrity of the prior session's high remains intact, the MKT is less likely to follow through and a sideways trade expected. A breach of the previous high and the trend is on. Roll with any new strength and leverage up if good risk parameters present themselves. The potential to get in at the beginning of a new move higher is real. This is can be an emotional trading environment as the MKT is transitioning from one market state to another. Reactions will go a little further than you expect in either direction so anticipate this in your position and risk management.`,
    },
    125: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The MKT in the NEUTRAL POSITIVE TRANSTION signature state with the R LEVEL at the DIR (DIRECTIONAL) is basically saying “prove it to me”. If the MKT is going to transition higher it should maintain a trade above the R LEVEL and just go. If not, it is more likely we are in for a digestive trade with DP (DOWNSIDE PIVOT) the low point for any sideways action. This can be a dangerous emotional trading environment with MKT reactions go a little further than you expect in either direction. Anticipate this in your position and risk management.`,
    },
    126: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to UP (UPSIDE PIVOT) defines the confirmation “breakout point” for any positive transition.  This is the “make or break” level and the high point for any new digestive trade. The MKT has produced a positive signal into the R LEVEL “resistance” which it must overcome to confirm the signal. In addition, once violated the “tell” that the rally is real will be the MKT holding positive price structure. If not it is a signal that the trade may turn digestive or reverse. This can be a dangerous emotional trading environment where MKT reactions go a little further than you expect in either direction. Anticipate this in your position and risk management.`,
    },
    127: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `Technically the MKT has produced a positive signal in a TRANSITIONAL NEUTRAL market state. The R LEVEL however is above the market questioning the validity of the signal. Be patient. The likelihood of the MKT returning to a NEUTRAL sideways trade is high. Expect some swings. Don’t fight positive structure above the R LEVEL however as this will confirm the positive transition. Respect the DP (DOWNSIDE PIVOT) as this is the forecasted base for the new consolidation. This can be an emotional trading environment as the MKT is transitioning from one market state to another. Reactions will go a little further than you expect in either direction so anticipate this in your position and risk management.`,
    },
    128: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `Technically the MKT has produced a positive signal in a TRANSITIONAL NEUTRAL market state. The R LEVEL however is above the market questioning the validity of the signal. Be patient. The likelihood of the MKT returning to a NEUTRAL sideways trade is high. Expect some swings. Don’t fight positive structure above the R LEVEL however as this will confirm the positive transition. Respect the DP (DOWNSIDE PIVOT) as this is the forecasted base for the new consolidation. This can be an emotional trading environment as the MKT is transitioning from one market state to another. Reactions will go a little further than you expect in either direction so anticipate this in your position and risk management.`,
    },
    129: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `Technically the MKT has produced a positive signal in a NEUTRAL TRANSITIONAL market state. The R LEVEL above the MKT however questions the validity of the signal and defines the expected high point for any positive follow through. Be patient. The likelihood of the MKT returning to a NEUTRAL sideways trade is high. This can be an emotional trading environment as the MKT is transitioning from one market state to another and big swings are expected. Beware of counter signal energy. If the MKT starts “trending” lower, it has the potential to feed on itself. Ride don’t fight trending price structure.`,
    },
    131: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a NEUTRAL POSITIVE EXTREME signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines the low point for any negative reaction if the underlying positive momentum is going to remain intact. If the market is going to continue to search for its upper extreme, it will maintain a trade above the R LEVEL. If not, it is a signal that the MKT may be transitioning lower. The MKT is extended and can quickly snap back into a sideways digest or lower squeeze. Use the sentiment bias as the lower target for any corrective turn.`,
    },
    132: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a NEUTRAL POSITIVE EXTREME signature state with the R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) defines the “base” that is keeping the positive momentum intact. If the market is going to continue to search for its upper extreme, it will maintain a trade above here. A failure is a sign of weakness which signals the potential for a transitional trade. The MKT is extended and can quickly snap back into a sideways digest or lower squeeze. Use the R LEVEL as the lower target for any corrective turn.`,
    },
    133: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band that defines the low point for any negative exhaustive reaction if the underlying momentum is going to remain positive. EXTREME market states can be emotional and with the R LEVEL in this position, it is a sign that the positive momentum has not yet run its course. New strength will be extended so expect more “stop and go” action rather than a smooth trend. The MKT is pressing into the upper digestive extreme and is flirting with a shift into a BULL TREND position. Beware of a false positive signal REVERSAL as prices will be extended and can quickly snap back.`,
    },
    134: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the low point for any negative exhaustive reaction if the underlying momentum is going to remain positive. EXTREME market states can be emotional and with the R LEVEL in this position, it is a sign that the positive momentum has not yet run its course. New strength will however be extended so expect more “stop and go” action rather than a smooth trend. The MKT is pressing into the upper digestive extreme and is flirting with a shift into a BULL TREND position and will need to hold positive structure if it is to avoid falling back into a digestive trade. Beware of a false positive signal reversal as prices will be extended and can quickly snap back.`,
    },
    135: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) in a NEUTRAL POSITIVE EXTREME market state identifies the price point that is keeping the positive momentum in play. As long as the MKT is above the R LEVEL, higher move highs should be anticipated. Respect the UP (UPSIDE PIVOT) as the potential exhaustion point but don’t fight positive structure above here as emotional trading conditions will no doubt overreact before the rally tires. A held trade under the DIR R LEVEL takes the energy out of the positive signal and will more likely drop the MKT back into a digestive trade. A failure from the DIR R LEVEL after an exhaustive signal has more potential to follow through than a failure without one. The key “tell” is negative structure. If it’s holding, stick with it but keep profit management trail stops tight as any break in structure will quickly deflate the volatility expectations.`,
    },
    136: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines the upper lid of the positive extreme state. This definition gives a hard line in the sand between NEUTRAL and positive BULL TREND. If the positive momentum is going to exhaust, it will do so from the R LEVEL after an exhaustive signal or “false” breakout. This is typical of this Market State. Don’t fight positive structure above the UP R LEVEL however as the MKT may be transitioning into a BULL TREND state. If the MKT is going to transition higher, it will maintain positive structure after an R LEVEL breach. Only a break in structure signals an exhaustive negative turn. Don’t FADE momentum without one. The DIR is a good gauge for the positive momentum. A failure from the DIR after an exhaustive signal is confirmation of the negative turn and adds more value on the opportunity. Trading below the DIR without an exhaustive negative signal is a sign that the trade may turn digestive.`,
    },
    137: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines the upper lid of the positive extreme state. This definition gives a hard line in the sand between NEUTRAL and positive BULL TREND. If the positive momentum is going to exhaust, it will do so from the R LEVEL after an exhaustive signal or “false” breakout. This is typical of this Market State. Don’t fight positive structure above the UP R LEVEL however as the MKT may be transitioning into a BULL TREND state. If the MKT is going to transition higher, it will maintain positive structure after an R LEVEL breach. Only a break in structure signals an exhaustive negative turn. Don’t FADE momentum without one. The DIR is a good gauge for the positive momentum. A failure from the DIR after an exhaustive signal is confirmation of the negative turn and adds more value on the opportunity. Trading below the DIR without an exhaustive negative signal is a sign that the trade may turn digestive.`,
    },
    138: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL defines the expected high point of the positive extreme state. As long as the integrity of this resistance point remains intact, an exhaustive negative REVERSAL is the forecast.  A violation of the R LEVEL is a sign of strength, however the extended nature of this technical state will keep the trade stop and go. Expect emotional trading conditions. If the MKT is going to continue higher, it will build a base above the UP (UPSIDE PIVOT) after an R LEVEL breach. Any negative exhaustive signal from the R LEVEL or UP must breakdown below the CriticalRange mid-point (DIR – DIRECTIONAL) to confirm a negative shift. Respect the DP (DOWNSIDE PIVOT) but don’t fight a failure, as any negative structure has the potential to feed on itself as this market state does have the potential to produce a short-term top.`,
    },
    139: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL defines the expected high point of the positive extreme state. As long as the integrity of this resistance point remains intact, an exhaustive negative REVERSAL is the forecast.  A violation of the R LEVEL is a positive, however the extended nature of this technical state will keep the trade stop and go. Expect emotional trading conditions. If the MKT is going to continue higher, it will build a base above the UP (UPSIDE PIVOT) after an R LEVEL breach. Any negative exhaustive signal from the R LEVEL or UP must breakdown below the CriticalRange mid-point (DIR – DIRECTIONAL) to confirm a negative shift. Respect the DP (DOWNSIDE PIVOT) but don’t fight a failure, as any negative structure has the potential to feed on itself as this market state does have the potential to produce a short-term top.`,
    },
    151: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) is a sign of underlying strength and creates a range from the DP (DOWNSIDE PIVOT) to the R LEVEL, which defines the lower support extreme of this technical state. The underlying tone is “buy break” positive down to the R LEVEL which is the expected low point of the negative signal.  If the DP supports price action and the market starts to build positive structure, it is a sign of strength that targets the UP (UPSIDE PIVOT). Trading below the DP is expected to be more of a soft sideways trade down to the R LEVEL. A break below the R LEVEL is more likely a “false” negative signal setting up a REVERSAL BUY opportunity. A held trade above the DIR (DIRECTIONAL) during the early start of the period is a sign that the market may be preparing for a counter signal low volatility rally through the UP, so avoid leaning on SELL signals at this inflection point. The “tell” for this scenario is positive price structure. If the MKT fails to hold it, the expectation should be for a difficult linear sideways session.`,
    },
    152: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) is a sign of underlying strength and creates a range from the DP (DOWNSIDE PIVOT) to the R LEVEL, which defines the lower support extreme of this technical state. The underlying tone is “buy break” positive down to the R LEVEL, which is the expected low point of the NEGATIVE SHIFT signal.  If the DP supports price action and the market starts to build positive structure, it is a sign of strength targeting the UP [UPSIDE PIVOT]. Trading below the DP is expected to be more of a stop and go trade down to the R LEVEL. A break below the R LEVEL is more likely a “false” negative signal setting up a REVERSAL BUY opportunity. A held trade above the DIR (DIRECTIONAL) during the early start of the period is a sign that the market may be preparing for a counter signal low volatility rally through the UP, so avoid leaning on SELL signals at this inflection point. The key “tell” will be price structure. If it’s a pretty picture, don’t FADE it and maintain a “what if” there is a linear move to the upside expectation. A messy rally and the MKT is more likely to fall back into a NEUTRAL DIGESTION after a “dead cat” squeeze into resistance.`,
    },
    153: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL equal to the CRX- (CRITICAL RANGE NEGATIVE EXTREME) creates a support band with the DP (DOWNSIDE PIVOT) which questions the validity of the negative signal, basically saying “prove it to me”. If the MKT is going to follow through on the NEGATIVE SHIFT signal, it should break under this band and not look back. If not, then a firm sideways trade is expected to develop above the R LEVEL targeting the prior session’s negative signal area or UP [Upside Pivot]. The DIR (DIRECTIONAL) is a key part of this structure as a “tell” to the validity of the signal. Below the DIR and the MKT is poised to test support. Trading above the DIR negates the negative signal, producing a sideways to positive transition higher expectation. The NEUTRAL nature of this state will keep the trade indecisive, so look to enter BREAKOUT signals on pull-backs rather than aggressively pressing it.`,
    },
    154: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The Sentiment Bias (R LEVEL) that is equal to the lower CriticalRange Support extreme (DP-DOWNSIDE PIVOT) questions the validity of the negative signal, basically saying “prove it to me”. If the MKT is going to follow through on the negative corrective signal, it should break under the R LEVEL and not look back. If not, then a firm digestive trade above the R LEVEL is expected. The R LEVEL represents the low point that the MKT can break down to without shifting the underlying sentiment negative. The expectation in this behavioral framework is for a sideways digestive trade within the UP-DP R LEVEL or for a positive session in strength and magnitude of the prior session’s negative signal, in effect canceling out the negative bias and putting the technical position to NEUTRAL.`,
    },
    155: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL = DIR (DIRECTIONAL) signifies a more aggressive stance to the signal confirmation and a “Make or Break” trade environment. In this behavioral framework, the market is “on the fence” and action off the DIR R LEVEL is expected to point the way to the next “move”. Expectations are for a “trend” move away from the R LEVEL, targeting the UT1 (UPSIDE TARGET #1) or DT1 (DOWNSIDE TARGET #1) levels. If the Neutral nature of the state is going to continue, the price move will exhaust here followed by a move back into the CriticalRange. A sustained breach of UT1 or DT1 is a signal that the market is moving back into a trend position. How the market reacts off the CriticalRange extremes (UP and DP) is the key to the session action. A FADE reaction off either is a signal that the trade will turn digestive with the DIR R LEVEL the profit target for any DP or UP FADE entry. The market will be a little indecisive with the potential for a dramatic drop in volatility and a tight digestive session. This is not a technical state to press it, but one where patience pays.`,
    },
    156: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The market has produced a negative signal against underlying positive momentum. The R LEVEL at the Upper digestive range extreme (R=UP) is an indication that the negative signal has not run its course and will remain in effect below the R LEVEL. SELL opportunities are a better option below the R LEVEL, as the negative corrective signal will be searching for support. On the long side, BUY REVERSAL strategies are the best as the MKT is searching for support and is expected to “probe” lower. Any BUY FADEs should have confirmation with repeated tests of an area. The ideal BUY FADE setup is to have the market make 3 presses into support with the 3rd bottoming out above the level (preferably the DP-DOWNSIDE PIVOT). A BREAKOUT above the R LEVEL does have the potential to produce a counter signal rally, however follow through is most likely limited to the UT1 (UPSIDE TARGET #1).`,
    },
    157: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL- UP (UPSIDE PIVOT) range defines the upper extreme of this technical state providing a negative sentiment bias below this price band. Typically in this behavioral framework, it is an indication that the negative signal has not run its course and will continue to probe lower targeting the DP (DOWNSIDE PIVOT) and DT1 (DOWNSIDE TARGET#1). On the long side, BUY REVERSAL strategies are the best to capture the “false failures” that are typical of this trade state. BUY FADEs should have confirmation with repeated tests of an area. The ideal BUY FADE setup is to have the market make 3 presses into support with the 3rd bottoming out above the level (preferably the DP). A BREAKOUT above the R LEVEL does have the potential to produce a counter signal rally, however indecisive choppy trading conditions are expected to limit follow through potential.`,
    },
    158: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) defines an upper resistance extreme of this technical state providing a negative sentiment bias below this price band. If the negative signal is going to remain in force, any probe higher is expected to exhaust within this zone. Typically in this behavioral framework, the market has yet to define its lower digestive parameter and a play for the CriticalRange support DP (DOWNSIDE PIVOT) is expected.  BUY REVERSAL strategies off support will work best after emotional sharp declines as “false failures” that are typical of this trade state. A BREAKOUT above the R LEVEL is more likely to produce a neutral sideways action over a positive counter signal rally as indecisive choppy trading conditions are expected.`,
    },
    159: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) extends the digestive zone up to the R LEVEL. The underlying tone is negative, however the expectation is that the market can produce a rally up to the R LEVEL which would be a selling opportunity. An R LEVEL placement above the UT1 is a sign of an underlying negative expectation. If the UP (UPSIDE PIVOT) contains price action and the market starts to build negative structure, a break down to the lower CriticalRange extreme or DP (DOWNSIDE PIVOT) or DT1 (DOWNSIDE TARGET#1) is the outlook. The NEUTRAL nature of this trade state does not purport follow through providing excellent BUY REVERSAL opportunities. A BREAKOUT above the R LEVEL is more likely a “false” positive signal setting up a REVERSAL SELL opportunity.`,
    },
    161: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) is an indication that the negative transition signal is suspect and not expected to follow through with the R LEVEL the expected low point for negative price action. This is the “hold or fold” level and the low point for any new digestive trade. Exhaustive and positive momentum signals around the R LEVEL will provide opportunity for a short term “dead cat” bounce. The underlying bias is negative transition so any rally after a DP failure should be considered a selling opportunity below the DIR targeting the R LEVEL.`,
    },
    162: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) is an indication that the negative transition signal is suspect and not expected to follow through with the R LEVEL the forecasted low point for negative price action. This is the “hold or fold” level and the low point for any new digestive trade. Exhaustive and positive momentum signals around the R LEVEL will provide opportunity for a short term “dead cat” bounce. The underlying bias is negative transition so any rally after an R LEVEL failure should be considered a selling opportunity below the DIR with the expectation of a break to new move lows.`,
    },
    163: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL to DP (DOWNSIDE PIVOT) range defines the price band supporting the market from a negative transition.  This is the “hold or fold” area and the low point for any new digestive trade. Exhaustive and positive momentum signals around the R LEVEL will provide opportunity for a short term “dead cat” bounce. The underlying bias is negative transition so any rally after an R LEVEL failure should be considered a selling opportunity below the DIR with the expectation of a break to new move lows. If the negative signal is going to weigh on the market the UP (UPSIDE PIVOT) will contain any advance. A held trade above the DIR (DIRECTIONAL) during the early start of the period puts the negative signal in question with the potential for a counter signal low volatility rally up through the UP.`,
    },
    164: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to DP (DOWNSIDE PIVOT) defines the confirmation “breaking point” for any negative transition.  This is the “hold or fold” level and the low point for any new digestive trade. A downside BREAKOUT below the R LEVEL is expected to “just go” producing a sharp drop on the initial failure maintaining negative structure through the balance of the period. A “false” negative signal REVERSAL, does have the potential to be the start of a counter signal “one way” trade targeting the UP (UPSIDE PIVOT) if not higher. The “tell” to this opportunity is the market continuing to hold positive structure after the exhaustive turn. The UP is the structural high point for the negative momentum and will maintain its integrity if the transitional trade is going to play out.`,
    },
    165: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The MKT in the NEUTRAL NEGATIVE TRANSTION signature state with the R LEVEL on the DIR is basically saying “prove it to me”. If the MKT is going to transition lower it should remain below the R LEVEL and just fail. If not a it is more likely we are in for a digestive trade with UP (UPSIDE PIVOT) the high point for any sideways action. A BREAKOUT above the UP is a sign of strength negating the negative transitional signal.  A failure from the DP (DOWNSIDE PIVOT) confirms the negative signal which has the potential to be the beginning of a transition into a new BEAR TREND.`,
    },
    166: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The MKT in the NEUTRAL NEGATIVE TRANSITION signature state with the R Level equal to the UP (UPSIDE PIVOT)  is basically saying that the negative signal is in “play” below the UP R LEVEL. Expectations are for the MKT to create a negative trend structure below this price point. A failure from the DP (DOWNSIDE PIVOT) is needed to confirm any shift into a BEAR TREND market state. The best opportunities to get short will be at the UP R LEVEL however any intra-day structure [lower move high] that coincides with a Price Map level will provide an opportunity to “get on” the expected negative transition move. Be sure to reduce leverage the further away from the UP R optimal entry area. Markets in this state should just “go” and begin to trend south. If not a sideway digestive trade is expected to develop. Only above the UP is the negative signal negated.`,
    },
    167: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The MKT in the NEUTRAL NEGATIVE TRANSITION signature state with the R Level above the UP (UPSIDE PIVOT) creates a resistance band up to the R LEVEL, that will keep a negative sentiment bias below this price zone. Expectations are for any positive action to exhaust in this area and begin to build negative trend structure. A failure from the DP (DOWNSIDE PIVOT) is needed to confirm any transition into a BEAR TREND market state. The best opportunities to get short will be within the UP-R LEVEL zone expecting the worst. After a challenge to resistance the negative momentum should build if the market is going to follow through to the downside.  If not a sideway digestive trade is expected to develop. Only above the R LEVEL is the negative signal negated.`,
    },
    168: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1 `,
      overview: `The MKT in the NEUTRAL NEGATIVE TRANSITION signature state with the R Level equal to the UT1 (UPSIDE TARGET#1) creates a resistance band up to the R LEVEL. This is the high point for any positive “squeeze” against the underlying negative signal. Look for exhaustion signals within this zone as the market will maintain a negative sentiment bias below the R LEVEL. A failure from the CriticalRange mid-point (DIR –DIRECTIONAL) is a “tell” that the negative momentum is going to increase. A failure from the DP (DOWNSIDE PIVOT) is needed to confirm any transition into a BEAR TREND market state. After a challenge to resistance the negative momentum should build and hold negative structure (lower move highs), if the market is going to follow through to the downside.  If not a sideways digestive trade is expected to develop. A held trade above the UP or BREAKOUT above the R LEVEL is a sign that the trade will turn digestive.`,
    },
    169: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The MKT in the NEUTRAL NEGATIVE TRANSITION signature state with the R LEVEL above the UT1 (UPSIDE TARGET#1) establishes the high point for any positive “squeeze” against the underlying negative signal. Typically any challenge to the R LEVEL will be short lived, producing a “touch and go” rejection, as this price point represents the negative sentiment bias for the trade period. Look for exhaustion signals from the UP (UPSIDE PIVOT) up to the R LEVEL. A failure from the CriticalRange mid-point (DIR –DIRECTIONAL) after a challenge to resistance is a “tell” that the negative momentum is going to increase. A BREAKOUT below the DP (DOWNSIDE PIVOT) confirms a transition into a BEAR TREND market state. After a challenge to resistance the negative momentum should build and hold negative structure (lower move highs), if the market is going to follow through to the downside.  If not a sideways digestive trade is expected to develop. A held trade above the UP or BREAKOUT above the R LEVEL is a sign that the trade will turn digestive.`,
    },
    171: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) extends the lower extreme down to this price point. Expectation is that the market will either exhaust at the DP (DOWNSIDE PIVOT) or the R LEVEL producing a positive reaction.  BUY REVERSAL strategies are optimal as well as FADE strategies with confirmation off either support level with the best opportunities after a sharp emotional decline. A failure from the R LEVEL is more likely a sign that the trade is going to turn digestive rather than a negative transition. The UP (UPSIDE PIVOT) represents the lid keeping the pressure on the market and is the hurdle any rally must overcome to signal a positive transition. The UP reaction is the “tell” to confirm a positive transition on a BREAKOUT or a move back to the lower extreme on a rejection.`,
    },
    172: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL at the DT1 (DOWNSIDE TARGET #1) creates a support band with the DP (DOWNSIDE PIVOT) that defines the expected low point for an exhaustive bounce out of the lower extreme. If the market is going to stabilize it will build a base within this zone. BUY REVERSAL strategies are optimal as well as FADE strategies with confirmation off the R LEVEL. A held trade below the DP after an R LEVEL failure however, is a sign of weakness that has the MKT vulnerable to a negative shift into a BEAR TREND technical state. The UP (UPSIDE PIVOT) represents the hurdle any rally must overcome to signal a positive transition. Expectation is that the MKT will reject from this resistance either as a SELL FADE or REVERSAL. These opportunities will work best early in the session targeting the R LEVEL support. The UP is the positive “turning point”, so expect some play, as the reaction will either confirm a positive transition or move back to the lower extreme.`,
    },
    173: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band that defines the expected low point for an exhaustive reaction. If the market is going to stabilize it will build a base within this zone. BUY REVERSAL strategies are optimal in this area as the EXTREME nature of this technical state will keep the trade emotional. A held trade below the DP after an R LEVEL failure is a sign of weakness that has the MKT vulnerable to a negative shift into a BEAR TREND technical state. The UP (UPSIDE PIVOT) represents the hurdle any rally must overcome to signal a positive transition. Expectation is that the MKT will reject from this resistance either as a SELL FADE or REVERSAL. Respect this resistance and do not press long positions into it “hoping” for a breakout. The UP is the “turning point” and ONLY a held violation confirms a positive shift.  A “false” BREAKOUT or REVERSAL is potentially the top of the next reaction to new move lows.`,
    },
    174: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL on the DP (DOWNSIDE PIVOT), is defining the expected low point for an exhaustive bounce. If the market is going to stabilize it will not do much if any trading below the R LEVEL. An R LEVEL BUY REVERSAL is the optimal entry strategy. A held BREAKOUT below the DP and the MKT is vulnerable to a negative shift into a BEAR TREND technical state. The UP (UPSIDE PIVOT) represents the hurdle any rally must overcome to signal a positive transition. Expectation is that the MKT will reject from this resistance either as a SELL FADE or REVERSAL. Respect this resistance and do not press long positions into it “hoping” for a breakout. The UP is the “turning point” and ONLY a held violation confirms a positive shift.  A “false” BREAKOUT or REVERSAL is potentially the top of the next reaction to new move lows.`,
    },
    175: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the negative momentum. The market is pressing the lower extreme and will remain “on edge” below this price point. A BREAKOUT above the R LEVEL signals a break in the negative momentum with confirmation of a positive transition only after a breach of the UP (UPSIDE PIVOT).  A BREAKOUT below the DP (DOWNSIDE PIVOT) is a sign of weakness that threatens to shift the market into a BEAR TREND state. The EXTREME nature of the trade state will keep the trade emotional with the potential for some “stop and go” action. Any negative signal off a DP failure will remain in effect below the R LEVEL as this is the true “tell” to the markets next move.`,
    },
    176: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL at the UP (UPSIDE PIVOT), is defining the “lid” that is keeping the market under pressure. If the market is going to continue to search for its lower extreme it will maintain a trade below here, “stair-stepping” lower on each successive major support level failure. A BREAKOUT above the R LEVEL is needed to confirm the “exhaustion” of the NEUTRAL NEGATIVE EXTREME state. Expectations are more for volatile sideways price swings rather that a positive transition. Positive structure after an R LEVEL violation is the key to the potential of any BUY BREAKOUT signal. Ride the initial momentum but be ready to bail at the first sign that structure is waning.`,
    },
    177: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL above the UP (UPSIDE PIVOT), defines the resistance band that is keeping the market under pressure. If the market is going to continue to search for its lower extreme it will maintain a trade below here. Look for SELL REVERSAL signals in this zone but do not “press” any lower weakness as no follow through is expected. A BREAKOUT above the R LEVEL is needed to confirm the “exhaustion” of the NEUTRAL NEGATIVE EXTREME state. Expectations are more for volatile sideways price swings rather that a positive transition. Positive structure after an R LEVEL violation, with the market maintaining a trade above the UP, is the key to the potential of any BUY BREAKOUT signal. Ride the initial momentum but be ready to bail at the first sign that structure is waning.`,
    },
    178: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1 `,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL equal to the UT1 (UPSIDE TARGET #1), defines the “lid” that is keeping the market under pressure. If the market is going to continue to search for its lower extreme it will maintain a trade below here. Look for SELL FADE and REVERSAL signals here and at the UP [UPSIDE PIVOT], especially after a positive REVERSAL signal off the DP (DOWNSIDE PIVOT). Lower SELL BREAKOUT signals are not recommended and are most likely a “trap” as downside follow through is not expected. A BREAKOUT above the R LEVEL is needed to confirm the “exhaustion” of the NEUTRAL NEGATIVE EXTREME state. Expectation is more for volatile sideways price swings rather that a positive transition. Positive structure after an R LEVEL violation, with the market maintaining a trade above the UP, is the key to the potential of any BUY BREAKOUT signal. Ride the initial momentum but be ready to bail at the first sign that structure is waning.`,
    },
    179: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a NEUTRAL NEGATVE EXTREME signature state with the R LEVEL above the UT1 (UPSIDE TARGET #1), defines the highpoint that is keeping the market vulnerable to a return to the offer. If the market is going to continue to search for its lower extreme any rally into this resistance will exhaust below here. Look for SELL FADE and REVERSAL signals at the R LEVEL and UP (UPSIDE PIVOT), especially after a positive REVERSAL signal off the DP (DOWNSIDE PIVOT). Lower SELL BREAKOUT signals are not recommended and are most likely a “trap” as downside follow through is not expected at this time. A best use a DP (DOWNSIDE PIVOT) failure as a signal for a DIR (DIRECTIONAL) SELL entry, as the market should not trade above the DIR if it is going to continue lower. If so a rise up to the R LEVEL should be anticipated. A BREAKOUT above the R LEVEL is more of a sign that the trade will turn digestive rather than a positive transition.`,
    },
    211: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) provides a “target” for the current counter trend signal. This is the forecasted “corrective” low point. The key “tell” for this state is how the market reacts at the DP (DOWNSIDE PIVOT) after a challenge to the R LEVEL. A held trade below the DP is a sign of weakness that signals the potential for a negative transition. A DP REVERSAL however has the potential to trigger the next advance to new move highs. The UP will be a formidable resistance for this structure especially early in the session. Once the MKT trades above the DIR it should stay above it. If not then the MKT will be vulnerable to a corrective trade targeting the R LEVEL.`,
    },
    212: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) provides a “target” for the current counter trend signal. This is the forecasted “corrective” low point. The key “tell” for this state is how the market reacts at the DP (DOWNSIDE PIVOT) after a challenge to the R LEVEL. A held trade below the DP is a sign of weakness that signals the potential for a negative transition. A DP REVERSAL however has the potential to trigger the next advance to new move highs. The UP will be a formidable resistance for this structure especially early in the session. Once the MKT trades above the DIR it should stay above it. If not then the MKT will be vulnerable to a corrective trade targeting the R LEVEL.`,
    },
    213: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band that is expected to support the current “correction”. The outlook is for the market to “stabilize” in this zone and build a base for a fresh advance. A break in structure below the R LEVEL is a signal that the MKT is in transition to a negative MKT STATE but more likely a NEUTRAL condition. Positive action early prior to a test of the DP-R LEVEL support area is most likely a price move not supported by any real buying and vulnerable to a corrective turn or more of a “stop and go” digestive trade. The UP containing positive price action is the “tell” for a corrective turn. A BREAKOUT above the UP is more likely to be followed by a firm digestive trade.`,
    },
    214: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL at the DP (DOWNSIDE PIVOT) puts the negative signal in question and basically says “prove it to me”. If the market is indeed going to “correct” it should break below the DP R LEVEL and not look back. If defined properly this price inflection point will provide an emotional “release” on the initial DP R LEVEL failure. If not, more of a “probe” lower will be the expectation with the market settling into a sideways trade and a NEUTRAL transitional shift in the condition. A positive reaction off the DP early in the session has the potential to be the start of the next leg higher for the BULL TREND. Work any positive structure but respect the UP (UPSIDE PIVOT). This is the high point for any buying if the corrective nature of the condition is going to persist. A BREAKOUT above the UP is needed to negate the corrective expectation and engage the BULL TREND for a new positive extension.`,
    },
    215: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL at the DIR (DIRECTIONAL) puts the negative signal to the test. If the MKT is indeed going to produce a “correction” it will maintain a trade below the DIR R LEVEL. If so ride any negative structure as the weakness will have the potential to feed on itself. A failure from the DP (DOWNSIDE PIVOT) is expected to inject some emotion on the initial BREAKOUT however the trade remains “corrective” and any positive REVERSAL off any PriceMap level should be looked at as an opportunity to get back on the underlying BULL TREND. A held trade above the DIR R LEVEL is expected to be more “sideways” than positive trend. Only above the UP (UPSIDE PIVOT) is a resumption of the positive trend forecasted. The positive momentum will have some “legs” after a UP BREAKOUT as this will negate the “corrective” signal so work any structure targeting the UT2 (UPSIDE TARGET #2).`,
    },
    216: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL at the UP (UPSIDE PIVOT) has sentiment confirming the MKTs vulnerability to a corrective trade. The R LEVEL defines the high point for any strength in the session if the negative signal is indeed going to follow through. Maintaining negative structure is the “tell” to the corrective trade playing out. Don’t be anxious to step in front of any emotional sell-off but instead look for exhaustive BUY REVERSAL signals off support to capture the next positive turn. A BREAKOUT above the R LEVEL puts the MKT back in a BULL TREND market state with the expectation of a resumption of the higher move high pattern.`,
    },
    217: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `A market in a BULL TREND CORRECTION signature state with the R LEVEL above the UP (UPSIDE PIVOT) creates a resistance band and confirms the market’s vulnerability to a corrective trade. The R LEVEL defines the high point for any strength in the session if the negative signal is indeed going to follow through. Maintaining negative structure is the “tell” to the corrective trade playing out. Don’t be anxious to step in front of any emotional sell-off but instead look for exhaustive BUY REVERSAL signals off support to capture the next positive turn. A BREAKOUT above the R LEVEL puts the MKT back in a BULL TREND market state with the expectation of a resumption of the higher move high pattern. This is a difficult market state as conflicting indicators will keep the trade “choppy”.`,
    },
    218: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `A market in a BULL TREND CORRECTION with the R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a resistance band with the UP (UPSIDE PIVOT) and confirms the market’s vulnerability to a corrective trade. The R LEVEL defines the high point for any strength in the session if the negative signal is indeed going to “play out”. As long as the market is below the R LEVEL it is vulnerable to a corrective trade targeting the DP (DOWNSIDE PIVOT). Don’t be anxious to step in front of any emotional sell-off but instead look for exhaustive BUY REVERSAL signals off support to capture the next positive turn. Remember, this is a difficult market state and “choppy” trading conditions should be expected. A BREAKOUT above the R LEVEL is valid however a better option is to use this signal as an alert for an entry at the UP. The “tell” to the MKT transitioning back into a BULL TREND from a UP BREAKOUT SIGNAL will be the market building a base above the UP after the R LEVEL violation.`,
    },
    219: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a BULL TREND CORRECTION with the R LEVEL above the UT1 (UPSIDE TARGET #1) indicates that the market may have one move price “squeeze” before it shifts into a corrective trade. Expectation is that any new buying in the period will exhaust below the R LEVEL, producing a sharp negative reaction. An injection of emotion after a REVERSAL signal is the “tell” to the negative corrective turn. A REVERSAL signal at the UP (UPSIDE PIVOT) should maintain negative structure below the UP to confirm a shift, targeting the DP (DOWNSIDE PIVOT). This can be a deceiving market state where early low volatility “optimism” turns into emotional “panic” later in the session. The DP is the base supporting any weakness. A positive reaction off this support early in the session will have the R LEVEL as the target. How the MKT reacts off the DIR (DIRECTIONAL) after a test of the DP will show the true intentions of the return to the BULL TREND or not.`,
    },
    221: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1), defines the price point that is supporting the underlying positive momentum. If the market is going to remain firm any new weakness will stabilize above here. The market is extended and any negative exhaustion signal should use the R LEVEL as the target. Look for SELL REVERSAL strategies, especially early in the trade period at the UP (UPSIDE PIVOT). This is an emotional trade state and extreme moves are common. Expectation is for the underlying positive to maintain its integrity with the market building a base above the R LEVEL. A failure from the R LEVEL is a sign of weakness. If the market is indeed going to transition lower it will maintain a trade below the DP (DOWNSIDE PIVOT) after an R LEVEL failure. A bounce back into the CriticalRange on a BUY DP REVERSAL and the trade is expected to turn digestive. Where the MKT stabilizes after an exhaustive signal is the “tell” to the strength at the current level. Stabilizing above the DIR is the strongest posture.`,
    },
    222: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL at the DT1 (DOWNSIDE TARGET #1), defines a price band with the DP (DOWNSIDE PIVOT) that is supporting the underlying positive momentum. If the market is going to remain firm any new weakness will stabilize above or within this zone. The market is extended. New buying will have limited potential and is expected to exhaust at the UP (UPSIDE PIVOT). Look for SELL REVERSAL strategies, especially early in the trade period, using the R LEVEL as a target. Don’t force a SELL FADE against positive structure however, as this is an emotional trade state and extreme moves are common. BUY R FADE should “work” on the initial test. A sustained press into R LEVEL or sustained trade below the DP is expected to give way to a negative transition following an R LEVEL failure.`,
    },
    223: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL below the DP (DOWNSIDE PIVOT), defines the price band that is supporting the underlying positive momentum. If the market is going to remain firm any new weakness will stabilize above or within this zone. Be sure, the market is extended. New buying will have limited potential and is expected to exhaust at the UP (UPSIDE PIVOT). Look for SELL REVERSAL strategies, especially early in the trade period, using the R LEVEL as a target. Don’t force a SELL FADE against positive structure. This is an emotional trade state and extreme moves are common. BUY R FADE should “work” on the initial test. A sustained press into R LEVEL or sustained trade below the DP is expected to give way to a negative transition following an R LEVEL failure.`,
    },
    224: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL at the DP (DOWNSIDE PIVOT), defines the support base keeping the market bid. If the market is going to remain firm it will maintain a trade above this price point.  Beware of new early strength into resistance however as the extended nature of this state will keep the market vulnerable to an exhaustive reaction. UP (UPSIDE PIVOT) SELL REVERSAL strategies will present the best opportunities especially early in the trade period. Don’t force it and FADE positive structure. This is an emotional trade state and extreme moves are common. BUY R FADE should “work” on the initial test. A sustained press into support is expected to give way confirming the extended nature of the state.`,
    },
    225: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL at the DIR (DIRECTIONAL), defines the breaking point for the current positive momentum. The market is pressing into the upper extreme and the R LEVEL represents the initial failure point for the BULL TREND. If this level is properly defined the market will quickly move south after a BREAKDOWN below the DIR R LEVEL. A failure from the DP (DOWNSIDE PIVOT) is needed to confirm a short-term top with the potential to influence the market for the next 3-5 sessions. A held trade above the R LEVEL will keep the market in a euphoric state searching for resistance. Be aggressive on any SELL REVERSAL signals at resistance, especially early in the session. The market is extended and any new buying is not expected to follow through. Any SELL opportunity will need to break under the DIR R LEVEL to confirm any negative sentiment shift.`,
    },
    226: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=DP`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL at the UP (UPSIDE PIVOT), defines the expected exhaustion point for the current positive momentum. The market is pressing into the extreme and the R LEVEL represents the upper lid. If this level is properly defined the market will produce a sharp rejection on the initial challenge. A BREAKOUT above the UP (UPSIDE PIVOT) is unlikely to have enough energy to extend and is more likely the “blow off” preceding an exhaustive REVERSAL. Any SELL opportunity will need to break under the DIR (DIRECTIONAL) to confirm any negative sentiment shift. Don’t be too anxious to buy the market off support as this state is typically the beginning of a transition into a NEUTRAL or negative market state.`,
    },
    227: {
      theme: `SENTIMENT BIAS - NEGATIVE - UP&gt;R&lt;UT1`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL above the UP (UPSIDE PIVOT), creates a resistance band that is expected to contain the current positive momentum. The market is pressing into the extreme and the R LEVEL - UP range defines the upper lid. If the area is properly identified the market will exhaust in this zone producing a sharp rejection. A BREAKOUT above the UP (UPSIDE PIVOT) is unlikely to have enough energy to extend. The “tell” to any further gains is the market maintaining a trade above the UP after an R LEVEL violation. An exhaustive UP REVERSAL signal after an R LEVEL violation should be followed by an emotional drop to confirm a turn. If so it has the potential to produce a short term top influencing the market for the next 3-5 sessions. Don’t be too anxious to buy the market off support as this state is typically the beginning of a transition into a NEUTRAL or negative market state.`,
    },
    228: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL equal to the UT1 (UPSIDE TARGET #1), defines the expected exhaustion point for the current positive momentum. The market is pressing into the extreme and the R LEVEL represents the upper lid. If this level is properly defined the market will produce a sharp rejection on the initial challenge. A BREAKOUT above the UP (UPSIDE PIVOT) is the set up signal for the rallies “last hurrah”. Look for exhaustion signals up to the UT1. Any SELL opportunity will need to break under the DIR (DIRECTIONAL) to confirm a negative sentiment shift. Don’t be too anxious to buy the market off support as this state is typically the beginning of a transition into a NEUTRAL or negative market state.`,
    },
    229: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a BULL TREND EXTREME signature state with the R LEVEL above the UT1 (UPSIDE TARGET #1), defines the expected exhaustion point for the current positive momentum. The market is pressing into the extreme and the R LEVEL represents the upper lid. If this level is properly defined the market will produce a sharp rejection on the initial challenge. A BREAKOUT above the UP (UPSIDE PIVOT) is the set-up signal for the rallies “last hurrah”. Look for exhaustive SELL signals up to the R LEVEL. Any SELL opportunity will need to break under the DIR (DIRECTIONAL) to confirm a negative sentiment shift. Don’t be too anxious to buy the market off support as this state is typically the beginning of a transition into a NEUTRAL or negative market state.`,
    },
    231: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET#1) defines the low point for any exhaustive pull-back from the BULL TREND POSITIVE EXTREME market state. If the underlying tone is going to remain positive any turn to support will build a base above the R LEVEL for a fresh advance. A failure from the R LEVEL is a sign of weakness that confirms a shift into a corrective or digestive market state with the potential of producing a short-term top. The “tell” to any turn is the market maintaining a trade below the DP after an R LEVEL failure. Any exhaustive signals at or around the UP (UPSIDE PIVOT) should target the DP (DOWNSIDE PIVOT) and the R LEVEL with the expectation that the MKT will stabilize in this zone. Don’t be too anxious to buy this break however as the extreme nature of this state will keep the trade “soft” and digestive.`,
    },
    232: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET#1) defines the low point for any weakness if the underlying tone is going to remain positive. A failure from the R LEVEL confirms the EXTREME nature of this signature state and the potential for a short-term top. Any exhaustive signals at or around the UP (UPSIDE PIVOT) should target the DP (DOWNSIDE PIVOT) and the R LEVEL with the expectation that the MKT will stabilize in this zone. BUY DP REVERSALS are recommended over FADES as any press into support is expected to be laborious with the R LEVEL the decisive turning point.`,
    },
    233: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band supporting the underlying positive momentum. The market is “ahead of itself” and this is the low point for any exhaustive pull-back if the BULL TREND is going to resume course. A failure from the R LEVEL confirms the EXTREME nature of this signature state and potential for a short-term top. Any exhaustive signals at or around the UP (UPSIDE PIVOT), especially early in the session, should target the DP R LEVEL support band with the expectation that the MKT will stabilize in this zone. BUY DP REVERSALS are recommended over FADES as any press into support is expected to be laborious with the R LEVEL the decisive turning point.`,
    },
    234: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines a hard turning point support level for the underlying positive momentum. The market is extended with the potential to be the “last hurrah” of the BULL TREND. The R LEVEL is the inflection point that keeps the trend in gear above this price point. A failure from the R LEVEL and the potential for a “dramatic” corrective shift is real. Respect this support but roll with a failure. The outlook for trading within the Critical Range is sideways with the potential for a “flat-line” session. Be more aggressive locking in profits on any SELL UP (UPSIDE PIVOT) REVERSAL signals as the real negative opportunity is below the R LEVEL.`,
    },
    235: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) in a BULL TREND POSITIVE EXTREME market state defines the turning point for the underlying positive momentum. If the market is going to remain bid searching for resistance it will maintain a trade above here. Expectation is that any new buying above this price point is the BULL TRENDS “last hurrah”. Be careful not to get sucked into any new excitement as it is more likely to disappoint. A failure from the R LEVEL and the potential for a “dramatic” corrective shift is real. Respect this support but roll with a failure. The market’s reaction off the DP (DOWNSIDE PIVOT) is the “tell” to the forecast. If prices stabilize at the DP the potential for a “flat-line” digestive session is high. A breakout below the DP confirms the extreme nature of this market state and 2-5 day short-term top.`,
    },
    236: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines a hard “lid” for the underlying positive momentum. The market is extended and this level represents the “cap” for the BULL TREND. Look for exhaustive signals around this inflection point as they have the potential to be the high point for a 2-5 day short-term top. A failure from the DIR (DIRECTIONAL) is a sign of weakness that must be confirmed below the DP (DOWNSIDE PIVOT) to trigger a transition into a corrective market state. Be patient and don’t force anything as trading within the Critical Range is likely to be a difficult sideways mess. If the market is going to “go” it will remain emotional. Any volatility “downshift” is the “tell” that activity may “flat-line” for the balance of the session. Be more aggressive locking in profits until the DP support is broken.`,
    },
    237: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) defines a resistance band that is expected to contain the current positive momentum. The market is extended and this area represents the high point for the BULL TREND POSITIVE EXTREME. Look for exhaustive signals in the R LEVEL-UP zone as they have the potential to be the peak for a 2-5 day short-term top. A failure from the DIR (DIRECTIONAL) after a challenge to this area is a sign of weakness that must be confirmed below the DP (DOWNSIDE PIVOT) to trigger a transition into a corrective market state. Be patient and don’t force anything, as trading within the Critical Range has the potential to be a difficult sideways mess. If the market is going to “go” it will remain emotional. Any volatility “downshift” is the “tell” that activity may “flat-line” for the balance of the session. Be more aggressive locking in profits until the DP support is broken.`,
    },
    238: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET#1) defines the price point that is expected to contain the current positive momentum. The market is extended and the R LEVEL is the forecasted peak for any new interest. Look for exhaustive signals at or near the R LEVEL as they have the potential to be the high for a 2-5 day short-term top. A failure from the DIR (DIRECTIONAL) after a challenge to this area confirms the exhaustive outlook and puts the DP (DOWNSIDE PIVOT) in jeopardy. The best SELL REVERSAL opportunities will happen early in the session. Any late challenges to resistance is a sign that the trade will turn digestive`,
    },
    239: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET#1) defines the expected high point for the current positive momentum. The market is pressing into the extreme and the R LEVEL is the forecasted peak for any emotional push higher. Look for exhaustive sell signals at or near the R LEVEL as they have the potential to be the high for a 2-5 day short-term top. A failure from the DIR (DIRECTIONAL) after a challenge to this area confirms the exhaustive outlook and puts the DP (DOWNSIDE PIVOT) in jeopardy and a shift into a transitional market state. The best exhaustive SELL opportunities will happen early in the session. Any late challenges to resistance is a sign that the trade will turn digestive.`,
    },
    241: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) in a BULL TREND ACCELERATION EXTREME defines the base supporting the positive event signal. If the market is going to maintain its transition into a positive state it will build positive structure above this price point. The R LEVEL below the market signals a more “digestive” expectation with an eventual transition into a positive BULL TREND structure. The negative the momentum has been broken but there is work to be done before the positive momentum shift can produce sustainable follow through. A failure from the R LEVEL is a sign of weakness that negates the positive signal and threatens to return the market to a BEAR TREND state. This is an emotional trade state so expect some erratic “swings” with all trading from the R LEVEL up to the UP (UPSIDE PIVOT). The market trying to decide if the positive “shift” is real and until it can build positive structure its intentions are suspect. Key off the DIR (DIRECTIONAL) for the “tell” to the session bias.`,
    },
    242: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) in a BULL TREND ACCELERATION EXTREME defines the base supporting the positive event signal. If the market is going to maintain its transition into a positive state it will build positive structure above this price point. The R LEVEL below the market signals a more “digestive” expectation with an eventual transition into a positive BULL TREND structure. The negative the momentum has been broken but there is work to be done before the positive momentum shift can produce sustainable follow through. A failure from the R LEVEL is a sign of weakness that negates the positive signal and threatens to return the market to a BEAR TREND state. This is an emotional trade state so expect some erratic “swings” with all trading from the R LEVEL up to the UP (UPSIDE PIVOT). The market trying to decide if the positive “shift” is real and until it can build positive structure its intentions are suspect. Key off the DIR (DIRECTIONAL) for the “tell” to the session bias.`,
    },
    243: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) in a BULL TREND ACCELERATION EXTREME creates a support band that defines the base supporting the positive event signal. If the market is going to sustain a transition into a positive state it will build positive structure above this price area. A failure from the R LEVEL is a sign of weakness that negates the positive signal and threatens to return the market to a BEAR TREND state. This is an emotional trade state so expect some “swings” with all trading from the R LEVEL up to the UP (UPSIDE PIVOT). The market trying to decide if the positive “shift” is real and until it can maintain a BREAKOUT above the UP its positive intentions are suspect.`,
    },
    244: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) in a BULL TREND ACCELERATION EXTREME market state defines the base supporting the positive event signal. If the market is going to sustain a transition into a positive state it will build positive structure above this price point. A failure from the DP R LEVEL is a sign of weakness that negates the positive signal and threatens to return the market to a BEAR TREND state. This is an emotional trade state so expect some “swings” with all trading within the Critical Range. The market trying to decide if the positive “shift” is real and until it can maintain a BREAKOUT above the UP (UPSIDE PIVOT) its positive intentions are suspect.`,
    },
    245: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) in a BULL TREND ACCELERATION EXTREME market state defines the turning point for the prior session’s positive signal. If the market is going to follow through it will maintain a trade above this price point and build positive structure. A trade below the DIR R LEVEL puts the positive signal in question and signals a sideways session, with the market “digesting” the current events. The market is technically “on the fence” and its next move is expected to influence trading for the next 2-5 sessions. Only a BREAKOUT below the DP (DOWNSIDE PIVOT) negates the positive signal and sets up a return to a negative trade state. Don’t fight any negative structure below the DIR R LEVEL as it has the potential to completely whip-out gains made by the prior session positive event. In addition a BREAKOUT above the UP (UPSIDE PIVOT) that holds positive structure has the potential to produce another “dramatic” advance. This market state is dynamic with the potential for major one-way moves or a rotational “flat-line” trading around the DIR R LEVEL.`,
    },
    246: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) in a BULL TREND ACCELERATION EXTREME market state defines the “lid” keeping the current positive momentum in check. The market has produces a major “about face” technical shift and the R LEVEL represents the confirmation point for this transition. Sentiment bias at this inflection point is basically saying “prove it to me”, if the market is truly going to shift into a positive market state it will BREAKOUT above the UP R and not look back. A rejection here and it is more likely the prior session’s positive signal was a 1-day event and a resumption of the previous negative market state is the expectation. Early session false UP R BREAKOUTs followed by a DIR (DIRECTIONAL) failure are the “tell” to a return to a negative market state. The key to this state is structure. If the positive momentum is real, it will maintain positive higher move high higher move low structure. If not then shift into a NEUTRAL or NEGATIVE market state is the outlook.`,
    },
    247: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) in a BULL TREND ACCELERATION EXTREME market state creates a resistance band that defines the expected high point for the current positive emotion. The market has produces a major “about face” technical shift and the R LEVEL-UP area represents the expected high point for the current momentum. If the trade is going to turn digestive or revert to its previous negative state it will “exhaust” within this price band. Follow any positive surge into this area with a negative strategy to capitalize on the 1st break in positive structure. A breach of the R LEVEL confirms the technical “reversal” and should maintain a trade above the UP (UPSIDE PIVOT) if it is truly transitioning into a positive state.  A failure from the DIR (DIRECTIONAL) after a positive push above the UP signals a turn back into a negative trade state. Expectations are for more of a digestive trade with pressure resuming in the coming session.`,
    },
    248: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET#1) in a BULL TREND ACCELERATION EXTREME market state defines the expected high point for the current positive emotion. The market has produced a major “about face” technical shift and the R LEVEL represents the expected “squeeze” high for the current momentum. If the trade is going to turn digestive or revert to its previous negative state it will “exhaust” at or below this price point. Follow any positive surge into this area with a negative strategy to capitalize on the 1st break in positive structure. A breach of the R LEVEL confirms the technical “reversal” and should maintain a trade above the UP (UPSIDE PIVOT) if it is truly transitioning into a positive state.  A failure from the DIR (DIRECTIONAL) after a positive push above the UP signals a turn back into a negative trade state. Expectations are for more of a digestive trade with pressure resuming in the coming session.`,
    },
    249: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET#1) in a BULL TREND ACCELERATION EXTREME market state defines the expected high point for the current positive emotion. The market has produces a major “about face” technical shift and the R LEVEL represents the expected “squeeze” high for the current momentum. If the trade is going to turn digestive or revert to its previous negative state it will “exhaust” at or below this price point. Follow any positive surge into this area with a negative strategy to capitalize on the 1st break in positive structure. A breach of the R LEVEL confirms the technical “reversal” and should maintain a trade above the UP (UPSIDE PIVOT) if it is truly transitioning into a positive state.  A failure from the DIR (DIRECTIONAL) after a positive push above the UP signals a turn back into a negative trade state. Expectations are for more of a digestive trade with pressure resuming in the coming session.SENTIMENT BIAS – POSITIVE - R&lt;DT1`,
    },
    251: {
      theme: `SENTIMENT BIAS – POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET#1) extends the DIGESTIVE low point down to the R LEVEL. The DP (DOWNSIDE PIVOT) is the key inflection point for this signature state. Trading above the DP and the MKT will have a positive TREND bias. Trading below the DP and the MKT will have a sideways DIGESTIVE bias down to the R Level. Work any positive structure above the DP with the expectation of a fresh advance to new move highs. Don’t press UP (UPSIDE PIVOT) BREAKOUT strategies as the digestive nature of this trade state will keep the trade sloppy. A failure from the DP will be seductive however the expectation is a “Bear Trap” for a buying opportunity off the R Level. Any break under the R Level is more likely a “false negative” providing a positive REVERSAL opportunity. Hedge note: if the MKT does build early session positive structure above the DIR (DIRECTIONAL) it should hold this support. If not a BREAKDOWN opportunity targeting the R Level is a valid counter trend opportunity.`,
    },
    252: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET#1) creates a support band from the DP (DOWNSIDE PIVOT) down to this price point. Any weakness in the session is expected to bottom out above or in this zone if the underlying BULL TREND is going to resume course.  Look for positive REVERSAL signals. A BREAKDOWN under the R LEVEL should be suspect as follow through at this in either direction is not expected owing to the DIGESTIVE nature of this trade state. The UP (UPSIDE PIVOT) resistance is the expected high point of any new sideways DIGESTION. BUY BREAKOUT strategies off the DIR (DIRECTIONAL) are preferred over the UP as extended follow through potential is not in the forecast. Have resting limits to exit and an aggressive position management plan as the digestive posture can quickly erase any unrealized gains.`,
    },
    253: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band below the market providing positive sentiment above this zone. Any weakness in the session is expected to bottom out above here if the underlying BULL TREND is going to resume course. Look for positive REVERSAL signals. A BREAKDOWN under the R LEVEL should be suspect as follow through at this in either direction is not expected owing to the DIGESTIVE nature of this trade state. The UP (UPSIDE PIVOT) resistance is the expected high point of any new DIGESTION. BREAKOUT strategies should be initiated sooner [lower] than later [higher] as extended follow through potential is not in the forecast. Have resting limits to exit and an aggressive position management plan as the digestive posture can quickly erase any unrealized gains. Consider using a FADE strategy off the DIR (DIRECTIONAL) after a positive UP BREAKOUT signal as an alternative.`,
    },
    254: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the lower DIGESTIVE extreme and provides a positive sentiment bias above this price point. Expectations are for a firm sideways trade within the UP (UPSIDE PIVOT) and DP Critical Range parameters. Negative SELL FADE and REVERSAL strategies will work best at resistance rather than a BREAKOUT below support as sloppy trading conditions with no follow through is the expectation. If the MKT is going to BREAKOUT for a new trend move north the key “tell” will be the structure of the move. A steady higher move high, higher move low structure with minimal pullbacks should not be faded. The “hard” defined digestive low point at the R Level does lend itself to a negative transitional turning point. Here again sloppy conditions are the expectation however negative structure should not be faded.`,
    },
    255: {
      theme: `SENTIMENT BIAS - NEUTRAL - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) signals an indecisive difficult trade state. Expectation is for a sideways digestive trade rotating around the DIR R Level. If the MKT is going to “go” it should build positive or negative structure above or below the DIR R Level and not look back, with confirmation of an extended move signaled at a breach of the UP (UPSIDE PIVOT) an30d DP (DOWNSIDE PIVOT) Critical Range parameters. Typically a MKT in this signature state either makes a move from the “get go” or churns sideways for the balance of the session. Use structure as the “tell”. If the MKT is has not been holding structure then continue to FADE price action in either direction.`,
    },
    256: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines the “lid” for the digestive trade. Expectation is that price action will be vulnerable to “corrective” set-backs below this price point down to the DP (DOWNSIDE PIVOT). The DP represents the lower parameter of the digestive pattern. If the MKT is going to maintain an underlying positive tone any weakness in the session will bottom out above here. A break under this support pivot and the MKT will be vulnerable to a negative transition. A BREAKOUT above the UP R, if valid, should produce a “pop” on the initial violation and continue to hold positive structure. Digestive trade states can be sloppy but if the upper parameter is defined properly it has the potential to spark a fresh advance. A surge in momentum or “pop” is the “tell” for a new positive signal.`,
    },
    257: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) creates a resistance band which defines the upper lid for the DIGESTIVE pattern. Expectation is that positive price action into this area will exhaust dropping the MKT back into a sideways trade. The sentiment bias below here is negative down to the DP (DOWNSIDE PIVOT) support level. If the MKT is going to maintain an underlying positive tone any weakness in the session will need to stabilize above this support pivot. A failure is a sign of weakness that has the MKT vulnerable to a transition into a negative or neutral market state. A BREAKOUT above the R Level should be used as a signal trigger to start buying pull backs off the UP and DIR (DIRECTIONAL) as the digestive nature of the trade state will keep trading conditions choppy.`,
    },
    258: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL above the market and equal to the UT1 (UPSIDE TARGET #1) puts a damper on any higher hope creating a resistance band between the UT1 and UP (UPSIDE PIVOT). This is the expected high point for any positive action in the session. Expectation is for any positive price move to “exhaust” in this zone dropping the market back into a sideways digestive trade. A BREAKOUT above the R LEVEL is a positive signal, however the digestive nature of the market state will keep the trade messy with a better option to enter off the UP after an R LEVEL violation. If the market is going to shift into BULL TREND mode it will maintain a trade above the UP after an R LEVEL BREAKOUT. If not a return to a difficult sideways trade in expected. If the market does start to breakdown entering sooner at the DIR (DIRECTIONAL) is recommended over a DP (DOWNSIDE PIVOT) BREAKOUT.`,
    },
    259: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) extends the digestive range up to the R Level. Expectation is that positive price action will struggle to maintain its momentum exhausting either at the UP (UPSIDE PIVOT) or the R LEVEL dropping the MKT back into a sideways digestive trade. This is more of a big sideways market state with a bias is for a positive surge into resistance that does not follow through. The DP (DOWNSIDE PIVOT) defines the lower extreme of the digestive pattern. If the underlying BULL TREND is going to maintain its influence any turn to support will bottom out above here. A break under the DP is more a NEUTRAL than negative TRANSITION signal. Don’t press any positions as follow through is not expected at this time.`,
    },
    261: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) lowers the foundational support of this market state in anticipation of a potential price “squeeze”. The “trend is your friend” and it’s positive down to the R LEVEL. Any negative signals above the here are “corrective” and expected to have a short duration, ultimately giving way to the underlying BULL TREND momentum. The key “tell” is intra-day structure. Any positive turn following a corrective “break” has the potential to be the start of the next reaction to new move highs. Use the DIR (DIRECTIONAL) as a sentiment guide; below here the market will be vulnerable to a play for the R LEVEL. Above the DIR the market will be poised for a positive extension. A failure from the R LEVEL is more a NEUTRAL signal than negative.`,
    },
    262: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the market and equal to the DT1 (DOWNSIDE TARGET #1) signals the potential vulnerability of the positive momentum down to this price point. It also represents the “squeeze” low for any emotional corrective trade. Resting orders as well as REVERSAL strategies should be run to anticipate a “touch and go” V bottom reaction. The expectation is that this is the lower extreme for the next reaction higher. The “tell” to this expectation being fulfilled is the MKT building positive structure off the intra-day low and maintaining a trade above the DIR (DIRECTIONAL) after a test of support.`,
    },
    263: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band supporting the underlying positive bias. Any weakness in the session is expected to bottom out above or within this area. Resting FADE orders as well as REVERSAL strategies should be run to anticipate a “touch and go” V bottom reaction. The expectation is that this is the lower extreme for the next reaction higher. The “tell” to this expectation being fulfilled is the MKT building positive structure off the intra-day low. An early session SELL REVERSAL at the UP (UPSIDE PIVOT) is vulnerable to a “corrective” squeeze down to the DP R LEVEL price band. A held trade below the R LEVEL is needed to confirm a break in the positive structure.`,
    },
    264: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the base supporting the positive market structure. If the market is going to continue its higher move high higher move low campaign any weakness will bottom out above here. The market is in buy mode so look for excuses to get in above the R LEVEL. A breach of the UP (UPSIDE PIVOT) should produce a “pop” on the initial BREAKOUT if the market is going to extend up to the UT2 (UPSIDE TARGET #2). A lackluster UP BREAKOUT signal has the potential to turn the trade digestive with the DIR (DIRECTIONAL) defining the positive structure “breaking point”. The R LEVEL is a hard base for the BULL TREND and a failure does have the potential to derail positive momentum. Don’t fight negative structure below the R LEVEL as the potential for a sharp corrective sell-off is real.`,
    },
    265: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BULL TREND signature state with the R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the current positive momentum. The market is in play and the R LEVEL will either confirm or deny the current BULL TREND. If the market is going to continue higher it should remain firm above this inflection point and just “go”. A BREAKOUT above the UP (UPSIDE PIVOT) confirms a new extension and is expected to trigger “fresh” interest and provide a nice “pop”.  A break below the R LEVEL signals a break in the positive market structure and a shift into a more digestive trade state. Expect difficult conditions with “false” momentum signals. Only below the DP (DOWNSIDE PIVOT) is there the potential for a “corrective” turn.`,
    },
    266: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) questions the viability of the current BULL TREND and puts the market in play. It is basically saying “prove it to me”. If the market is truly trending higher it should BREAKOUT above the UP R level and not look back. Expectations are that any BREAKOUT signal should be made with “authority” and sparked with a “pop” followed by a “one way” trade into the close. If the BREAKOUT higher scenario does play out it has the potential to influence the market for the next 3-5 sessions. Trading below the UP R however is expected to be more sideways in nature down to the DP (DOWNSIDE PIVOT). This is the “true test” of the positive trends fortitude. If the market builds a base here it is a sign of strength for an eventual resumption of the higher move high campaign. A failure from the DP with the negative sentiment bias is more likely a negative transition than a shift into a NEUTRAL state.`,
    },
    267: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL creates a resistance band with the UP (UPSIDE PIVOT) and questions the viability of the current BULL TREND. It is basically saying “prove it to me”. If the market is truly trending higher it should BREAKOUT above the R LEVEL and maintains a trade above the UP. Expectations are that the market will hold positive structure targeting the UT2 (UPSIDE TARGET #2). Trading below the R LEVEL-UP resistance band however is expected to be more sideways in nature down to the DIR (DIRECTIONAL) and DP (DOWNSIDE PIVOT). The DP is the “true test” of the positive trends fortitude. If the market builds a base here it is a sign of strength for an eventual resumption of the higher move high campaign. A failure from the DP in this state is more likely a negative transition than a shift into a NEUTRAL state.`,
    },
    268: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a resistance band with the UP (UPSIDE PIVOT) and questions the viability of the current BULL TREND. The outlook is more for a digestive rather than corrective trade. Rallies into the R LEVEL are expected to exhaust and retrace back to the DIR (DIRECTIONAL). If the market does breach the R LEVEL the “tell” will be how the market reacts off the UP. Base building above this inflection point is the key to the higher forecast. A SELL REVERSAL failure signals a sideways trade with the DIR gauging the momentum. Trading below the DIR is a sign that the trade will turn “corrective” targeting the DP (DOWNSIDE PIVOT). The DP is the “true test” of the positive trends fortitude. If the BULL TREND is going to resume, it will build a base above here. A failure from the DP confirms a transition into a NEUTRAL or BEAR TREND market state.`,
    },
    269: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) defines the expected peak of the next positive push. Expectations are for any new strength to exhaust at or below the R LEVEL, shifting the market into a digestive trade with the DIR (DIRECTIONAL) the lower extreme for the sideways forecast. Use the UP (UPSIDE PIVOT) as the rotational level within the R-DIR price range. A BREAKOUT below the DIR is a sign that the trade will turn “corrective” targeting the DP (DOWNSIDE PIVOT). The DP is the “true test” of the positive trends fortitude. If the BULL TREND is going to resume, it will build a base above here. A failure from the DP confirms a transition into a NEUTRAL or BEAR TREND market state.`,
    },
    271: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) in a BULL TREND POSITIVE technical state extends the underlying aggressive buy posture down to this price point. If the market is going to perform to expectation the pull-back will typically either stabilize off the DP (DOWNSIDE PIVOT) or off the R LEVEL. The market has generated a positive technical signal in a positive trend environment but remains vulnerable to a “squeeze” down to the R LEVEL before it can follow through. The market doesn’t “need” to correct before it rallies but if it does it is a break to buy on any positive turn in momentum. Early session UP (UPSIDE PIVOT) BREAKOUT signals should maintain positive trend structure.  A break in structure or REVERSAL puts the R LEVEL support as the target objective of any countertrend trade.`,
    },
    272: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the market and equal to the DT1 (DOWNSIDE TARGET #1) in a BULL TREND POSITIVE market state confirms the positive foundation of the market. The R Level represents the structural low point for any pull-back if the positive signal is going to play out. Sentiment remains firm down to the R Level however; the “immediacy” of the signal is in question, as a break down to the R Level will deflate the positive excitement. A bounce is expected however extended gains are unlikely. Key off the DIR (DIRECTIONAL) after a test of support as this will be the “tell” to the market’s true intentions. A held trade above the DIR and momentum will be ready to extend to new move highs. If not more of a digestive trade will be the outlook. A failure from the R Level negates the positive signal and is likely to produce a negative shift in momentum.`,
    },
    273: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band supporting the markets firm foundation. This is the structural low point for any weakness if the positive signal is going to play out. Typically the market will make one “head fake” into this area then quickly move away and build positive structure for the balance of the session. A failure from the R Level negates the positive signal and has the potential to produce an emotional negative shift in momentum and a counter signal trade. An early session UP (UPSIDE PIVOT) SELL REVERSAL has the potential to influence the market for the balance of the session targeting the DP R LEVEL price band. How the market reacts at the DIR (DIRECTIONAL) will be the ”tell” to the markets intentions.`,
    },
    274: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) in a BULL TREND POSITIVE market state defines the structural low point that supports the aggressive buy posture. If the positive signal is going to play out any weakness will bottom out above here. Typically the market will make one play into this area then quickly move away and build positive structure for the balance of the session. The nature of this state is “staunch” BULL TREND so if the market starts trading above the DIR (DIRECTIONAL) it should maintain a trade above here and continue higher. A BREAKOUT above the UP (UPSIDE PIVOT) is needed to confirm a new leg higher. A failure from the R Level negates the positive signal with the potential to produce an emotional negative shift and a counter signal trade.`,
    },
    275: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) poses a challenge to the recent positive signal and is the most aggressive sentiment bias position for this signature state. If the higher move high higher move low posture is going to “extend” then the R LEVEL will support any weakness. If not, a failure from the DIR R LEVEL represents the 1st break in momentum, which if confirmed below the DP (DOWNSIDE PIVOT) has the potential to produce a significant counter trend negative signal. A positive reaction off the DIR R LEVEL that is confirmed with a BREAKOUT above the UP (UPSIDE PIVOT) however is the “tell” that the aggressive buying is going to continue, with the UT2 (UPSIDE TARGET #2) the expected target.`,
    },
    276: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) in a BULL TREND POSITIVE market state questions a validity of the aggressive buy posture, and is basically saying “prove it to me”. If the market is truly going higher it should BREAKOUT above the UP R LEVEL and not look back.  The “tell” for positive follow through will be a strong “pop” after a UP R LEVEL violation with the market building positive structure. If the market is going to maintain a firm tone it will hold above the DIR (DIRECTIONAL) after a UP R LEVEL breach. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of the markets intentions. Don’t FADE any negative structure as it has the potential to feed on itself.`,
    },
    277: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) shows a lack of confidence in the follow through potential in the positive signal. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”.  The R LEVEL and the UP create a resistance band containing any new strength. A BREAKOUT above the R LEVEL will typically build a base above the UP if it is indeed going to extended higher with the DIR (DIRECTIONAL) the structural low “turning point” for the underlying positive momentum. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than a resumption of the positive trend but a failure has the potential to produce a negative transitional shift.`,
    },
    278: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) shows a lack of confidence in the follow through potential in the positive signal. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”. The R LEVEL and the UP create a resistance band containing any new strength. A BREAKOUT above the R LEVEL will typically build a base above the UP if it is indeed going to extended higher with the DIR (DIRECTIONAL) the structural low “turning point” for the underlying positive momentum. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than an immediate resumption of the positive trend but a failure has the potential to feed on itself.`,
    },
    279: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) shows a lack of confidence in the follow through potential in the positive signal. The higher the R LEVEL the less likely the positive momentum will be able to keep pace. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”. A BREAKOUT above the UP (UPSIDE PIVOT) is a positive signal however the profit potential is limited to the R LEVEL. Any negative reaction following a UP BREAKOUT should bottom out above the DIR (DIRECTIONAL) if the underlying tone is going to remain firm. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than an immediate resumption of the positive trend but a failure has the potential to feed on itself producing a negative transitional shift.`,
    },
    281: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) in a BULL TREND ACCELERATION technical state extends the underlying aggressive buy posture down to this price point. If the market is going to perform to expectation the pull-back will typically either stabilize off the DP (DOWNSIDE PIVOT) or off the R LEVEL. The market has generated a big positive technical signal but is vulnerable to a “squeeze” down to the R LEVEL before it can follow through. The market doesn’t “need” to correct before it rallies but if it does it is a break to buy on any positive turn in momentum. Early session UP (UPSIDE PIVOT) BREAKOUT signals should maintain positive trend structure. A break in structure or REVERSAL puts the R LEVEL support as the target objective of any countertrend trade.`,
    },
    282: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the market and equal to the DT1 (DOWNSIDE TARGET #1) is confirmation of the aggressive buy posture. The R LEVEL represents the structural low point for any pull-back if the positive signal is going to play out. Sentiment remains positive down to the R Level however, the “immediacy” of the signal is in question, as a break down to the R LEVEL will deflate the positive excitement. A bounce is expected however extended gains are unlikely. A failure from the R LEVEL negates the positive signal and is likely to produce an emotional negative shift in momentum.`,
    },
    283: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band supporting the aggressive buy posture. This is the structural low point for any weakness if the positive signal is going to play out. Typically the market will make one “head fake” into this area then quickly move away and build positive structure for the balance of the session. A failure from the R LEVEL negates the positive signal and has the potential to produce an emotional negative shift in momentum and a counter signal trade.`,
    },
    284: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the structural low point that supports the aggressive buy posture. If the positive signal is going to play out any weakness will bottom out above here. Typically the market will make one play into this area then quickly move away and build positive structure for the balance of the session. The nature of this state is aggressive positive so if the market starts trading above the DIR (DIRECTIONAL) it should maintain a trade above here and continue higher. A BREAKOUT above the UP (UPSIDE PIVOT) is needed to confirm an extension higher. A failure from the R LEVEL will negate the positive signal with the potential to produce an emotional negative shift and a counter signal trade.`,
    },
    285: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) poses a challenge to the signal and is the most aggressive sentiment bias position for this signature state. If the higher move high higher move low posture is going to “steepen” then the R LEVEL will support any weakness. If not, a failure from the DIR R LEVEL represents the 1st break in momentum, which if confirmed below the DP (DOWNSIDE PIVOT) has the potential to produce a significant counter trend negative signal. A positive reaction off the DIR R LEVEL that is confirmed with a BREAKOUT above the UP (UPSIDE PIVOT) is the “tell” that the aggressive buying is going to extend, with the UT2 (UPSIDE TARGET #2) the expected target.`,
    },
    286: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) in a BULL TREND ACCELERATION market state questions a validity of the aggressive buy posture, and is basically saying “prove it to me”. If the market is truly going higher it should BREAKOUT above the UP R LEVEL and not look back.  The “tell” for positive follow through will be a strong “pop” after a UP R LEVEL violation with the market building positive structure. If the market is going to maintain a firm tone it will hold above the DIR (DIRECTIONAL). A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of the markets intentions. Don’t FADE any negative structure as it has the potential to feed on itself.`,
    },
    287: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) shows a lack of confidence in the follow through potential in the positive signal. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”. The R LEVEL and the UP create a resistance band containing any new strength. A BREAKOUT above the R LEVEL will typically build a base above the UP if it is indeed going to extended higher with the DIR (DIRECTIONAL) the structural low “turning point” for the underlying positive momentum. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than a resumption of the positive trend but a failure has the potential to feed on itself.`,
    },
    288: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) shows a lack of confidence in the follow through potential in the positive signal. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”. The R LEVEL and the UP create a resistance band containing any new strength. A BREAKOUT above the R LEVEL will typically build a base above the UP if it is indeed going to extended higher with the DIR (DIRECTIONAL) the structural low “turning point” for the underlying positive momentum. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than an immediate resumption of the positive trend but a failure has the potential to feed on itself.`,
    },
    289: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) shows a lack of confidence in the follow through potential in the positive signal. The higher the R LEVEL the less likely the positive momentum will be able to keep pace. Expectations is for the market to “digest” the prior session’s positive signal, if not negate it, with the R LEVEL the high point for any upside “head-fake”. A BREAKOUT above the UP (UPSIDE PVIOT) is a positive signal however the profit potential is limited to the R LEVEL. Any negative reaction following a UP BREAKOUT should bottom out above the DIR (DIRECTIONAL) if the underlying tone is going to remain firm. A BREAKOUT below the DIR is a sign of weakness that has the market vulnerable to a counter signal negative transition. How the market reacts at the DP (DOWNSIDE PIVOT) will be the true test of its intentions. A bounce off the DP signals more sideways action than an immediate resumption of the positive trend but a failure has the potential to feed on itself.`,
    },
    321: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) shows a lack of confidence in the follow through potential in the negative signal. The lower the R LEVEL the less likely the negative momentum will be able to overwhelm the market. Expectation is for the market to “digest” the prior session’s negative signal. If not, negate it with the R LEVEL the low point for any downside “head-fake”. A BREAKOUT below the DP (DOWNSIDE PIVOT) is a negative signal, however the profit potential is limited to the R LEVEL. Any positive reaction following a DP BREAKOUT should top out below the DIR (DIRECTIONAL) if the underlying tone is going to remain weak. A BREAKOUT above the DIR is a sign of strength that has the market poised for a counter signal positive transition. How the market reacts at the UP (UPSIDE PIVOT) will be the true test of its intentions. A rejection from the UP signals more sideways action than an immediate resumption of the negative trend, but a BREAKOUT above the UP has the potential to feed on itself.`,
    },
    322: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) shows a lack of confidence in the follow through potential of the negative signal. Expectation is for the market to “digest” the prior session’s signal. If not, negate it with the R LEVEL the low point for any downside “head-fake”. The R LEVEL and the DP (DOWNSIDE PIVOT) create a price band supporting any new weakness. A BREAKOUT below the R LEVEL is a negative signal that will typically keep prices below the DP is it is indeed going to release lower. After an R LEVEL failure, the DIR (DIRECTIONAL) becomes the structural high “turning point” for the underlying negative momentum. A BREAKOUT above the DIR is a sign of strength that has the market poised for a counter signal positive transition. How the market reacts at the UP (UPSIDE PIVOT) will be the true test of its intentions. A rejection from the UP signals more sideways action than an immediate resumption of the negative trend, but a BREAKOUT above the UP has the potential to build on itself.`,
    },
    323: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) shows a lack of confidence in the follow through potential of the negative signal. Expectation is for the market to “digest” the prior session’s signal. If not, negate it with the R LEVEL the low point for any downside “head-fake”. The R LEVEL and the UP (UPSIDE PIVOT) create a price band supporting any new weakness. A BREAKOUT below the R LEVEL will typically struggle to trade back above the DP if it is going to extend lower with the DIR (DIRECTIONAL) the structural high “turning point” for the underlying negative momentum. A BREAKOUT above the DIR is a sign of strength that has the potential for a counter signal positive transition. How the market reacts at the UP will be the true test of its intentions. A rejection from the UP signals more sideways action than a resumption of the negative trend, but a BREAKOUT has the potential to feed on itself.`,
    },
    324: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) in a BEAR TREND ACCELERATION market state questions the validity of the aggressive sell posture, and is basically saying “prove it to me”. If the market is truly that bad, it should BREAKOUT below the DP R LEVEL and not look back. The “tell” for negative follow through will be an emotional “drop” after a DP R LEVEL failure with the market building negative structure. If the market is going to maintain a weak tone, it will maintain a trade below the DIR (DIRECTIONAL). A BREAKOUT above the DIR is a sign of strength that has the market poised for a counter signal positive transition. How the market reacts at the UP (UPSIDE PIVOT) will be the true test of the market’s intentions. Don’t FADE any positive structure above the UP, as it has the potential to feed on itself.`,
    },
    325: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) poses a challenge to the negative signal and is the most aggressive sentiment bias position for this signature state. If the lower move low lower move high posture is going to “cascade”, then the R LEVEL will contain any strength. If not, a trade above the DIR R LEVEL represents the 1st break in momentum which if confirmed above the UP (UPSIDE PIVOT) has the potential to produce a significant counter trend positive signal. A negative reaction off the DIR R LEVEL that is confirmed with a BREAKOUT below the DP (DOWNSIDE PIVOT) is the “tell” that the aggressive selling is going to extend with the DT2 (DOWNSIDE TARGET #2) the expected target.`,
    },
    326: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines the structural high point that contains the aggressive sell posture. If the negative signal is going to play out, any strength will exhaust below here. Typically the market will make one play into this area then quickly move away and build negative structure for the balance of the session. The nature of this state is aggressively negative so if the market starts trading below the DIR (DIRECTIONAL), it should maintain a trade below here and continue lower. A BREAKOUT below the DP (DOWNSIDE PIVOT) is needed to confirm an extension lower. A BREAKOUT above the R LEVEL will negate the negative signal with the potential to produce an emotional positive shift and a counter signal trade.`,
    },
    327: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) creates a resistance band that keeps the market in an aggressive sell posture. This is the structural high point for any strength if the negative signal is going to play out. Typically the market will make one “head fake” into this area then quickly move away and build negative structure for the balance of the session. A BREAKOUT above the R LEVEL negates the negative signal and has the potential to produce an emotional positive shift in momentum and a counter signal trade.`,
    },
    328: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL above the market and equal to the UT1 (UPSIDE TARGET #1) is confirmation of the aggressive sell posture. The R LEVEL represents the structural high point for any pull-back if the negative signal is going to play out. Sentiment remains negative, however the “immediacy” of the signal is in question as a rally up to the R LEVEL will provide some “false” hope. Expectation is for a rejection followed by a more orderly less emotional break. A BREAKOUT above the R LEVEL negates the negative signal and is likely to produce an emotional positive shift in momentum.`,
    },
    329: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) in a BEAR TREND ACCELERATION technical state extends the aggressive selling pressure up to this price point. If the market is going to perform to expectation, any positive price action will typically either reject from the UP (UPSIDE PIVOT) or exhaust at the R LEVEL. The market has generated a big negative technical signal but is susceptible to a “squeeze” up to the R LEVEL before it can follow through to the downside. The market doesn’t “need” to recover before it breaks but if it does, it is a rally to sell. Early session DP (DOWNSIDE PIVOT) BREAKOUT signals should maintain negative trend structure. A break in structure or REVERSAL puts the R LEVEL resistance as the target objective of any counter trend trade.`,
    },
    331: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines a “target” level for the current negative momentum. Expectation is that the market is going to produce a positive “corrective” bounce off this support. A break below the DP (DOWNSIDE PIVOT) will set up a play for the R LEVEL support and define the DIR (DIRECTIONAL) as the high point for any positive “squeeze” if the negative momentum is going to continue down to the R LEVEL. A break under the R LEVEL is not expected to produce a sharp negative reaction. Avoid selling the market in the hole below here but rather FADE a sharp positive reaction against the DP or DIR. A BREAKOUT above the DIR is more a signal of digestion than a positive shift with the UP (UPSIDE PIVOT) the expected high point. FADE momentum against this inflection point. Only above the UP resistance is a shift into a NEUTRAL market state the forecast. Expect difficult trading conditions.`,
    },
    332: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) creates a support band with the DP (DOWNSIDE PIVOT) and questions the viability of the current BEAR TREND DIGESTION giving the market more of a sideways expectation. The R LEVEL is the base defining the confirmation “breaking point” for the next extension lower. A failure here is negative, however more of a “choppy” trend lower is the outlook versus a smooth trend. Look to FADE positive price reactions after an R LEVEL failure rather than selling into new weakness. The DIR (DIRECTIONAL) is the high point for any positive “squeeze” after an R LEVEL failure if the negative signal is going to play out. A BUY DIR BREAKOUT confirms the digestive intentions of this market state with the UP (UPSIDE PIVOT) the expected “lid”. Only above this resistance is a shift into a NEUTRAL market state more likely. Expect difficult trading conditions.`,
    },
    333: {
      theme: `SENTIMENT BIAS - POSITIVE - DP&gt;R&lt;DT1`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band below the market and questions the viability of the current BEAR TREND NEGATIVE momentum. The R LEVEL in this position is basically saying “prove it to me”. If the market is truly trending lower, it should fail from the R LEVEL and not look back. Expectations are that any SELL BREAKOUT signals should be made with “authority” triggering a sharp “drop” and followed by accelerated negative market structure. If the BREAKOUT lower scenario does play out, it has the potential to influence the market for the next 3-5 sessions. Trading above the DP R LEVEL price band however is expected to produce a difficult “corrective” up to the UP (UPSIDE PIVOT). This is the “true test” of the negative trend’s intentions. If the market confirms resistance here, it is a sign of weakness targeting the R LEVEL. A BREAKOUT above the UP is more likely a positive transition than a shift into a NEUTRAL state as this signal will be in the direction of the R LEVEL bias.`,
    },
    334: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) in a BEAR TREND NEGATIVE market state questions the viability of the current momentum. The R LEVEL in this position is basically saying “prove it to me”. If the market is truly that weak, it should fail from the DP R LEVEL and not look back. Expectations are that any SELL BREAKOUT signal should be made with emotion, triggering a sharp “drop” and followed by accelerated negative structure or what is technically referred to as a “pisser”. If the BREAKOUT lower scenario does play out, it has the potential to influence the market for the next 3-5 sessions. Trading above the DP R LEVEL however is expected to be a difficult corrective trade up to the UP (UPSIDE PIVOT). This is the “true test” of the negative trend’s intentions. If the market creates a “lid” here, it is a sign of weakness for an eventual resumption of the lower move low campaign. A BREAKOUT above the UP is more likely a positive transition than a shift into a NEUTRAL state.`,
    },
    335: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BEAR TREND NEGATIVE signature state with the R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the current negative momentum. The market has generated a fresh negative signal in a weak market state and the R LEVEL will either confirm or deny the signals validity. If the market is going to continue lower, it should remain hard pressed below the R LEVEL and continue to give ground. A failure from the DP (DOWNSIDE PIVOT) confirms a new extension lower and is expected to trigger a sharp emotional drop on the initial SELL BREAKOUT. A rise above the R LEVEL signals a break in the negative market structure and a shift into a more digestive trade. Expect difficult conditions with “false” momentum signals. Only above the UP (UPSIDE PIVOT) is there the potential for a “corrective” turn.`,
    },
    336: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `A market in a BEAR TREND NEGATIVE signature state with the R LEVEL equal to the UP (UPSIDE PIVOT) defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell area as it has the potential to be the high point for the next reaction lower. Resting FADE and REVERSAL entry strategies are recommended. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP- DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity. In addition, a BREAKOUT above the R LEVEL has the potential to trigger a counter trend corrective rally. Don’t fight positive structure above here “hoping” for an exhaustive turn.`,
    },
    337: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `A market in a BEAR TREND NEGATIVE signature state with the R LEVEL above the UP (UPSIDE PIVOT) creates a resistance band which defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell zone. Rallies into this area are expected to exhaust. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP-DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity.`,
    },
    338: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `A market in a BEAR TREND NEGATIVE signature state with the R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a resistance band which defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell zone. Rallies into this area are expected to exhaust. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP-DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity.`,
    },
    339: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a BEAR TREND NEGATIVE signature state with the R LEVEL above the UT1 (UPSIDE TARGET #1) is vulnerable to the negative momentum up to the R LEVEL. This is the “squeeze” high and optimal sell zone. Rallies up to this inflection point are suspect and expected to exhaust. Size management at lower selling opportunities should be considered to ensure enough firepower if this opportunity is presented. The market is under pressure and should just go, but with the R LEVEL above the market, the potential for a “corrective” squeeze is real. The “tell” to this set-up is an early session “false” failure followed by a low volatility rally into the R LEVEL. Avoid this opportunity if it sets up late in the session as the turn is more likely to come after the close.`,
    },
    341: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines a “target” level for the current negative momentum. Expectation is that the market is going to produce a positive “corrective” bounce off this support. A break below the DP (DOWNSIDE PIVOT) will set up a play for the R LEVEL support and define the DIR (DIRECTIONAL) as the high point for any positive “squeeze” if the negative momentum is going to resume course for the R LEVEL support. A break under the R LEVEL is not expected to produce a sharp negative reaction. Avoid selling the market in the hole but rather FADE a positive reaction against the DP or DIR. A BREAKOUT above the DIR is more a signal of digestion than a positive shift with the UP (UPSIDE PIVOT) the expected high point. FADE momentum against this inflection point. Only above the UP resistance is a shift into a NEUTRAL market state the forecast. Expect difficult trading conditions.SENTIMENT BIAS - POSITIVE - R=DT1`,
    },
    342: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) creates a support band with the DP (DOWNSIDE PIVOT) and questions the viability of the current BEAR TREND, giving the market more of a digestive expectation. The R LEVEL is the base defining the confirmation “breaking point” for the next reaction lower. A failure here is negative, however, more of a “choppy” trend lower is the outlook. Look to FADE a positive price move after an R LEVEL failure rather than selling into new weakness. The DIR (DIRECTIONAL) is the high point for any positive “squeeze” after an R LEVEL failure if the negative signal is going to play out. A BUY BREAKOUT above the DIR confirms the digestive intentions of this market state with the UP (UPSIDE PIVOT) the expected “lid”. Only above this resistance is a shift into a NEUTRAL market state more likely. Expect difficult trading conditions.`,
    },
    343: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band below the market and questions the viability of the current BEAR TREND. The R LEVEL in this position is basically saying “prove it to me”. If the market is truly trending lower, it should fail from the R LEVEL and not look back. Expectations are that any SELL BREAKOUT signal should be made with “authority”, triggering a sharp “drop” and followed by a “one way” trade into the close. If the BREAKOUT lower scenario does play out, it has the potential to influence the market for the next 3-5 sessions. Trading above the DP R LEVEL price band however is expected to be digestive in nature up to the UP (UPSIDE PIVOT). This is the “true test” of the negative trend’s intentions. If the market confirms resistance here, it is a sign of weakness targeting the R LEVEL. A BREAKOUT above the UP is more likely a positive transition than a shift into a NEUTRAL state, as this signal will be in the direction of the R LEVEL bias.`,
    },
    344: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) questions the viability of the current BEAR TREND and puts the market in play. The R LEVEL in this position is basically saying “prove it to me”. If the market is truly trending lower, it should fail from the DP R LEVEL and not look back. Expectations are that any SELL BREAKOUT signal should be made with “emotion”, triggering a sharp “drop” and followed by a “one way” trade into the close. If the BREAKOUT lower scenario does play out, it has the potential to influence the market for the next 3-5 sessions. Trading above the DP R LEVEL however is expected to be digestive in nature up to the UP (UPSIDE PIVOT). This is the “true test” of the negative trend’s intentions. If the market creates a “lid” here, it is a sign of weakness for an eventual resumption of the lower move low campaign. A BREAKOUT above the UP with the current positive sentiment bias is more likely a positive transition than a shift into a NEUTRAL state.`,
    },
    345: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BEAR TREND signature state with the R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the current negative momentum. The market is in play and the R LEVEL will either confirm or deny the current BEAR TREND. If the market is going to continue lower, it should remain hard pressed below this inflection point and just release. A failure from the DP (DOWNSIDE PIVOT) confirms a new extension and is expected to trigger new negative emotion and a sharp drop.  A rise above the R LEVEL signals a break in the negative market structure and a shift into a more digestive trade state. Expect difficult conditions with “false” momentum signals. Only above the UP (UPSIDE PIVOT) is there potential for a “corrective” turn.`,
    },
    346: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `A market in a BEAR TREND signature state with the R LEVEL equal to the UP (UPSIDE PIVOT) defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell area as it has the potential to be the high point for the next reaction lower. Resting FADE and REVERSAL entry strategies are recommended. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP- DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity. In addition, a BREAKOUT above the R LEVEL has the potential to trigger a counter trend corrective rally. Don’t fight positive structure above here “hoping” for an exhaustive turn.`,
    },
    347: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `A market in a BEAR TREND signature state with the R LEVEL above the UP (UPSIDE PIVOT) creates a resistance band which defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell zone. Rallies into this area are expected to exhaust. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “typical” Hedge scenario is when the MKT breaks out to the downside (below the DP- DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity.`,
    },
    348: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `A market in a BEAR TREND signature state with the R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a resistance band which defines the upper peak of the negative market structure. Trading below the R LEVEL will keep the market vulnerable to a negative trend move. This is the optimal sell zone. Rallies into this area are expected to exhaust. Size management off lower selling opportunities should be considered to ensure enough firepower if an R LEVEL Sell opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP-DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity.`,
    },
    349: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a BEAR TREND signature state with the R LEVEL above the UT1 (UPSIDE TARGET#1) is vulnerable to the negative momentum up to the R LEVEL. This is the optimal sell zone. Rallies up to this inflection point are expected to exhaust. Size management at lower selling opportunities should be considered to ensure enough firepower if this opportunity is presented. With the R LEVEL above the market, a “worst case” scenario is when the MKT breaks out to the downside (below the DP-DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity.`,
    },
    351: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) extends the digestive range down to the R LEVEL. Expectation is that negative price action will stabilize either at the DP (DOWNSIDE PIVOT) or the R LEVEL, bouncing the MKT back into a sideways digestive trade. This is more of a big sideways market state with a bias for negative price action into support that does not follow through. The UP (UPSIDE PIVOT) defines the upper extreme of the digestive pattern. If the underlying BEAR TREND is going to maintain its influence, any challenge to resistance will top out below here. A BREAKOUT above the UP is more a NEUTRAL than positive transition signal. Don’t press any positions as follow through is not expected at this time.`,
    },
    352: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL below the market and equal to the DT1 (DOWNSIDE TARGET #1) questions the potential of the negative momentum and creates a support band between the DT1 and DP (DOWNSIDE PIVOT). This is the expected low point for any weakness in the session. Expectation is for any negative price move to “stabilize” in this zone, bouncing the market back into a sideways digestive trade. A BREAKOUT below the R LEVEL is a negative signal, however, the digestive nature of the market state will keep the trade messy with a better option to sell against the DP after an R LEVEL failure rather than sell the R LEVEL BREAKOUT. If the market is going to shift into BEAR TREND mode, it will maintain a trade below the DP after an R LEVEL failure. If not, a return to a difficult sideways trade in expected. If the market does start to shift positive, entering sooner at the DIR (DIRECTIONAL) is recommended over a UP (UPSIDE PIVOT) BREAKOUT.`,
    },
    353: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band which defines the lower base for the DIGESTIVE pattern. Expectation is that price weakness into this area will stabilize, producing a bounce back into a sideways trade. The sentiment bias above here is positive up to the UP (UPSIDE PIVOT) resistance level. If the MKT is going to maintain an underlying negative tone, any strength in the session will need to exhaust below this resistance. A BREAKOUT above the UP is a positive signal that has the MKT poised to transition into a positive or neutral market state. A BREAKOUT below the R LEVEL should be used as a signal trigger to start selling positive reaction against the DP and DIR (DIRECTIONAL), as the digestive nature of the trade state will keep trading conditions choppy.`,
    },
    354: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the “base” for the digestive trade. Expectation is that price action will be susceptible to “corrective” pull-backs above this price point up to the UP (UPSIDE PIVOT). The UP represents the upper parameter of the digestive pattern. If the MKT is going to maintain an underlying negative tone, any show of strength in the session will top out below here. A BREAKOUT above this resistance pivot and the MKT will be poised for a positive transition. A BREAKOUT below the DP R LEVEL, if valid, should produce an emotional “drop” on the initial failure and continue to hold negative structure. Digestive trade states can be sloppy but if the lower parameter is defined properly, it has the potential to trigger a new leg lower on a failure. An emotional “release” on the initial breakdown is the “tell” for a new negative signal.`,
    },
    355: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=DIR`,
      overview: `The R LEVEL at the DIR (DIRECTIONAL) in this technical state signals an indecisive market state. The MKT is stuck in a digestive pattern and is building energy for a move. Below the DIR R LEVEL, the expectation is for an acceleration of the negative momentum sparking a fresh trend move south. A sustained failure from the DP (DOWNSIDE PIVOT) is needed to confirm this outlook. Any new pressure should just “go” holding negative structure. A rise above the R LEVEL puts the MKT in more of a DIGESTIVE trade state up to the UP (UPSIDE PIVOT) or UT1 (UPSIDE TARGET#1). No follow through is expected at this time. This is a difficult emotional trade state. Stick and move, protecting any unrealized profits if the MKT does not immediately release.`,
    },
    356: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines the upper extreme for the negative pattern. The UP is the optimal sell area for any corrective rise. Rallies up to this area are expected to exhaust and reverse with the potential to be the high point for any new reaction lower. If the MKT is going to resume its BEAR TREND, it will maintain negative structure. A rise above the UP R LEVEL confirms a break in the negative structure and a shift into either the neutral state or beginning of an extended corrective or positive transition. Typically if the MKT is going to transition, it will BREAKOUT above the UP early in the session and hold positive structure. A violation later in the session is more like a NEUTRAL SHIFT with a sideways trade expected. A failure from the DIR (DIRECTIONAL) after a test of the UP R LEVEL is a fresh negative, although the digestive nature of this state must be respected and aggressive position management implemented.`,
    },
    357: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL along with the UP (UPSIDE PIVOT) creates a resistance band that defines the upper extreme for the digestive pattern. This is the optimal sell area for any corrective rise. Rallies up to this area are expected to exhaust and reverse with the potential to be the high point for any new reaction lower. Size management off lower selling opportunities should be considered to ensure enough firepower if this opportunity is presented. A “worst case” scenario for this state is when the MKT breaks out to the downside (below the DP-DOWNSIDE PIVOT) early in the session and then has a low volatility rally all day, closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity. The “tell” for this counter trend signal will typically be a positive REVERSAL at the DP or a BREAKOUT above the DIR (DIRECTIONAL).`,
    },
    358: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET#1) in this technical state defines the upper extreme for the digestive pattern. The UT1 to UP (UPSIDE PIVOT) price band is the optimal sell area for any corrective rise. Rallies up to this area are expected to exhaust and reverse with the potential to be the high point for any new reaction lower. Size management off lower selling opportunities should be considered to ensure enough firepower if this opportunity is presented. A “worst case” scenario for this state is when the MKT breaks out to the downside (below the DP) early in the session and then has a low volatility rally all day, closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity. The “tell” for this counter trend signal will typically be a positive REVERSAL at the DP (DOWNSIDE PIVOT) or a BREAKOUT above the DIR (DIRECTIONAL).`,
    },
    359: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) in this technical state defines the upper extreme for any digestive trade. This is the expected high point for any positive squeeze and the optimal sell area. Rallies up to this inflection point are expected to exhaust and have the potential to be the high point for any new reaction lower. Size management off lower selling opportunities should be considered to ensure enough firepower if this opportunity is presented. A “worst case” scenario when the R LEVEL is above the UT1 is when the MKT breaks out to the downside (below the DP) early in the session and then has a low volatility rally all day closing at or near the R LEVEL. Recognizing this momentum shift early can produce a nice counter trend opportunity. The “tell” for this counter trend signal will typically be a positive REVERSAL at the DP or a BREAKOUT above the DIR (DIRECTIONAL).`,
    },
    361: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) in a BEAR TREND ACCELERATION EXTREME market state defines the expected low point for the current negative emotion. The market has produced a major “about face” technical shift and the R LEVEL represents the expected “squeeze” low for the current momentum. If the trade is going to turn digestive or revert to its previous positive state, it will “stabilize” at or above this price point. Follow any new pressure into this area with a positive strategy to capitalize on the 1st break in negative structure. A failure from the R LEVEL confirms the technical “reversal” and should maintain a trade below the DP (DOWNSIDE PIVOT) if it is truly transitioning into a negative market state. A BREAKOUT above the DIR (DIRECTIONAL) after a break under the DP signals a turn back into a positive trade state. Expectations are for more of a digestive trade with optimism resuming in the coming session.`,
    },
    362: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) in a BEAR TREND ACCELERATION EXTREME market state defines the expected low point for the current negative emotion. The market has produced a major “about face” technical shift and the R LEVEL represents the expected “squeeze” low for the current momentum. If the trade is going to turn digestive or revert to its previous positive state, it will “stabilize” at or above this price point. Follow any new pressure into this area with a positive strategy to capitalize on the 1st break in negative structure. A failure from the R LEVEL confirms the technical “reversal” and should maintain a trade below the DP (DOWNSIDE PIVOT) if it is truly transitioning into a negative market state. A BREAKOUT above the DIR (DIRECTIONAL) after a break under the DP signals a turn back into a positive trade state. Expectations are for more of a digestive trade with optimism resuming in the coming session.`,
    },
    363: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) in a BEAR TREND ACCELERATION EXTREME market state creates a support band that defines the expected low point for the current negative emotion. The market has produced a major “about face” technical shift and the DP-R LEVEL area represents the expected low point for the current momentum. If the trade is going to turn digestive or revert to its previous positive state, it will “stabilize” within this price band. Follow any negative release into this area with a positive strategy to capitalize on the 1st break in negative structure. A failure from the R LEVEL confirms the technical “reversal” and should maintain a trade below the DP if it is truly transitioning into a negative state.  A BREAKOUT above the DIR (DIRECTIONAL) after a break below the DP signals a turn back into a positive trade state. Expectations are for more of a digestive trade with optimism resuming in the coming session.`,
    },
    364: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) in a BEAR TREND ACCELERATION EXTREME market state defines the “base” keeping the current negative momentum in check. The market has produced a major “about face” technical shift and the R LEVEL represents the confirmation point for this transition. Sentiment bias at this inflection point is basically saying “prove it to me”. If the market is truly going to shift into a negative market state, it will BREAKOUT below the DP R and not look back. A bounce off this support level and it is more likely the prior session’s negative signal was a 1-day event and a resumption of the previous positive market state is the expectation. An early session false DP R BREAKOUT that is followed by a DIR (DIRECTIONAL) breach is the “tell” of a positive shift in market state. The key to any shift is structure. If the negative momentum is real, it will maintain negative lower move low lower move high structure. If not, then a shift into a NEUTRAL or positive market state is the outlook.`,
    },
    365: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) in a BEAR TREND ACCELERATION EXTREME market state defines the turning point for the prior session’s negative signal. If the market is going to follow through, it will maintain a trade below this price point and build negative structure. A trade above the DIR R LEVEL puts the negative signal in question and signals a sideways session with the market “digesting” the current events. The market is technically “on the fence” and its next move is expected to influence trading for the next 2-5 sessions. Only a BREAKOUT above the UP (UPSIDE PIVOT) negates the negative signal and sets up a return to a positive trade state. Don’t fight any positive structure above the DIR R LEVEL as it has the potential to completely take back losses made by the prior session’s negative event. In addition, a BREAKOUT below the DP (DOWNSIDE PIVOT) that holds negative structure has the potential to produce another “emotional” break. This market state is dynamic with the potential for major one-way moves or a rotational “flat-line” trading around the DIR R LEVEL.`,
    },
    366: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) in a BEAR TREND ACCELERATION EXTREME market state defines the lid containing the negative event signal. If the market is going to maintain a transition into a weak market state, it will hold negative structure below this price point. A BREAKOUT above the UP R LEVEL is a sign of strength that negates the negative signal and provides hope to return the market to a BULL TREND state. This is an emotional trade state so expect some “swings” with all trading within the CriticalRange. The market is trying to decide if the negative “shift” is real and until it can maintain a BREAKOUT below the DP (DOWNSIDE PIVOT), its negative intentions are suspect.`,
    },
    367: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) in a BEAR TREND ACCELERATION EXTREME creates a resistance band that defines the lid containing the negative event signal. If the market is going to sustain a transition into a weak market state, it will maintain negative structure below this price band. A violation of the R LEVEL is a sign of strength that negates the negative signal and provides hope to return the market to a BULL TREND state. This is an emotional trade state so expect some “swings” with all trading from the R LEVEL down to the DP (DOWNSIDE PIVOT). The market is trying to decide if the negative “shift” is real and until it can maintain a BREAKOUT below the DP, its negative intentions are suspect.`,
    },
    368: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) in a BEAR TREND ACCELERATION EXTREME defines the lid containing the negative event signal. If the market is going to continue its transition into a weak market state, it will maintain negative structure below this price point. The R LEVEL above the market signals a more “digestive” expectation with an eventual transition into a negative BEAR TREND structure. The positive momentum has been broken but there is work to be done before the negative momentum shift can produce sustainable follow through. A violation of the R LEVEL is a sign of strength that negates the negative signal and provides hope to return the market to a BULL TREND state. This is an emotional trade state so expect some erratic “swings” with all trading from the R LEVEL down to the DP [DOWNSIDE PIVOT]. The market is trying to decide if the negative “shift” is real and until it can build negative structure, its intentions are suspect. Key off the DIR (DIRECTIONAL) for the “tell” to the session bias.`,
    },
    369: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) in a BEAR TREND ACCELERATION EXTREME defines the lid containing the negative event signal. If the market is going to continue its transition into a weak market state, it will maintain negative structure below this price point. The R LEVEL above the market signals a more “digestive” expectation with an eventual transition into a negative BEAR TREND structure. The positive momentum has been broken but there is work to be done before the negative momentum shift can produce sustainable follow through. A violation of the R LEVEL is a sign of strength that negates the negative signal and provides hope to return the market to a BULL TREND state. This is an emotional trade state so expect some erratic “swings” with all trading from the R LEVEL down to the DP (DOWNSIDE PIVOT). The market is trying to decide if the negative “shift” is real and until it can build negative structure, its intentions are suspect. Key off the DIR (DIRECTIONAL) for the “tell” to the session bias.`,
    },
    371: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) in a BEAR TREND ACCELERATION EXTREME defines the lid containing the negative event signal. If the market is going to continue its transition into a weak market state, it will maintain negative structure below this price point. The R LEVEL above the market signals a more “digestive” expectation with an eventual transition into a negative BEAR TREND structure. The positive momentum has been broken but there is work to be done before the negative momentum shift can produce sustainable follow through. A violation of the R LEVEL is a sign of strength that negates the negative signal and provides hope to return the market to a BULL TREND state. This is an emotional trade state so expect some erratic “swings” with all trading from the R LEVEL down to the DP (DOWNSIDE PIVOT). The market is trying to decide if the negative “shift” is real and until it can build negative structure, its intentions are suspect. Key off the DIR (DIRECTIONAL) for the “tell” to the session bias.`,
    },
    372: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET#1) defines the price point that is expected to support the current negative momentum. The market is extended and the R LEVEL is the forecasted low for any new weakness. Look for exhaustive signals at or near the R LEVEL as they have the potential to be the low for a 2-5 day short-term bottom. A BREAKOUT above the DIR (DIRECTIONAL) after a test of this area confirms the exhaustive outlook and puts the UP (UPSIDE PIVOT) in jeopardy. The best BUY REVERSAL opportunities will happen early in the session. Any late challenges to support is a sign that the trade will turn digestive.`,
    },
    373: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) defines a price band that is expected to support the current negative momentum. The market is extended and this area represents the low point for the BEAR TREND NEGATIVE EXTREME. Look for positive exhaustive signals in the DP-R LEVEL range as they have the potential to be the base for a 2-5 day short-term bottom. A BREAKOUT above the DIR (DIRECTIONAL) after a test of this support is a sign of strength that must be confirmed above the UP (UPSIDE PIVOT) to trigger a transition into a corrective market state. Be patient and don’t force anything, as trading within the CriticalRange has the potential to be a difficult sideways mess. If the market is going to “go” it will remain emotional. Any volatility “downshift” is the “tell” that activity may “flat-line” for the balance of the session. Be more aggressive locking in profits until the UP resistance is taken out.`,
    },
    374: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL equal to the DP (DOWNSIDE PIVOT) defines the base for the underlying negative momentum. The market is extended and this level represents the “bottom” for the BEAR TREND. Look for exhaustive signals around this inflection point as they have the potential to be the low point for a 2-5 day short-term bottom. A BREAKOUT above the DIR (DIRECTIONAL) is a sign of strength that must be confirmed above the UP (UPSIDE PIVOT) to trigger a transition into a corrective market state. Be patient and don’t force anything as trading within the CriticalRange is likely to be a difficult sideways mess. If the market is going to “go”, it will remain emotional. Any volatility “downshift” is the “tell” that activity may “flat-line” for the balance of the session. Be more aggressive locking in profits until the UP resistance is violated.`,
    },
    375: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) in a BEAR TREND NEGATIVE EXTREME market state defines the turning point for the underlying negative momentum. If the market is going to remain on “edge” searching for support, it will maintain a trade below here. Expectation is that any new selling below this price point is the BEAR TREND’S “last release”. Be careful not to get sucked into any new “panic” as it is more likely a “trap”. A BREAKOUT above the R LEVEL and the potential for a “dramatic” corrective shift is real. Respect this inflection point but roll with a violation. The market’s reaction off the UP (UPSIDE PIVOT) is the “tell” to the forecast. If prices reject at the UP, the potential for a “flat-line” digestive session is high. A BREAKOUT above the UP confirms the extreme nature of this market state and 2-5 day short-term bottom.`,
    },
    376: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP (UPSIDE PIVOT) defines a hard turning point resistance level for the underlying negative momentum. The market is extended with the potential to be the “last release” of the BEAR TREND. The R LEVEL is the inflection point that keeps the trend in gear below this price point. A BREAKOUT above the R LEVEL and the potential for a “dramatic” corrective shift is real. Respect this resistance but roll with a violation. The outlook for trading within the Critical Range is sideways with the potential for a “flat-line” session. Be more aggressive locking in profits on any BUY DP (DOWNSIDE PIVOT) REVERSAL signals, as the real positive opportunity is above the R LEVEL.`,
    },
    377: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) defines a hard turning point resistance level for the underlying negative momentum. The market is extended with the potential to be the “last release” of the BEAR TREND. The R LEVEL is the inflection point that keeps the trend in gear below this price point with the UP-R LEVEL range acting as a “lid” to any new strength. A BREAKOUT above the R LEVEL and the potential for a “dramatic” corrective shift is real. Respect this resistance but roll with a violation. The outlook for trading within the CriticalRange is sideways with the potential for a “flat-line” session. Be more aggressive locking in profits on any BUY DP (DOWNSIDE PIVOT) REVERSAL signals, as the real positive opportunity is above the R LEVEL.`,
    },
    378: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET#1) defines the high point for any strength if the underlying tone is going to remain negative. A BREAKOUT above the R LEVEL confirms the EXTREME nature of this signature state and potential for a short-term bottom. Any exhaustive signals at or around the DP (DOWNSIDE PIVOT) should target the UP (UPSIDE PIVOT) and the R LEVEL with the expectation that the MKT will reject from this zone. SELL UP REVERSALS are recommended over FADEs as any challenge to resistance is expected to be laborious with the R LEVEL the decisive turning point. If executing a SELL R FADE, look for confirmation that the level is containing momentum instead of stepping in front of the initial challenge.`,
    },
    379: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET#1) defines the high point for any exhaustive bounce from the BEAR TREND EXTREME market state. If the underlying tone is going to remain negative, any challenge to resistance will build structure below the R LEVEL for a fresh decline. A breach of the R LEVEL is a sign of strength that confirms a shift into a corrective or digestive market state with the potential of producing a short-term bottom. The “tell” to any turn is the market maintaining a trade above the UP (UPSIDE PIVOT) after an R LEVEL violation. Any exhaustive signals at or around the DP (DOWNSIDE PIVOT) should target the UP and the R LEVEL with the expectation that the MKT will reject in this zone. Don’t be too anxious to sell this rally if the market is holding positive structure, as the extreme nature of this state will keep the trade emotional.`,
    },
    381: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines the expected exhaustion low point for the current negative momentum. The market is pressing the extreme and the R LEVEL represents the lower edge. If this level is properly defined, the market will produce a strong bounce on the initial test. A BREAKOUT below the DP (DOWNSIDE PIVOT) is the set up signal for the selling pressures “last flush”. Look for exhaustion BUY REVERSAL signals down to the R LEVEL. Any BUY opportunity will need to trade back above the DIR (DIRECTIONAL) to confirm a positive sentiment shift. Don’t be too anxious to sell the market against resistance as this state is typically the beginning of a transition into a NEUTRAL or positive market state.`,
    },
    382: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) defines the expected exhaustion low point for the current negative momentum. The market is pressing the extreme and the R LEVEL represents the lower edge. If this level is properly defined, the market will produce a strong bounce on the initial test. A BREAKOUT below the DP (DOWNSIDE PIVOT) is the set up signal for the selling pressures “last flush”. Look for exhaustion BUY REVERSAL signals down to the DT1. Any BUY opportunity will need to trade back above the DIR (DIRECTIONAL) to confirm a positive sentiment shift. Don’t be too anxious to sell the market against resistance as this state is typically the beginning of a transition into a NEUTRAL or positive market state.`,
    },
    383: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL below the DP (DOWNSIDE PIVOT) creates a support band that is expected to stabilize the current negative momentum. The market is pressing into the extreme and the DP-R LEVEL range defines the lower edge. If the area is properly identified, any new pressure will exhaust in this zone producing a sharp REVERSAL. A BREAKOUT below the DP (DOWNSIDE PIVOT) is unlikely to have enough energy to extend. The “tell” to any further losses is the market maintaining a trade below the DP after an R LEVEL failure. A BUY DP REVERSAL signal after an R LEVEL failure should be followed by an emotional advance to confirm a turn. If so, it has the potential to produce a short term bottom influencing the market for the next 3-5 sessions. Don’t be too anxious to sell the market against resistance as this state is typically the beginning of a transition into a NEUTRAL or positive market state.`,
    },
    384: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `A MKT in a BEAR TREND EXTREME signature state with the R LEVEL at the DP (DOWNSIDE PIVOT) is defining the lower extreme for the trade state. Expectations are for the MKT to produce an exhaustive reaction off this price point, resulting in a counter trend rally or digestive trade. Expect the market to build a base above here after a REVERSAL signal defining the lower end of a new consolidation pattern or a short term bottom. A break under the R LEVEL puts the MKT in an emotional state with fear a factor in any new weakness. Avoid pressing new weakness as no follow through is expected.`,
    },
    385: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL at the DIR (DIRECTIONAL) defines the turning point for the current negative momentum. The market is pressing into the lower extreme and the R LEVEL represents the initial breaking point for the current negative momentum. If this level is properly defined, the market will quickly move higher after a BREAKOUT above the DIR R LEVEL. A violation of the UP [UPSIDE PIVOT] is needed to confirm a short-term bottom with the potential to influence the market for the next 3-5 sessions. A held trade below the R LEVEL will keep the market in a panic state searching for support. Be aggressive on any BUY REVERSAL signals at minor and major PriceMap support levels, especially early in the session. The market is extended and any new selling is not expected to follow through. Any BUY opportunity will need to trade above the DIR R LEVEL to confirm any positive sentiment shift.`,
    },
    386: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL at the UP (UPSIDE PIVOT) is defining the resistance lid keeping the market offered. If the market is going to remain weak, it will maintain a trade below this price point.  Beware of new early weakness into support however as the extended nature of this state will keep the market vulnerable to an exhaustive positive reaction. DP (DOWNSIDE PIVOT) BUY REVERSAL strategies will present the best opportunities especially early in the trade period. Don’t force it however and FADE negative structure. This is an emotional trade state and extreme moves are common. SELL R FADE should “work” on the initial test. A sustained challenge to resistance is expected to give way, confirming the extended nature of the state.`,
    },
    387: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL above the UP (UPSIDE PIVOT) defines the price band that is containing the underlying negative momentum. If the market is going to remain weak, any new strength will fizzle below or within this zone. Be sure, the market is extended. New selling will have limited potential and is expected to stabilize at the DP (DOWNSIDE PIVOT). Look for BUY REVERSAL strategies, especially early in the trade period, using the R LEVEL as a target. Don’t force a BUY DP FADE against negative structure however; as this is an emotional trade state and extreme moves are common. An oversold market can become more oversold. SELL R FADE should “work” on the initial test. A sustained challenge into the R LEVEL or held trade above the UP is expected to give way to a positive transition following an R LEVEL BREAKOUT.`,
    },
    388: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL at the UT1 (UPSIDE TARGET #1), defines a price band with the UP (UPSIDE PIVOT) that is containing the underlying negative momentum. If the market is going to remain soft, any new strength will fizzle below or within this zone. Be sure, the market is extended. Any new selling will have limited potential and is expected to stabilize at the DP (DOWNSIDE PIVOT). Look for BUY REVERSAL strategies, especially early in the trade period using the R LEVEL as a target. Don’t force a BUY FADE against negative structure however, as this is an emotional trade state and extreme moves are common. SELL R FADE should “work” on the initial test. A sustained press into the R LEVEL or sustained trade above the UP is expected to give way to a positive transition following an R LEVEL BREAKOUT.`,
    },
    389: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `A market in a BEAR TREND EXTREME signature state with the R LEVEL above the UT1 (UPSIDE TARGET #1) defines the price point that is keeping the pressure on the underlying negative momentum. If the market is going to remain weak, any new strength will dissipate below here. The market is extended to the downside and any positive exhaustion signal should use the R LEVEL as the target. Look for BUY REVERSAL strategies at the DP (DOWNSIDE PIVOT), especially early in the trade period. This is an emotional trade state and extreme moves are common. Expectation is for the underlying weakness to keep its influence with the market building structure below the R LEVEL. A BREAKOUT above the R LEVEL is a sign of strength. If the market is indeed going to transition higher, it will maintain a trade above the UP (UPSIDE PIVOT) after an R LEVEL BREAKOUT. A fall back into the CriticalRange on a SELL UP REVERSAL strategy and the trade is expected to turn digestive.`,
    },
    391: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `A market in a BEAR TREND CORRECTION signature state with the R LEVEL below the DT1 (DOWNSIDE TARGET #1) is defining the base for any counter trend corrective rise. If the market is going to build positive structure, it will sustain a trade above this price level and produce a “corrective” rally. This is the optimal BUY area for the session with the expectation of a “corrective” rally. A break under the R LEVEL puts the MKT back in a BEAR TREND technical state with the expectation of a resumption of the lower move low pattern. Typically after an R LEVEL failure, if the market is going to follow through to the downside any positive reaction will top out below the DP (DOWNSIDE PIVOT).`,
    },
    392: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `A market in a BEAR TREND CORRECTION signature state with the R LEVEL below the DP (DOWNSIDE PIVOT) at the DT1 (DOWNSIDE TARGET #1) is defining a support band with the DP-DT1 R LEVEL range the base for a counter trend corrective rise. If the market is going to build positive structure, it will sustain a trade above this price band. This is the optimal BUY zone for the session with the expectation of a “corrective” rally. A break under the R LEVEL puts the MKT back in a BEAR TREND technical state with the expectation of a resumption of the lower move low pattern. Don’t press an R LEVEL failure as this structure bias is expected to be more “corrective” stop and go action versus BEAR TREND.`,
    },
    393: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `A market in a BEAR TREND CORRECTION signature state with the R LEVEL below the DP (DOWNSIDE PIVOT) at the CRX- (CRITICAL RANGE NEGATIVE EXTREME) is defining a support band (DP-R LEVEL) for a counter trend corrective rise. If the market is going to build positive structure, it will sustain a trade above this price band. This is the optimal BUY zone for the session with the expectation of a “corrective” rally. A break under the R LEVEL puts the MKT back in a BEAR TREND technical state with the expectation of a resumption of the lower move low pattern. The UP (UPSIDE PIVOT) represents a critical resistance point in this technical state as this is the “turning point” which, if violated, would potentially transition the negative momentum into NEUTRAL or a positive shift. Expect a tug-o-war between buyers and sellers in this area. A held BREAKOUT above the UP is the confirmation that the MKT is set for further corrective action and higher prices. A “false” BREAKOUT or REVERSAL is potentially the top of the next reaction to new move lows.`,
    },
    394: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `A market in a BEAR TREND CORRECTION signature state with the R LEVEL at the DP (DOWNSIDE PIVOT) is defining the low point for any weakness in the session if the market is going to produce a counter trend rally. Expect the market to build positive structure above here if the corrective trade is going to play out. A break under the R LEVEL puts the MKT back in a BEAR TREND technical state with the expectation of a resumption of the lower move low pattern. How the MKT behaves after a DP R LEVEL failure is the key “tell”. If it is bad and the trend is going to resume, the break will qualify below the CRX- (CRITICAL RANGE NEGATIVE EXTREME) with the DP containing any positive reaction. Expectation should be for steady negative price structure to build. A rotational trade around the DP R LEVEL after a failure is a sign that the trade will turn digestive.`,
    },
    395: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a BEAR TREND CORRECTION signature state with the R LEVEL at the DIR (DIRECTIONAL) is defining the base for any positive “corrective” shift. If the MKT is going to hold firm, it will maintain a trade above here. A failure from the DIR R LEVEL is a sign of weakness that threatens a return of the BEAR TREND but more likely a soft NEUTRAL trade. If the MKT is going higher, it should build positive structure [higher move highs and high move lows] above the DIR R LEVEL and not look back. If it cannot do this then the potential for a return to a BEAR TREND posture is real. If the MKT starts to build negative structure below the DIR R LEVEL, roll with it and maintain a negative outlook until that structure is broken at which time the outlook will be for a sideways mess. Trading above the DIR R LEVEL can be volatile as buying interest in emotional rallies can quickly disappear. Use the PriceMap framework to make position management adjustments and aggressively protect profits.`,
    },
    396: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL equal to the UP [UPSIDE PIVOT] defines the expected exhaustion area for any positive push. If the BEAR TREND is going to resume, the MKT will struggle to maintain a trade above here. Look for REVERSAL signals. A held breach of the R LEVEL has the potential to transition the market into a NEUTRAL or a positive state. Any move above the R LEVEL will be “corrective” so expect difficult trading conditions and chopping “vacuum” swings. A “false” UP R LEVEL BREAKOUT or REVERSAL is potentially the top of the next leg lower of the BEAR TREND. A BREAKOUT below the DP (DOWNSIDE PIVOT) should just “go” and maintain negative structure if it is indeed the beginning of a new trend move south. If not and the MKT cannot maintain negative structure, it is a “tell” that the trade will turn digestive. `,
    },
    397: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) but below the UT1 (Upside Target #1) defines the price range between the R LEVEL-UP as the expected exhaustion area for any positive push. If the BEAR TREND is going to resume, the MKT will reject in this area. Look for REVERSAL signals in this price zone. A held breach of the R LEVEL has the potential to transition the market into a NEUTRAL or a positive state. A “false” R LEVEL BREAKOUT or REVERSAL is potentially the top of the next leg of the BEAR TREND to new move lows. If the MKT trades under the DIR, it should remain below that price point if the BEAR TREND is going to engage. Work any negative structure with a trade vision of a multi session decline. If the MKT cannot maintain a trade below the DIR and instead trades back up through it, the expectation should be for a difficult sideways digestive trade with the R LEVEL the upper lid.`,
    },
    398: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) defines the price range between the UP-UT1 R LEVEL as the expected exhaustion area for any positive push. If the BEAR TREND is going to resume, the MKT will reject in this area. Look for REVERSAL signals to add to with confirmation. A held breach of the R LEVEL has the potential to transition the market into a NEUTRAL or a positive state. A “false” BREAKOUT or REVERSAL is potentially the top of the next move to new move lows. If the MKT trades under the DIR, it should remain below that price point if the BEAR TREND is going to engage. Work any negative structure as there is the potential for a multi session decline. If the MKT cannot maintain a trade below the DIR and instead trades back up through it, the expectation should be for a difficult sideways digestive trade with the R LEVEL the upper lid.`,
    },
    399: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) defines the expected exhaustion area for any positive push. If the BEAR TREND is going to resume, the MKT will exhaust in this area. Look for REVERSAL signals off any resistance below this level. A held breach of the R LEVEL has the potential to transition the market into a NEUTRAL or a positive state. A “false” BREAKOUT or REVERSAL is potentially the top of the next move to new move lows. If the MKT trades under the DIR (DIRECTIONAL), it should remain below that price point if the BEAR TREND is going to engage. Work any negative structure as there is the potential for a multi session decline. If the MKT cannot maintain a trade below the DIR and instead trades back up through it, the expectation should be for a difficult sideways digestive trade with the R LEVEL the upper lid. This market structure bias is a sign of underlying weakness which can produce an emotional squeeze into a good selling opportunity near the R LEVEL.`,
    },
    401: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines the base keeping the market in a solid state. If the aggressive buying is going to control the tone, any weakness will stabilize in this zone. A BREAKOUT below the R LEVEL signals a negative shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding negative structure and maintaining a trade below the DP. If so the likelihood of a short-term top influencing the action for the next 3-5 sessions is high. Avoid pressing new strength above the UP (UPSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. SELL UP REVERSAL strategies are more likely to produce a digestive trade that targets the R LEVEL rather than a trend reversal, as only below the R LEVEL is a negative transition confirmed.`,
    },
    402: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) creates a price band with the DP (DOWNSIDE PIVOT) defining the base keeping the market in a solid state. If the aggressive buying is going to control the tone, any weakness will stabilize in this zone. A BREAKOUT below the R LEVEL signals a negative shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding negative structure and maintaining a trade below the DP. If so, the likelihood of a short-term top influencing the action for the next 3-5 sessions is high. Avoid pressing new strength above the UP (UPSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. SELL UP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only below the R LEVEL is a negative transition confirmed.`,
    },
    403: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band that defines the base keeping the market in a solid state. If the aggressive buying is going to continue to control the tone, the market will maintain a trade above this price zone. A BREAKOUT below the R LEVEL signals a negative shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding negative structure below the DP-R LEVEL range. If so, the likelihood of a short-term top influencing the action for the next 3-5 sessions is high. Avoid pressing new strength above the UP (UPSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. SELL UP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal as only below the R LEVEL is a negative transition confirmed.`,
    },
    404: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL at the DP (DOWNSIDE PIVOT) defines the base keeping the market in a solid state. If the market is going to remain firm, it will maintain a trade above this price point. A BREAKOUT below the DP R LEVEL signals a negative shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding negative structure below the R LEVEL. If so, the likelihood of a short-term top influencing the action for the next 3-5 sessions is high. Avoid pressing new strength above the UP (UPSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. SELL UP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only below the DP is a negative transition confirmed.`,
    },
    405: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the market’s aggressive buy posture. If the market is going to remain on firm ground, it will maintain a trade above this inflection point. A failure from the R LEVEL is the 1st break in momentum which must be confirmed below the DP (DOWNSIDE PIVOT) to signal a potential short-term top. If the market is going to rotate lower, it should hold negative structure after a DP BREAKOUT. A BREAKOUT above the UP (UPSIDE PIVOT) is a sign of strength but at this time is more likely a “trap” than a new extension higher. Trail any new longs with a SELL REVERSAL as sharp corrective breaks are common in this market state.`,
    },
    406: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL at the UP (UPSIDE PIVOT) defines the expected upper resistance extreme of this technical state. If the market is going to “exhaust”, it will do so below the UP R LEVEL. Look for negative reversal signals at and around this inflection point. A held advance above the UP R LEVEL however and the emotional state can shift into a euphoric frenzy, setting up a sharp price move higher. Use the DIR (DIRECTIONAL) as a “tell” to the session opportunity. If the market is going to move out of the extreme, it will build negative structure below the DIR and not look back. A “stop and go” trade around the DIR is a sign that the trade will turn digestive.`,
    },
    407: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) creates a price band that defines the expected upper resistance extreme of this technical state. If the market is going to “exhaust”, it will do so below the R LEVEL. Look for negative reversal signals within the UP-R LEVEL zone. A held BREAKOUT above the R LEVEL however and the emotional state can shift into euphoric frenzy, setting up a sharp price advance. Use the DIR (DIRECTIONAL) as a “tell” to the session opportunity. If the market is going to move out of the extreme, it will build negative structure below the DIR and not look back. A “stop and go” trade around the DIR is a sign that the trade will turn digestive. The DP (DOWNSIDE PIVOT) is the confirmation turning point for any short-term top scenario and the base keeping the underlying positive momentum in command. `,
    },
    408: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a price band with the UP (UPSIDE PIVOT) that defines the expected upper resistance extreme of this technical state. If the market is going to “exhaust”, it will do so below the R LEVEL. Look for negative reversal signals within the UP-UT1 R LEVEL range.  A BREAKOUT above the R LEVEL will keep things emotionally optimistic as long as the market maintains a trade above the UP. A break below the UP after a higher push is more neutral than negative, as only below the DIR [DIRECTIONAL] does the potential for a short term top come into play. The DP (DOWNSIDE PIVOT) is the confirmation turning point for any short-term top scenario and the base keeping the underlying positive momentum in command.`,
    },
    409: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) defines the expected upper resistance extreme of the current positive momentum. If the rally is going to exhaust, it will do so below the R LEVEL. Look for negative reversal signals at or near this inflection point.  A BREAKOUT above the R LEVEL is a sign of strength that will keep positive party going as long as the market maintains a trade above the UP. A break under the UP after a higher push is more neutral than negative, as only below the DIR (DIRECTIONAL) does the potential for a short term top come into play. The DP (DOWNSIDE PIVOT) is the confirmation turning point for any short-term top scenario and the base keeping the underlying positive momentum in command.`,
    },
    501: {
      theme: `SENTIMENT BIAS - POSITIVE - R&lt;DT1`,
      overview: `The R LEVEL below the DT1 (DOWNSIDE TARGET #1) defines the expected lower support extreme of the current negative momentum. If the market is going to stabilize, it will do so above the R LEVEL. Look for exhaustive positive reversal signals at or near this inflection point. A failure below the R LEVEL is a sign of weakness that will keep things emotionally hard pressed as long as the market maintains a trade below the DP. A rise above the DP after a lower press is more neutral than positive, as only above the DIR (DIRECTIONAL) does the potential for a short term bottom come into play. The UP (UPSIDE PIVOT) is the confirmation turning point for any short-term bottom scenario and the lid keeping the underlying negative momentum in command.`,
    },
    502: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL equal to the DT1 (DOWNSIDE TARGET #1) creates a price band with the DP [DOWNSIDE PIVOT] that defines the expected lower support extreme of this technical state. If the market is going to stabilize, it will do so above the R LEVEL. Look for exhaustive positive reversal signals within the DP-DT1 R LEVEL zone.  A failure below the R LEVEL will keep things emotionally hard pressed as long as the market maintains a trade below the DP. A rise above the DP after a lower press is more neutral than positive, as only above the DIR (DIRECTIONAL) does the potential for a short term bottom come into play. The UP (UPSIDE PIVOT) is the confirmation turning point for any short-term bottom scenario and the lid keeping the underlying negative momentum in command. `,
    },
    503: {
      theme: `SENTIMENT BIAS - POSITIVE - R=CRX-`,
      overview: `The R LEVEL below the DP (DOWNSIDE PIVOT) creates a price band that defines the expected lower support extreme of this technical state. If the market is going to stabilize, it will do so above the R LEVEL. Look for exhaustive positive reversal signals within the DP-R LEVEL zone. A held failure below the R LEVEL however and the emotional state can shift into panic setting up a sharp sell-off. Use the DIR (DIRECTIONAL) as a “tell” to the session opportunity. If the market is going to move out of the extreme, it will build positive structure above the DIR and not look back. A “stop and go” trade around the DIR is a sign that the trade will turn digestive. The UP (UPSIDE PIVOT) is the confirmation turning point for any short-term bottom scenario and the lid keeping the underlying negative momentum in command. `,
    },
    504: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL at the DP (DOWNSIDE PIVOT) defines the expected lower support extreme of this technical state. If the market is going to stabilize, it will do so above here. Look for exhaustive positive reversal signals at and around this price point.  A held failure below the DP R LEVEL however and the emotional state can shift into panic setting up a sharp sell-off. Use the DIR (DIRECTIONAL) as a “tell” to the session opportunity. If the market is going to move out of the extreme, it will build positive structure above the DIR and not look back. A “stop and go” trade around the DIR is a sign that the trade will turn digestive.`,
    },
    505: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `The R LEVEL equal to the DIR (DIRECTIONAL) defines the turning point for the market’s aggressive sell posture. If the market is going to remain on edge, it will maintain a trade below this inflection point. A rise above the R LEVEL is the 1st break in momentum which must be confirmed above the UP (UPSIDE PIVOT) to signal a potential short-term bottom. If the market is going to rotate higher, it should hold positive structure after a UP BREAKOUT. A failure from the DP (DOWNSIDE PIVOT) is a sign of weakness, however it is more likely a “trap” than a new extension lower. Trail any new sales with a BUY REVERSAL as sharp corrective pull-backs are common in this market state.`,
    },
    506: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL at the UP (UPSIDE PIVOT) defines the lid keeping the market under pressure. If the market is going to remain hard pressed, it will maintain a trade below this price point. A BREAKOUT above the UP R LEVEL signals a positive shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding positive structure above the R LEVEL. If so the likelihood of a short-term bottom influencing the action for the next 3-5 sessions is high. Avoid pressing new weakness below the DP (DOWNSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. BUY DP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only above the UP is a positive transition confirmed.`,
    },
    507: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL above the UP (UPSIDE PIVOT) creates a price band that defines the lid keeping the market under pressure. If the aggressive sell pressure is going to continue to weigh on the market, it will maintain a trade below this price point. A BREAKOUT above the R LEVEL signals a positive shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding positive structure above the UP-R LEVEL price band. If so, the likelihood of a short-term bottom influencing the action for the next 3-5 sessions is high. Avoid pressing new weakness below the DP (DOWNSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. BUY DP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only above the R LEVEL is a positive transition confirmed.`,
    },
    508: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL equal to the UT1 (UPSIDE TARGET #1) creates a price band with the UP (UPSIDE PIVOT) that defines the lid keeping the market under pressure. If the aggressive sell pressure is going to continue to weigh on the market, any show of strength will fizzle within this zone. A BREAKOUT above the R LEVEL signals a positive shift in sentiment with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding positive structure and maintaining a trade above the UP. If so, the likelihood of a short-term bottom influencing the action for the next 3-5 sessions is high. Avoid pressing new weakness below the DP (DOWNSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. BUY DP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only above the R LEVEL is a positive transition confirmed.`,
    },
    509: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL above the UT1 (UPSIDE TARGET #1) defines the lid keeping the market under pressure. If the aggressive sell pressure is going to continue to weigh on the market, any show of strength will fizzle below this price point. A BREAKOUT above the R LEVEL signals a break in the negative momentum with the potential to produce a significant corrective turn. The “tell” to the success of this signal is the market holding positive structure and maintaining a trade above the UP (UPSIDE PIVOT). If so, the likelihood of a short-term bottom influencing the action for the next 3-5 sessions is high. Avoid pressing new weakness below the DP (DOWNSIDE PIVOT). The market is at an extreme and follow through at this time is unlikely. BUY DP REVERSAL strategies are more likely to produce a digestive trade rather than a trend reversal, as only above the R LEVEL is a positive transition confirmed.`,
    },
    901: {
      theme: `SENTIMENT BIAS - POSITIVE - R &lt; DT1`,
      overview: `The R LEVEL that is below the DT1 (DOWNSIDE TARGET #1) defines the support level that the market is focused on for its next move. Currently the MKT is poised for an advance and the R LEVEL represents the “hold or fold” level for the current pattern. If the MKT builds positive structure above here, it will have some “legs” and should just “go” if it is indeed going to BREAKOUT. Expectation is for a steady climb. Any exhaustive negative REVERSAL signal is a sign that the trade will turn digestive. A failure from the R LEVEL is expected to have some emotional energy with the potential to be the start of a new transition lower. Here too the break should maintain negative structure as any exhaustive positive REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    902: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DT1`,
      overview: `The R LEVEL that is equal to the DT1 (DOWNSIDE TARGET #1) defines the support level that the market is focused on for its next move. Currently the MKT is poised for an advance and the R LEVEL represents the “hold or fold” level for the current pattern. If the MKT builds positive structure above here, it will have some “legs” and should just “go” if it is indeed going to BREAKOUT. Expectation is for a steady climb. Any exhaustive negative REVERSAL signal is a sign that the trade will turn digestive. A failure from the R LEVEL is expected to have some emotional energy with the potential to be the start of a new transition lower. Here too the break should maintain negative structure as any exhaustive positive REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    903: {
      theme: `SENTIMENT BIAS - POSITIVE - DP&gt;R&lt;DT1`,
      overview: `The R LEVEL that is between the DP (DOWNSIDE PIVOT) and the DT1 (DOWNSIDE TARGET #1) defines the support range that the market is focused on for its next move. Currently the MKT is poised for an advance and the R LEVEL represents the “hold or fold” level for the current pattern. If the MKT builds positive structure above here, it will have some “legs” and should just “go” if it is indeed going to BREAKOUT. Expectation is for a steady climb. Any exhaustive negative REVERSAL signal is a sign that the trade will turn digestive. A failure from the R LEVEL is expected to have some emotional energy with the potential to be the start of a new transition lower. Here too the break should maintain negative structure as any exhaustive positive REVERSAL is a sign that the action is just an expansion of the current trading range parameters`,
    },
    904: {
      theme: `SENTIMENT BIAS - POSITIVE - R=DP`,
      overview: `The R LEVEL that is equal to the lower CriticalRange extreme (DP-DOWNSIDE PIVOT) defines the support level that the market is focused on for its next move. Currently the MKT is poised for an advance and the R LEVEL represents the “hold or fold” level for the current pattern. If the MKT builds positive structure above here, it will have some “legs” and should just “go” if it is indeed going to BREAKOUT. Expectation is for a steady climb. Any exhaustive negative REVERSAL signal is a sign that the trade will turn digestive. A failure from the DP R LEVEL is expected to have some emotional energy with the potential to be the start of a new transition lower. Here too the break should maintain negative structure as any exhaustive positive REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    905: {
      theme: `SENTIMENT BIAS - NO BIAS - R=DIR`,
      overview: `A market in a NEUTRAL DIGESTION TRANSITION signature state with the R LEVEL at the DIR (DIRECTIONAL) is “on the fence” in its truest sense. This state is typically the result of a multi session digestive “coil”. The MKT is wound up and ready to make a move, pivoting off the R LEVEL inflection point. This is the most balanced market state with MKT sentiment aligned at 1 price point - the R LEVEL. Expectation is for the market to show its hand and make a move off this level. If not, a “flat line” rotational trade around the DIR R level is forecasted. Use the CriticalRange extremes (UP - UPSIDE PIVOT and DP - DOWNSIDE PIVOT) as confirmation triggers. If the MKT is going to BREAKOUT into a new TREND move, it will maintain positive or negative structure after a UP or DP BREAKOUT signal. If not, the market produces an exhaustive REVERSAL. A move to the opposite CriticalRange extreme should be expected`,
    },
    906: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UP`,
      overview: `The R LEVEL that is equal to the upper CriticalRange extreme [UP-UPSIDE PIVOT] defines the resistance level that the market is focused on for its next move. Currently the MKT is poised for a decline and the R LEVEL represents the “make or break” level for the current pattern. If the MKT builds negative structure below here, it will start to “circle the drain” and should just “release” if it is indeed going to BREAKOUT. Expectation is for a steady price deterioration. Any positive REVERSAL signal is a sign that the trade will turn digestive. A violation of the UP R LEVEL is expected to provide a “pop” of energy with the potential to be the start of a new transition higher. Here too the rally should maintain positive structure as any exhaustive negative REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    907: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=CRX+`,
      overview: `The R LEVEL that is within the UP [UPSIDE PIVOT] to UT1 (UPSIDE TARGET #1) defines the resistance range with the UP that the market is focused on for its next move. Currently the MKT is poised for a decline and the R LEVEL represents the “make or break” level for the current pattern. If the MKT builds negative structure below here, it will start to “circle the drain” and should just “release” if it is indeed going to BREAKOUT. Expectation is for a steady price deterioration. Any positive REVERSAL signal is a sign that the trade will turn digestive. A violation of the R LEVEL is expected to provide a “pop” of energy with the potential to be the start of a new transition higher. Here too the rally should maintain positive structure as any exhaustive negative REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    908: {
      theme: `SENTIMENT BIAS - NEGATIVE - R=UT1`,
      overview: `The R LEVEL that is equal to the UT1 (UPSIDE TARGET #1) defines the resistance level that the market is focused on for its next move. Currently the MKT is poised for a decline and the R LEVEL represents the “make or break” level for the current pattern. If the MKT builds negative structure below here, it will start to “circle the drain” and should just “release” if it is indeed going to BREAKOUT. Expectation is for a steady price deterioration. Any positive REVERSAL signal is a sign that the trade will turn digestive. A violation of the R LEVEL is expected to provide a “pop” of energy with the potential to be the start of a new transition higher. Here too the rally should maintain positive structure as any exhaustive negative REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
    909: {
      theme: `SENTIMENT BIAS - NEGATIVE - R&gt;UT1`,
      overview: `The R LEVEL that is above the UT1 (UPSIDE TARGET #1) defines the resistance level that the market is focused on for its next move. Currently the MKT is poised for a decline and the R LEVEL represents the “make or break” level for the current pattern. If the MKT builds negative structure below here, it will start to “circle the drain” and should just “release” if it is indeed going to BREAKOUT. Expectation is for a steady price deterioration. Any positive REVERSAL signal is a sign that the trade will turn digestive. A violation of the R LEVEL is expected to provide a “pop” of energy with the potential to be the start of a new transition higher. Here too the rally should maintain positive structure as any exhaustive negative REVERSAL is a sign that the action is just an expansion of the current trading range parameters.`,
    },
  };
  return structure[code] || structure["error"];
}
