
// static list of binance data types
const binanceFilterList = [
    { label: 'CRYPTO', value: 'binance' },
    { label: 'FUTURES', value: 'futures' },
    { label: 'STOCKS', value: 'stocks' },
    { label: 'ETF', value: 'etf' },
    { label: 'MKT GRID 1', value: 'mktgrid1' },
]

const selectedBlock = {
    block: {},
    blockName: '',
};


// States
const dashboardFilterState = (function states() {
    return {
        dateFilterList: [],
        binanceFilterList: binanceFilterList,
        indicatorFilterList: [],
        cryptoBlockList: [],
        selectedBlock: selectedBlock,
        marketExchangeList: []
    }
})()

export default dashboardFilterState;