import { Fragment, useState } from "react";
import './DashboardInsight.css';
import DashboardInsightStructure from "./DashboardInsightStructure";
import DashboardInsightStrategy from "./DashboardInsightStategry";
import DashboardInsightOptions from "./DashboardInsightOptions";
import DashboardInsightState from "./DashoardInsightState";
import DashboardInsightTilesPart from "./DashBoardInsightTilesPart";
import DashboardInsightDownload from "./DashoardInsightDownload";
import { useSelector } from "react-redux";

const DashboardInsight = (props) => {
    const {
        codeNumber,
        structureMetrices,
        trendNumber,
        tileData,
        BreakupText,
        marketAttributesOneImages,
        marketAttributesTwoImages,
        headerFormater,
        getDownloadTabLinks
    } = props;

    const [tab , setTab] = useState("state");

    const planType = useSelector((state) => state.planType.value);

    return (
        <Fragment>
            <div style={{width : planType == "Tier1" ? "47%" : "40%"}}>
                <div className="tabs-header">

                    <div className={tab === "state" ? "color_tab":"tab"} onClick={()=> setTab("state")}>STATE</div>
                    <div className={tab === "structure" ? "color_tab":"tab"} onClick={()=> setTab("structure")}>STRUCTURE</div>
                    {(planType === "Tier1" || planType === "Tier2") && <div className={tab === "strategy" ? "color_tab":"tab"} onClick={() => setTab("strategy")}>STRATEGY</div>}
                    {planType === "Tier2" && <div className={tab === "options" ? "color_tab":"tab"} onClick={()=> setTab("options")}>OPTIONS</div>}
                    {(planType === "Tier1" || planType === "Tier2") && <div className={tab === "downloads" ? "color_tab":"tab"} onClick={()=> setTab("downloads")}>DOWNLOADS</div>}
                </div>
                { (tab !== "downloads") &&
                    <DashboardInsightTilesPart tileData={tileData}/>
                }

                {tab === "structure" && <DashboardInsightStructure codeNumber={codeNumber} trendNumber={trendNumber} BreakupText={BreakupText} structureMetrices={structureMetrices} headerFormater={headerFormater}/>}
                {tab === "strategy" && <DashboardInsightStrategy codeNumber={codeNumber} trendNumber={trendNumber} BreakupText={BreakupText}/>}
                {tab === "state" && <DashboardInsightState codeNumber={codeNumber} marketAttributesOneImages={marketAttributesOneImages} marketAttributesTwoImages={marketAttributesTwoImages} BreakupText={BreakupText}/>}
                {tab === "options" && <DashboardInsightOptions codeNumber={codeNumber} trendNumber={trendNumber} BreakupText={BreakupText}/>}
                {tab === "downloads" && <DashboardInsightDownload getDownloadTabLinks={getDownloadTabLinks}/>}
            </div>


        </Fragment>
    )
}

export default DashboardInsight;
