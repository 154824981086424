import { PathConfig } from "../shared/app.config";

export async function checkDuePayment() {
    const url = PathConfig.AUTH_SERVICE_BASE_URL + `/Payment/CheckDuePayment`;

    const response = await fetch(url,{
        credentials: "include"
    });

    return response.json();
}
