import { configureStore } from '@reduxjs/toolkit'
import dataModeReducer from './dataModeSlice'
import blockDataReducer from './blockDataSlice'
import selectedDateReducer from './selectedDateSlice'
import planTypeSlice from './planTypeSlice'
import rValueSlice from './rValueSlice'

export const store = configureStore({
  reducer: {
    dataMode: dataModeReducer,
    blockData:blockDataReducer,
    selectedDate : selectedDateReducer,
    planType : planTypeSlice,
    rValue : rValueSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch