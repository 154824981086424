import { createSlice } from '@reduxjs/toolkit'



export const selectedDateSlice = createSlice({
  name: 'blockData',
  initialState: {
    selectedDate : null
  },
  reducers: {
    setSelectedDate: (state,action) => {
      state.value = action.payload
    }
  },
})

export const {setSelectedDate} = selectedDateSlice.actions

export default selectedDateSlice.reducer