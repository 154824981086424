import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  dataMode: "binance",
}

export const dataModeSlice = createSlice({
  name: 'dataMode',
  initialState: {
    dataMode: "binance"
  },
  reducers: {
    setDataMode: (state,action) => {
      state.value = action.payload
    }
  },
})

export const { setDataMode} = dataModeSlice.actions

export const selectDataMode = (state)=> state.dataMode.value;

export default dataModeSlice.reducer