import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import DashboardContainer from "../components/dashboard/DashboardContainer"
import SignInCallback from "../components/signin-callback/SigninCallback"

const AppRoutes = ({ isLoggedIn }) => {
  if (isLoggedIn) return (
    <Routes>
      <Route path="/signin-callback" element={<SignInCallback />} />
      <Route path="/dashboard" element={<DashboardContainer />} />
      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  )
  return (
    <Routes>
      <Route path="/signin-callback" element={<SignInCallback />} />
    </Routes>
  )
}

export default AppRoutes
