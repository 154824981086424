import { Fragment, useEffect, useState } from "react";
import './DashboardInsight.css';
import struct_img from "../../assets/wsImages/newImages/AttributePivotal.png";
import Strategy from "./InsightData/strategy";

const DashboardInsightState = (props) => {
    const [stateValue,setStateValue] = useState()
    const [imgUrl,setImgUrl] = useState();
    const [graphImgUrl1,setGraphImgUrl1] = useState();
    const [graphImgUrl2,setGraphImgUrl2] = useState();
    const [graphImgUrl3,setGraphImgUrl3] = useState();
    const [graphImgUrl4,setGraphImgUrl4] = useState();
    const {
      codeNumber,
      marketAttributesOneImages,
      marketAttributesTwoImages
    } = props

    useEffect(()=>{
        setStateValue(Strategy(codeNumber))

        if(codeNumber) {
          loadImage(codeNumber)
        }

        if(marketAttributesOneImages && marketAttributesTwoImages) {
          loadGraphImage()
        }
    }, [codeNumber, marketAttributesOneImages, marketAttributesTwoImages])

    const loadImage = (trendNumber) => {
        import(`../../assets/playbook/MSB_${trendNumber}.png`).then(image => {
          setImgUrl(image.default);
        });
      };

    const loadGraphImage = () => {
        import(`../../assets/wsImages/newImages/${props.marketAttributesOneImages[0].imgName}`).then(image => {
          setGraphImgUrl1(image.default);
        });
        import(`../../assets/wsImages/newImages/${props.marketAttributesOneImages[1].imgName}`).then(image => {
            setGraphImgUrl2(image.default);
          });
        import(`../../assets/wsImages/newImages/${props.marketAttributesTwoImages[0].imgName}`).then(image => {
          setGraphImgUrl3(image.default);
        });
        import(`../../assets/wsImages/newImages/${props.marketAttributesTwoImages[1].imgName}`).then(image => {
          setGraphImgUrl4(image.default);
        });
      };

    return (
        <Fragment>
                <div className="content-text-area">
                        {stateValue && <p dangerouslySetInnerHTML={{__html: props.BreakupText(stateValue.overview)}}/>}
                </div>

                {props.marketAttributesOneImages && <div className="image-discription-div">

                    <div className="market-graph-div">
                        <div className="market-graph-image-div">
                            <span className="header-span">{props.marketAttributesOneImages[0].imgType}</span>
                            <img src={graphImgUrl1}/>
                            <span className="footer-span">{props.marketAttributesOneImages[0].imgDescription}</span>
                        </div>
                        <div className="market-graph-image-div">
                            <span className="header-span">{props.marketAttributesOneImages[1].imgType}</span>
                            <img src={graphImgUrl2}/>
                            <span className="footer-span">{props.marketAttributesOneImages[1].imgDescription}</span>
                        </div>
                    </div>

                    <div className="market-expectation-div">
                        <div className="header-div">Market Expectations</div>
                        <div><img src={imgUrl}/></div>
                    </div>

                    <div className="market-graph-div">
                        <div className="market-graph-image-div">
                            <span className="header-span">{props.marketAttributesTwoImages[0].imgType}</span>
                            <img src={graphImgUrl3}/>
                            <span className="footer-span">{props.marketAttributesTwoImages[0].imgDescription}</span>
                        </div>
                        <div className="market-graph-image-div">
                            <span className="header-span">{props.marketAttributesTwoImages[1].imgType}</span>
                            <img src={graphImgUrl4}/>
                            <span className="footer-span">{props.marketAttributesTwoImages[1].imgDescription}</span>
                        </div>
                    </div>
                </div>}
        </Fragment>
    )
}

export default DashboardInsightState;
