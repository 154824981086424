import { Fragment, useEffect, useState } from "react"
import './DashboardInsight.css'
import Strategy from "./InsightData/strategy"

const DashboardInsightStrategy = (props) => {
    const [strategyValue,setStrategyValue] = useState()
    const [optImgUrl,setOptImgUrl] = useState()
    const [hdgeImgUrl,setHdgeImgUrl] = useState()
    const { trendNumber } = props

    useEffect(() => {
        setStrategyValue(Strategy(trendNumber))
        if(trendNumber) loadImage(trendNumber)
    }, [trendNumber])

    const loadImage = (trendNumber) => {
        import(`../../assets/playbook/MSB_${trendNumber}_O.png`).then(image => {
          setOptImgUrl(image.default)
        })
        import(`../../assets/playbook/MSB_${trendNumber}_H.png`).then(image => {
            setHdgeImgUrl(image.default)
        })
    }

    useEffect(()=>{
        if(strategyValue)
            strategyValue.overview = strategyValue?.overview.replaceAll('class','className')
    },[strategyValue])

    return (
        <Fragment>
                <div className="content-text-area-stategry">
                        {strategyValue && <div className="stategry-header" dangerouslySetInnerHTML={{__html: props.BreakupText(strategyValue.theme.replace(/HEDGE/g, '<br />HEDGE'))}}/>}
                        {strategyValue && <p dangerouslySetInnerHTML={{__html: props.BreakupText(strategyValue.overview)}}/>}
                </div>

                <div className="image-discription-div-stategry">
                <div className="market-structure-div-stategry">
                        <div className="header-div">Optimal Strategy</div>
                        <div><img src={optImgUrl}/></div>
                    </div>
                    <div className="market-structure-div-stategry">
                        <div className="header-div">Hedge Strategy</div>
                        <div><img src={hdgeImgUrl}/></div>
                    </div>
                </div>
        </Fragment>
    )
}

export default DashboardInsightStrategy
