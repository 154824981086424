import { Fragment, useEffect } from "react";
import {useSelector} from "react-redux";
import './DashboardData.css';

const DashboardData = (props) => {
    const { risk, renderPriceMapBlock, rText, rHead } = props;

    let blockData = useSelector((state) => state.blockData.value);
    const planType = useSelector((state) => state.planType.value);

    return (
        <Fragment>
            { blockData && <div className="dashboard-data-section">
                <div style={{ display : "flex"}}>
                    <div className="data-table-div">

                        <div className="data-table-tile-transparent">
                        </div>
                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#000", backgroundColor: "#90FAFA"}}>UT4 +c</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ut4 === blockData.r ? "#0F0" :""}}>{blockData.ut4 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ut4)}</div></div> : renderPriceMapBlock(blockData.ut4)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#B22222"}}>**</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ul08 === blockData.r ? "#0F0" :""}}>{blockData.ul08 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ul08)}</div></div> : renderPriceMapBlock(blockData.ul08)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#B22222"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ul07 === blockData.r ? "#0F0" :""}}>{blockData.ul07 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ul07)}</div></div> : renderPriceMapBlock(blockData.ul07)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#FF0000"}}>UT3</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ut3 === blockData.r ? "#0F0" :""}}>{blockData.ut3 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ut3)}</div></div> : renderPriceMapBlock(blockData.ut3)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#B22222"}}>**</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ul05 === blockData.r ? "#0F0" :""}}>{blockData.ul05 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ul05)}</div></div> : renderPriceMapBlock(blockData.ul05)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#B22222"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ul04 === blockData.r ? "#0F0" :""}}>{blockData.ul04 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ul04)}</div></div> : renderPriceMapBlock(blockData.ul04)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#FF0000"}}>UT2</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ut2 === blockData.r ? "#0F0" :""}}>{blockData.ut2 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ut2)}</div></div> : renderPriceMapBlock(blockData.ut2)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#B22222"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ul02 === blockData.r ? "#0F0" :""}}>{blockData.ul02 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ul02)}</div></div> : renderPriceMapBlock(blockData.ul02)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#FF0000"}}>UT1</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.ut1 === blockData.r ? "#0F0" :""}}>{blockData.ut1 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.ut1)}</div></div> : renderPriceMapBlock(blockData.ut1)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#000", backgroundColor: "#FF48FB"}}>CRX+</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.crxplus === blockData.r ? "#0F0" :""}}>{blockData.crxplus === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.crxplus)}</div></div> : renderPriceMapBlock(blockData.crxplus)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#FF0000"}}>UP</div>
                            <div className="data-table-value-1" style={{color: blockData.up === blockData.r ? "#000" : "#FFF", backgroundColor: blockData.up === blockData.r ? "#0F0" :"#FF0000"}}>{ blockData.up === blockData.r? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.up)}</div></div> : renderPriceMapBlock(blockData.up)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#8B4513"}}>CR+</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.crplus === blockData.r ? "#0F0" :""}}>{blockData.crplus === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.crplus)}</div></div> : renderPriceMapBlock(blockData.crplus)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#000", backgroundColor: "#FFFF00"}}>DIR</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dir == blockData.r ? "#0F0" :"#FFFF00"}}>{blockData.dir == blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dir)}</div></div> : renderPriceMapBlock(blockData.dir)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#8B4513"}}>CR-</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.crminus === blockData.r ? "#0F0" :""}}>{blockData.crminus === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.crminus)}</div></div> : renderPriceMapBlock(blockData.crminus)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000FF"}}>DP</div>
                            <div className="data-table-value-1" style={{color: blockData.dp == blockData.r ? "#000" : "#FFF", backgroundColor:  blockData.dp == blockData.r ? "#0F0" :"#0000FF"}}>{ blockData.dp == blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dp)}</div></div> : renderPriceMapBlock(blockData.dp)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#9370DB"}}>CRX-</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.crxminus === blockData.r ? "#0F0" :""}}>{blockData.crxminus === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.crxminus)}</div></div> : renderPriceMapBlock(blockData.crxminus)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000FF"}}>DT1</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dt1 === blockData.r ? "#0F0" :""}}>{blockData.dt1 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dt1)}</div></div> : renderPriceMapBlock(blockData.dt1)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000CD"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dl02 === blockData.r ? "#0F0" :""}}>{blockData.dl02 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dl02)}</div></div> : renderPriceMapBlock(blockData.dl02)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000FF"}}>DT2</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dt2 === blockData.r ? "#0F0" :""}}>{blockData.dt2 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dt2)}</div></div> : renderPriceMapBlock(blockData.dt2)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000CD"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dl04 === blockData.r ? "#0F0" :""}}>{blockData.dl04 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dl04)}</div></div> : renderPriceMapBlock(blockData.dl04)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000CD"}}>**</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dl05 === blockData.r ? "#0F0" :""}}>{blockData.dl05 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dl05)}</div></div> : renderPriceMapBlock(blockData.dl05)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000FF"}}>DT3</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dt3 === blockData.r ? "#0F0" :""}}>{ blockData.dt3 === blockData.r? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dt3)}</div></div> : renderPriceMapBlock(blockData.dt3)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000CD"}}>*</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dl07 === blockData.r ? "#0F0" :""}}>{blockData.dl07 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dl07)}</div></div> : renderPriceMapBlock(blockData.dl07)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#0000CD"}}>**</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dl08 === blockData.r ? "#0F0" :""}}>{blockData.dl08 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dl08)}</div></div> : renderPriceMapBlock(blockData.dl08)}</div>
                        </div>

                        <div className="data-table-tile">
                            <div className="data-table-key-1" style={{color: "#FFF", backgroundColor: "#FF74FF"}}>DT4 -c</div>
                            <div className="data-table-value-1" style={{color: "#000", backgroundColor: blockData.dt4 === blockData.r ? "#0F0" :""}}>{blockData.dt4 === blockData.r ? <div className="r-div"><div>R</div><div>{renderPriceMapBlock(blockData.dt4)}</div></div> : renderPriceMapBlock(blockData.dt4)}</div>
                        </div>
                    </div>
                    <div className="data-table-risk-div">
                    <div className="risk-part" style={{height:28*1, color : "white" }}><div className="risk-header-div">RISK</div></div>
                        {risk && <div className="risk-part" style={{height:28*risk[0] , color: "#000", backgroundColor: "#0F0"}}><div className="risk-header-div-content">RISK OFF</div></div>}
                        {risk && <div className="risk-part" style={{height:28*risk[1] , color: "#000", backgroundColor: "#0FF"}}><div className="risk-header-div-content">LESS RISK</div></div>}
                        {risk && risk[2] != 0 && <div className="risk-part" style={{height:28*risk[2] , color: "#000", backgroundColor: "#9900FF"}}><div className="risk-header-div-content">PIVOTAL</div></div>}
                        {risk && <div className="risk-part" style={{height:28*risk[3] , color: "#000", backgroundColor: "#FF0"}}><div className="risk-header-div-content">@ RISK</div></div>}
                        {risk && <div className="risk-part" style={{height:28*risk[4] , color: "#000", backgroundColor: "#F00"}}><div className="risk-header-div-content">RISK ON</div></div>}
                    </div>
                </div>
                <div className="bottom-div">
                    <div className="bottom-div-two">
                        <div className="one">{rHead}</div>
                        <div className="two">{`R = ${rText}`}</div>
                    </div>
                </div>
            </div>}
        </Fragment>
    )
}

export default DashboardData;
