import { Log, UserManager } from 'oidc-client'
import { PathConfig } from '../shared/app.config'

const isDev = process.env.REACT_APP_NODE_ENVIRONMENT === 'dm-dev'
const isLocal = process.env.REACT_APP_NODE_ENVIRONMENT === 'local'

class AuthService {
  constructor() {
    const apiBaseUrl = PathConfig.AUTH_SERVICE_BASE_URL || window.location.origin
    const uiBaseUrl = PathConfig.UI_BASE || window.location.origin

    const settings = {
      authority: apiBaseUrl,
      client_id: "jsservice-web-app",
      redirect_uri: `${uiBaseUrl}/signin-callback${(isDev || isLocal) ? '' : '.html'}`,
      silent_redirect_uri: uiBaseUrl,
      post_logout_redirect_uri: uiBaseUrl,
      response_type: 'code',
      scope: 'openid profile email',
      metadata: {
        issuer: apiBaseUrl,
        authorization_endpoint: `${apiBaseUrl}/connect/authorize`,
        token_endpoint: `${apiBaseUrl}/connect/token`,
        userinfo_endpoint: `${apiBaseUrl}/connect/userinfo`,
        end_session_endpoint: `${apiBaseUrl}/connect/endsession`,
        check_session_iframe: `${apiBaseUrl}/connect/checksession`,
        revocation_endpoint: `${apiBaseUrl}/connect/revocation`,
        introspection_endpoint: `${apiBaseUrl}/connect/introspect`
      }
    }
    this.userManager = new UserManager(settings)

    Log.logger = console
    Log.level = Log.INFO
  }

  getUser() {
    return this.userManager.getUser()
  }

  login() {
    return this.userManager.signinRedirect()
  }

  renewToken() {
    return this.userManager.signinSilent()
  }

  logout() {
    return this.getUser().then(user => {
      const userIdToken = user ? user.id_token : null
      this.userManager.removeUser()
      this.userManager.clearStaleState()
      return this.userManager.signoutRedirect({ id_token_hint: userIdToken })
    })
  }
}

export const authService = new AuthService()
