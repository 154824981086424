import { Fragment } from "react";
import { calenderSvg } from "../../assets/CommonSvg/commonSvg";
import SelectField from "../../shared-components/select-field/SelectField";
import { getColorBlockRValueRegime } from "../dashboard/assets/ColorBlockValues";
import getMsbTextColor from "../dashboard/assets/MsbTextColor";
import getColorBlockTrendTypeObj from "../dashboard/assets/TrendType";
import { getSymbol } from "../dashboard/DashboardColorBlockDetails";
import './DashboardFilter.css';
import refresh_img from "../../assets/dashboard/refresh-icon.png"
import { useSelector } from "react-redux";

const AllSymbols = ["BAND","OP","MASK","HFT","APT","XBT", "BTC", "ETH", "USDT", "USDC",
    "BNB", "BUSD", "ADA", "XRP", "SOL", "DOGE", "DOT", "DAI", "TRX", "LEO", "SHIB", "WBTC", "AVAX", "MATIC",
    "UNI", "LTC", "FTT", "LINK", "CRO", "XLM", "NEAR", "ALGO", "BCH", "ATOM", "ETC", "XMR", "VET", "MANA",
    "FLOW", "HBAR", "ICP", "EGLD", "FIL", "THETA", "APE", "XTZ", "HNT", "SAND", "TUSD", "BSV", "AXS", "KCS",
    "ZEC", "USDP", "EOS", "MKR", "AAVE", "MIOTA", "HT", "USDN", "GRT", "BTT", "KLAY", "XEC", "USDD", "OKB",
    "WAVES", "FTM", "QNT", "NEO", "PAXG", "RUNE", "BAT", "CHZ", "ZIL", "LRC", "STX", "DASH", "GMT", "CAKE",
    "KSM", "FEI", "ENJ", "CELO", "CRV", "GALA", "AMP", "1INCH", "NEXO", "XDC", "HOT", "XEM", "SNX", "KAVA",
    "MINA", "DCR", "GT", "ONE", "AR", "KDA", "XYM", "QTUM", "GNO", "BTG", "COMP", "BORA", "IOST", "CVX",
    "AUDIO", "TFUEL", "ROSE", "OMG", "ICX", "STORJ", "TWT", "IOTX", "KNC", "ANKR", "ZRX", "GLM", "SRM",
    "RVN", "CEL", "JST", "SXP", "ONT", "MXC", "SC", "WOO", "LPT", "BTRST", "YFI", "GLMR", "WAXP", "SKL", "IMX", "BAL",
    "GUSD", "CHSB", "ENS", "ZEN", "NFT", "HIVE", "POLY", "XCH", "UMA", "LDO", "SCRT", "SUSHI", "DGB", "PLA", "ELON", "CSPR",
    "RSR", "MX", "LSK", "KEEP", "EPS", "BNT", "RNDR", "RENBTC", "VGX", "ORBS", "PEOPLE", "NU", "PUNDIX", "CKB", "MED", "OCEAN",
    "ONG", "CEEK", "ACA", "CHR", "REV", "WXT", "SUSD", "CELR", "XPRT", "REN", "XNO", "WIN", "POWR", "FLUX", "ILV", "CFX", "VLX",
    "ARDR", "SNT", "RLY", "C98", "COTI", "DYDX", "OGN", "MTL", "REP", "TEL", "DAO", "CVC", "DENT", "CTSI", "WRX", "SYS", "STEEM",
    "DAG", "USTC", "FXS"
];

const DashboardFilter = (props) => {
    const {
        binanceFilterList,
        dateFilterList,
        indicatorFilterList,
        cryptoBlockList,
        selectedBlock,
        marketExchangeList
    } = props.filterState;
    const {
        getDatabyDate,
        getDatabyMarketName,
        onClickMarketDropDown,
        onClickMarketExchange,
        selectedDropdownIndex
    } = props;



    const planType = useSelector((state) => state.planType.value);
    const blockData = useSelector((state) => state.blockData.value);

    return (
        <Fragment>
            <div style={{width : planType == "Tier1" ? "47%" : "40%"}}>
                <div className="dashboard-filter-div">
                    <div className="date-dropdown">
                        <div className="calender-icon">{calenderSvg}</div>
                    <SelectField
                        options={dateFilterList}
                        className="dropdown-select-date"
                        onChangeHandler={(e) => getDatabyDate(e.target.value)}
                        isDateDropDown={true}
                    />
                    </div>
                    <SelectField
                        options={binanceFilterList}
                        className="dropdown-select-market"
                        onChangeHandler={(e) => getDatabyMarketName(e.target.value)}
                        isMarketDropDown={true}
                    />
                    <SelectField
                        options={marketExchangeList}
                        className="dropdown-select-excahnges"
                        onChangeHandler={(e)=> onClickMarketExchange(e.target.value)}
                    />
                    <SelectField
                        options={indicatorFilterList}
                        className="dropdown-select"
                        onChangeHandler={(e) => onClickMarketDropDown(e.target.value)}
                    />

                    <a href="/">
                        <img className="refresh-icon" src={refresh_img}/>
                    </a>
                </div>
                <div className="boxes-wrap purple-bk-lighter" style={{maxHeight : planType == "Tier1" ? "812px" : "756px"}}>
                    {
                        cryptoBlockList.map((block, index) => {

                            const abbreviatedName = block?.name?.split(".");
                            let symbolObject = getSymbol(abbreviatedName[1]);
                            let blockName = abbreviatedName?.length === 2 ? abbreviatedName[0] : symbolObject?.coinSymbol;

                            let trendTypeData = getColorBlockTrendTypeObj(block);
                            let backgroundColor = trendTypeData?.bgColor ? trendTypeData.bgColor : "#fff";

                            const textColor = getMsbTextColor(block?.posState);

                            let regime = getColorBlockRValueRegime(block);

                            let isBorder = (block === selectedBlock.block || block === blockData) ? true : false;
                                var coinSymbol = blockName;
                                var currency = "";

                                if(selectedDropdownIndex == 0){
                                AllSymbols.forEach(theSymbol => {

                                if (blockName.indexOf(theSymbol) == 0 && symbolObject.currency =="") {
                                        coinSymbol = theSymbol;
                                        currency = blockName.replace(theSymbol, '');
                                        blockName = theSymbol
                                        symbolObject.currency = currency

                                    }
                                });
                            }
                            if (
                                block.MARKET === "N/A" ||
                                blockName === "SPX" ||
                                blockName === "VIX" ||
                                blockName === "USDBRL" ||
                                block?.MARKET === 'BLOCKFILLS'
                            ) {
                                return
                            }
                            return (
                                <div key={index} className='block-div' style={{
                                    backgroundColor: backgroundColor, color: textColor
                                }}
                                    onClick={() => props.onClickBlockHandler(block, blockName)}
                                >
                                    <div className='block-active-cell' style={{border: isBorder ? "4px solid white" : "" , padding : isBorder ? "2px" : "5px"}}>
                                        <div className="trend-type-name">{trendTypeData?.typeDescription}</div>
                                        <div className="block-name-div">
                                            <div
                                                className="market-name"
                                            >
                                                {block?.MARKET}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: blockName.length <= 5
                                                        ? "20px"
                                                        : (20-(20-blockName.length)*.6)+"px"
                                                }}
                                                className="block-name"
                                            >
                                                {blockName}
                                            </div>
                                            <div
                                                className="market-name-two"
                                            >
                                                {symbolObject.currency}
                                            </div>
                                        </div>
                                        <div className="pos-state-div">
                                            <label className="">{block?.posState}</label>
                                            <label className="">{regime}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardFilter;
