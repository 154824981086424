import { Fragment, useEffect, useState } from "react";
import DashboardInsight from "./DashboardInsight";
import { useSelector} from 'react-redux'
import { FetchExportLinks } from "../../services/DashboardServices";
import moment from "moment";

const DashboardInsightContainer = (props) => {
    const {
      codeNumber,
      trendNumber,
      tileData,
      marketAttributesOneImages,
      marketAttributesTwoImages,
      latestDate,
      planType
    } = props;

    let blockData = useSelector((state)=> state.blockData.value);

    const [structureMetrices , setStructureMetrices] = useState();

    useEffect(() => {
        if (blockData) setStructureMetricesValue()
    }, [blockData]);

    const BreakupText=(originalText)=>{
        let everyPeriod = 2;
        let overviewArr = originalText.split(". ");

        for (let i = 0; i < overviewArr.length; i++) {
            if (i + 1 !== overviewArr.length) {
                overviewArr[i] = overviewArr[i] + ". ";
            }
            if ((i + 1) % everyPeriod === 0) {
                overviewArr[i] = overviewArr[i] + "<br/><br/>";
            }
        }

        return overviewArr.join("");
    }
    function forceDownload(blob, filename) {
        var a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      // Current blob size limit is around 500MB for browsers
      function downloadResource(url, filename) {
        if (!filename) filename = url.split('\\').pop().split('/').pop();
        fetch(url, {
            headers: new Headers({
              'Origin': window.location.origin
            }),
            mode: 'cors'
          })
          .then(response => response.blob())
          .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename);
          })
          .catch(e => console.error(e));
      }
    const formatStructureMetrics = (val,ticksize) =>{
        let text = val.toString();
        if (
            text.includes(".") &&
            ticksize != undefined &&
            ticksize.includes("/") &&
            ticksize.split("/").length == 2
          ) {
            var multiplicationfactor = ticksize.split("/")[1];
            var precision = 0.1;

            if (multiplicationfactor == 32) {
              var value = Math.floor((Number(text) % 1) * multiplicationfactor);

              text = Math.floor(Number(text)).toString() + "-";
              if (value.toString().length == 1) {
                text += "0";
              } else if (value.toString().length == 0) {
                text += "00";
              }
              text = text + value.toString();
            } else {
              if (multiplicationfactor > 9) {
                precision = 0.01;
              }
              var value = Math.floor(Number(text) / precision) * precision;
              value = Math.round(value * 100000000) / 100000000;
              if (multiplicationfactor == 4) {
                var rem = Math.floor((Number(text) % 1) * 4) * 2;
                text = Math.floor(value).toString() + "-" + rem.toString();
              } else if (
                !value.toString().includes(".") &&
                multiplicationfactor < 10
              ) {
                if (multiplicationfactor == 4) {
                  var rem = Math.floor((Number(text) % 1) * 4) * 2;
                  text = value.toString() + "-" + rem.toString();
                } else {
                  text = value.toString() + "-" + (value % 1).toString();
                }
              } else if (
                !value.toString().includes(".") &&
                multiplicationfactor > 9
              ) {
                text = value.toString() + "-00";
              } else {
                text = value.toString().replace(".", "-");
                if (
                  value.toString().split(".")[1].length == 1 &&
                  multiplicationfactor > 9
                ) {
                  text = text + "0";
                }
              }
            }
          } else if (
            text.includes(".") &&
            ticksize != undefined &&
            ticksize.includes("/") &&
            ticksize.split("/").length == 3
          ) {
            var multiplicationfactor = ticksize
              .split("/")[1]
              .split(" ")[0];
            var multiplicationfactor2 = ticksize.split("/")[2];

            if (multiplicationfactor == 32) {
              var value = Math.floor((Number(text) % 1) * multiplicationfactor);
              var rem = ((Number(text) % 1) * multiplicationfactor) % 1;
              text = Math.floor(Number(text)).toString() + "-";
              if (value.toString().length == 1) {
                text += "0";
              } else if (value.toString().length == 0) {
                text += "00";
              }
              var precision = 0.1;
              text = text + value.toString();
              if (multiplicationfactor > 9) {
                precision = 0.01;
              }
              if (multiplicationfactor == 2) precision = 0.5;

              var remValue = Math.round(rem / precision) * precision;
              remValue = Math.round(remValue * 100000000) / 100000000;
              if (multiplicationfactor2 == 4) {
                var res = Math.floor(rem * 10);
                if (res == 4) {
                  res = 5;
                } else if (res == 6) {
                  res = 7;
                }
                text = text + res.toString();
              } else {
                text = text + Math.floor(remValue * 10).toString();
              }
            } else {
              var precision = 0.005;

              var value = Math.floor(Number(text) / precision) * precision;
              value = Math.round(value * 100000000) / 100000000;

              if (value % 1 == 0) text = value.toString() + "-000";
              else {
                text = value.toString().replace(".", "-");
                if (value.toString().split(".")[1].length == 2) {
                  text = text + "0";
                }
              }
            }
          } else if (
            ticksize != undefined &&
            !ticksize.includes("/") &&
            !Number(ticksize).toString().includes('.') && !Number(ticksize).toString().includes('e')
          ) {
            text = Math.round(Number(text)).toString();
          } else if (
            text.includes(".") &&
            ticksize != undefined &&
            !ticksize.includes("/") &&
            (Number(ticksize).toString().includes('.')
        || Number(ticksize).toString().includes('e'))
          ) {
            var origticksize = ticksize
            ticksize = Number(ticksize).toString();
            var precision = Number(ticksize);
            var value = Math.round(Number(text) / precision) * precision;
            value = Math.round(value * 100000000) / 100000000;
            var decm_value = value % 1;
            decm_value = (
              Math.round(decm_value * 100000000) / 100000000
            ).toString();
            if (!decm_value.includes(".")) {
              decm_value += ".0";
            }
            if(ticksize.includes('.'))
            while((decm_value.split('.')[1]).length < (ticksize.split('.')[1]).length){
              decm_value = decm_value + '0'
            }
          else{
            while((decm_value.split('.')[1]).length < (origticksize.split('.')[1]).length){
              decm_value = decm_value + '0'
            }
            }
            text = Math.floor(value).toString() + "." + decm_value.split(".")[1];
          }

        text = text.replace("-", "'");
        return text;
    };
    const setStructureMetricesValue = () =>{
        let ticksize = blockData.ticksize;
        let tempAPMD = formatStructureMetrics(blockData.apmd,ticksize);
        let tempHalfAMPD =  formatStructureMetrics(blockData.apmd / 2,ticksize);
        let tempAPMDTick =  formatStructureMetrics(blockData.apmdticks,ticksize);
        let tempVAR =  formatStructureMetrics(blockData.var,ticksize);
        let tempAD  =  formatStructureMetrics(blockData.ad,ticksize);
        let tempMSD =  formatStructureMetrics(blockData.msd,ticksize);
        let metricesObj = {
            AMPD : tempAPMD,
            HalfAMPD : tempHalfAMPD,
            APMDTick:tempAPMDTick,
            VAR : tempVAR,
            AD : tempAD,
            MSD :tempMSD
        }

        setStructureMetrices(metricesObj);
    };

    const headerFormater =(value) =>{
        if(value && value !== "structure error"){
            let splitHeaderTab2 = value.split(" - ");
            let textOriginal = splitHeaderTab2[1];

            splitHeaderTab2[1] = splitHeaderTab2[1].replace(/NEGATIVE/g, '<span class="red-pastel">' + textOriginal + ' BIAS' + '</span>');
            splitHeaderTab2[1] = splitHeaderTab2[1].replace(/POSITIVE/g, '<span class="blue">' + textOriginal + ' BIAS' + '</span>');
            splitHeaderTab2[1] = splitHeaderTab2[1].replace(/NO BIAS/g, '<span class="tan">' + textOriginal + '</span>');

            splitHeaderTab2[2] = '<span class="green-text">' + splitHeaderTab2[2] + '</span>';

            return '<br/><span class="section-info-text-header">' + splitHeaderTab2.join(" - ") + '</span>';
        }
    }
    function displayIframe(url){
        console.log(url);

    }

    const getDownloadTabLinks = async (currentDate) =>{

        const downloadsSection = document.querySelector(
            ".main-header-div .downloadSection"
        );
        let disableOpacity = 0.3;

        var arrLinks = await FetchExportLinks(currentDate ? currentDate : latestDate, planType);
        var groupedFiles = new Array(12);

        downloadsSection.innerHTML =
            '<div class="download-group">' +
            '<div class="download-group-name"></div>' +
            '<div class="download-item download-item-1"></div>' +
            '<div class="download-item download-item-2"></div>' +
            '<div class="download-item download-item-3"></div>' +
            '<div class="download-item download-item-4"></div>' +
            '<div class="update-date">Updated</div>' +
        '</div>';
    //let groupedFiles = new Array(20);

    let linkArray = [];

    for (let i = 0; i < arrLinks.length; i++) {

        linkArray = [];

        switch (arrLinks[i].filename) {

            case "PriceMap-Crypto.csv":
                //Crypto 1 - PriceMap
                groupedFiles[0] = arrLinks[i];
                break;
            case "PriceMap-Crypto_daily.csv":
                //Crypto 1 - Indicators
                groupedFiles[1] = arrLinks[i];
                break;
            case "Bookmap-Crypto.csv":
                //Crypto 1 - Bookmap
                groupedFiles[3] = arrLinks[i];
                break;
            case "PriceMap-Futures.csv":
                //Futures 1 - PriceMap
                groupedFiles[4] = arrLinks[i];
                break;
            case "PriceMap-Futures_daily.csv":
                //Futures 1 - Indicators
                groupedFiles[5] = arrLinks[i];
                break;
            case "PriceMap Bookmap Futures.csv":
                //Futures 1 - Bookmap
                //groupedFiles[11] = arrLinks[i];
                break;


            case "PriceMap-StocksETF.csv":
                //STOCKS - PriceMap
                groupedFiles[8] = arrLinks[i];
                break;
            case "PriceMap-StocksETF_daily.csv":
                    //STOCKS - PriceMap
                groupedFiles[9] = arrLinks[i];
                break;

        }
    };
    let downloadContainer;
    let date = null;
    for (let i = 0; i < groupedFiles.length; i++) {
        if (i % 4 == 0) {
            downloadContainer = document.createElement("div");
            downloadContainer.classList.add("download-group");

            let downloadItemType = document.createElement("div");
            downloadItemType.classList.add("download-group-name");

            let downloadCheckBox = document.createElement("div");
            downloadCheckBox.classList.add("download-group-check");

            let inputCheckBox = document.createElement("input");
            inputCheckBox.setAttribute("type","checkbox");
            inputCheckBox.classList.add("download-checkboxes");

            if (i < 4) {

                downloadItemType.innerHTML = "CRYPTO ";
                downloadContainer.classList.add("crypto-1");

                inputCheckBox.setAttribute("id", "crypto-1-group");
                inputCheckBox.setAttribute("value", "0,1,2,3");
            }

            else if (i < 8) {
                downloadItemType.innerHTML = "FUTURES ";
                downloadContainer.classList.add("futures-1");

                inputCheckBox.setAttribute("id", "futures-1-group");
                inputCheckBox.setAttribute("value", "4,5,6,7");
            }

            else if (i < 12)
            {
                downloadItemType.innerHTML = "STOCKS / ETF ";
                downloadContainer.classList.add("stocks");

                inputCheckBox.setAttribute("id", "stocks-group");
                inputCheckBox.setAttribute("value", "8,9,10,11");
            }

            downloadContainer.appendChild(downloadItemType);

        }

        let downloadItemCsv = document.createElement("div");
        downloadItemCsv.classList.add("download-item");



        if (i % 4 == 0) {
            //pricemap
            downloadItemCsv.classList.add("download-item-1");
            let csvLink = document.createElement("a");
            csvLink.innerHTML = "PriceMap";
			csvLink.setAttribute("allow-downloads", "");

            if (groupedFiles[i] != null) {
                date = groupedFiles[i].date;
                csvLink.setAttribute("href",groupedFiles[i].link); // download url

            }
            else {
                csvLink.style.opacity = disableOpacity;
            }

            downloadItemCsv.appendChild(csvLink);

            downloadContainer.appendChild(downloadItemCsv);
        }
        else if (i % 4 == 1) {
            //indicator
            downloadItemCsv.classList.add("download-item-2");
            let csvLink = document.createElement("a");
            csvLink.innerHTML = "PriceMap Daily";
			csvLink.setAttribute("allow-downloads", "");

            if (groupedFiles[i] != null) {
                date = groupedFiles[i].date;
                csvLink.setAttribute("href",groupedFiles[i].link); // download url

            }
            else {
                csvLink.style.opacity = disableOpacity;
            }

            downloadItemCsv.appendChild(csvLink);

            downloadContainer.appendChild(downloadItemCsv);
        }

        else if (i % 4 == 3) {
            //bookmap and update date
            downloadItemCsv.classList.add("download-item-4");
            let csvLink = document.createElement("a");
            csvLink.innerHTML = "Bookmap";

            if (groupedFiles[i] != null) {
                date = groupedFiles[i].date;
                csvLink.setAttribute("href",groupedFiles[i].link); // download url

            }
            else {
                csvLink.style.opacity = disableOpacity;
            }
            downloadItemCsv.appendChild(csvLink);

            let csvDate = document.createElement("div");
            csvDate.classList.add("update-date");
            var utc = moment.utc(date).format("YYYY-MM-DD LT");
            csvDate.innerHTML = moment(utc).local().format("YYYY-MM-DD LT") + "  UTC";
            date = null;

            downloadContainer.appendChild(downloadItemCsv);
            downloadContainer.appendChild(csvDate);

            downloadsSection.appendChild(downloadContainer);


        }

    };
        return downloadsSection;
    }

    return (
        <Fragment>
            <DashboardInsight
              codeNumber={codeNumber}
              trendNumber={trendNumber}
              tileData={tileData}
              BreakupText={BreakupText}
              marketAttributesOneImages={marketAttributesOneImages}
              marketAttributesTwoImages={marketAttributesTwoImages}
              structureMetrices={structureMetrices}
              headerFormater={headerFormater}
              getDownloadTabLinks={getDownloadTabLinks}
            />
        </Fragment>
    )
}

export default DashboardInsightContainer;
