export const HTTP_SCHEME = "http";
export const HTTPS_SCHEME = "https";

export const SCHEME = HTTPS_SCHEME + "://";
export const SCHEME_UI = HTTP_SCHEME + "://";

export const DEV_API_DOMAIN = "localhost:44310";
export const PROD_ADI_DOMAIN = "";

let config;

if (process.env.REACT_APP_NODE_ENVIRONMENT === 'dm-dev') {
    config = require('./path_config-dm-dev').default;
} else if (process.env.REACT_APP_NODE_ENVIRONMENT === 'local') {
    config = require('./path_config-local').default;
} else {
    config = require('./path_config').default;
}

export const PathConfig = config
