import { createSlice } from '@reduxjs/toolkit'



export const rValueSlice = createSlice({
  name: 'rValue',
  initialState: {
    rValue: null
  },
  reducers: {
    SetRValue: (state,action) => {
      state.value = action.payload
    }
  },
})

export const {SetRValue} = rValueSlice.actions

export default rValueSlice.reducer