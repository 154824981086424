import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const SelectField = props => {

    const dataMode = useSelector(state=> state.dataMode.value);
    const selectDate = useSelector((state)=> state.selectedDate.value);

    let selectedMarket = false;
    let selectedDate = false;
    let isSelected = false;


    return (
        <div>
            <select
                className={props.className}
                onBlur={props.onBlur}
                onChange={(event) => {
                    props.onChangeHandler(event);
                }}
            >
                {props.options?.map((option, index) => {
                    isSelected = index === 0 ? true : false
                    selectedMarket = option?.value === dataMode ? true : false
                    selectedDate = option?.value == selectDate ? true : false
                    if(option.value === "Exchanges" || option.value === "R"){
                        return
                    }


                    return (
                        <option key={index} value={option?.value} selected={props.isMarketDropDown ? selectedMarket : 
                            props.isDateDropDown ? selectedDate : isSelected}>{option?.label}</option>
                    )
                })}
            </select>
        </div>
    )
}

export default SelectField;