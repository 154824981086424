export default function getMarketAttributesName(code) {
    if (code == "error") return;

  const marketAttributes = {
    100: {
      1: "AttributeNoMomentum",
      2: "AttributeNoBias",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    110: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    120: {
      1: "AttributeMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityUP",
      4: "AttributeTrendPositive",
    },
    130: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributePositiveExtreme",
    },
    150: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    160: {
      1: "AttributeMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityUP",
      4: "AttributeTrendNegative",
    },
    170: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNegativeExtreme",
    },
    210: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    220: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributePositiveExtreme",
    },
    230: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributePositiveExtreme",
    },
    240: {
      1: "AttributeNoMomentum",
      2: "AttributeNoBias",
      3: "AttributeVolalityUP",
      4: "AttributePositiveExtreme",
    },
    250: {
      1: "AttributeNoMomentum",
      2: "AttributeNoBias",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    260: {
      1: "AttributeMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityUP",
      4: "AttributeTrendPositive",
    },
    270: {
      1: "AttributeMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityUP",
      4: "AttributeTrendPositive",
    },
    280: {
      1: "AttributeMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityUP",
      4: "AttributeTrendPositive",
    },
    320: {
      1: "AttributeMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityUP",
      4: "AttributeTrendNegative",
    },
    330: {
      1: "AttributeMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityUP",
      4: "AttributeTrendNegative",
    },
    340: {
      1: "AttributeMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityUP",
      4: "AttributeTrendNegative",
    },
    350: {
      1: "AttributeNoMomentum",
      2: "AttributeNoBias",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    360: {
      1: "AttributeNoMomentum",
      2: "AttributeNoBias",
      3: "AttributeVolalityUP",
      4: "AttributeNegativeExtreme",
    },
    370: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNegativeExtreme",
    },
    380: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNegativeExtreme",
    },
    390: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNonTrend",
    },
    400: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasDN",
      3: "AttributeVolalityDN",
      4: "AttributePositiveExtreme",
    },
    500: {
      1: "AttributeNoMomentum",
      2: "AttributeBiasUP",
      3: "AttributeVolalityDN",
      4: "AttributeNegativeExtreme",
    },
    900: {
      1: "AttributePivotal",
      2: "AttributeNoBias",
      3: "AttributeVolalityMixed",
      4: "AttributeTrendNonTrend",
    }
    };

    return marketAttributes[code];

}

export function getAttributesTypeByName(name) {

    const attributeTypes = {
        "AttributeBiasDN": {
            "type": "Bias",
            "description": "NEGATIVE BIAS"
        },
        "AttributeBiasUP": {
            "type": "Bias",
            "description": "POSITIVE BIAS"
        },
        "AttributeMomentum": {
            "type": "Momentum",
            "description": "MOMENTUM"
        },
        "AttributeNegativeExtreme": {
            "type": "Trend Type",
            "description": "NEGATIVE EXTREME"
        },
        "AttributeNoBias": {
            "type": "Bias",
            "description": "NO BIAS"
        },
        "AttributeNoMomentum": {
            "type": "Momentum",
            "description": "NO MOMENTUM"
        },
        "AttributeNonTrend": {
            "type": "Trend Type",
            "description": "NON TREND"
        },
        "AttributePivotal": {
            "type": "Trend Type",
            "description": "PIVOTAL"
        },
        "AttributePositiveExtreme": {
            "type": "Trend Type",
            "description": "POSITIVE EXTREME"
        },
        "AttributeTrendNegative": {
            "type": "Trend Type",
            "description": "TREND NEGATIVE"
        },
        "AttributeTrendNonTrend": {
            "type": "Trend Type",
            "description": "TREND NON TREND"
        },
        "AttributeTrendPositive": {
            "type": "Trend Type",
            "description": "TREND POSITIVE"
        },
        "AttributeVolalityDN": {
            "type": "Volatility",
            "description": "DOWN"
        },
        "AttributeVolalityMixed": {
            "type": "Volatility",
            "description": "MIXED"
        },
        "AttributeVolalityUP": {
            "type": "Volatility",
            "description": "UP"
        },
    };

    return attributeTypes[name];
}

export function FuturesDescriptionDate(DescriptionDate) {

  let splitDesc = DescriptionDate.split("-");
  let monthCode = "";
  let year = "";

  let monthKey = {
      F: "JAN",
      G: "FEB",
      H: "MAR",
      J: "APR",
      K: "MAY",
      M: "JUN",
      N: "JUL",
      Q: "AUG",
      U: "SEP",
      V: "OCT",
      X: "NOV",
      Z: "DEC",
  };


  year = splitDesc[1].slice(-2);
  monthCode = splitDesc[1].slice((splitDesc[1].length - 3), -2);

  return monthKey[monthCode] + year
}

export function countDecimals(value) {
  const numStr = String(value);
  if (!numStr.includes('.')) return 0;
  return numStr.split(".")[1].length || 0;
}
