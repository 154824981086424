export default function Strategy(code) {
    const strategy = {
        error: {
            theme: "strategy error",
            overview: "strategy error",
        },
        100: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at the UP on a FADE; <span class="red">SELL PUTS</span> at the DP on a FADE <br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; <span class="blue">BUY</span> <span class="red">PUTS</span>/<span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT <br />
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            <div class="text-space"></div>
            In a Neutral Digestive (NEU) market state look to sell options, either early in the trading session on market rallies and breaks at Major PriceMap levels or minor level extremes.  Until a breakout is confirmed above the UP (Upside Pivot) or below the DP (Downside Pivot), a trader should resist buying options, as false signals and sideways trading is likely.  A trader may be presented with several opportunities during the session to move in and out of option sales profitably.  Capitalizing on these trades when able can lead to successful trading periods even when the underlying market has little net movement.  Traders entering the session short options may look to hold these positions until a breakout is signaled.  Traders with long positions may want to use what movement the market offers to cover positions. 
            <div class="text-space"></div>
            <span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />           
            <div class="text-space"></div>
        NEUTRAL DIGESTIVE sessions can be the precursor to a “BREAKOUT” as the coiling action will build power. Typically, a sign that the sentiment is going to change from NEUTRAL DIGESTION to TREND is signaled by an early session trend move with no break in the positive or negative structure. A simple Moving Average is a great tool to use to identify a possible “Breakout” day with the expectation that if the market is going to scale up or down it will maintain a trade above or below the Moving Average. 
        <br/> <br/>
        
            -   SIDEWAYS PRICE EXPECTATION.<br />
            -   “BREAKOUT” MORE LIKELY WHEN R = DIR.<br />
        </div>`,
        },
        110: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT; 
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> UP REVERSAL; 
        <span class="red">SELL PUTS</span> DP REVERSAL 
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
            Option trading in a NEUTRAL POSITIVE SHIFT market state can prove difficult, because although there is a positive signal, rallies are vulnerable to failure and a shift back into a digestive sideways trade.  Flexible discipline is required. An early session breakout rally can be a signal to establish an options position that would be long the market, however, any failure or breakdown in the positive structure of this rally can be a signal to sell options, especially calls which may rapidly deteriorate in price due to a rejection at higher levels.  Expectation is for a quick deflation in volatility after a negative REVERSAL signal which returns the market to a digestive trade. Other factors to consider are short option positions brought into the trading day may be held until breakout conformation, then look to cover appropriate risk.  Look at ongoing volatility levels and skew pricing on calls for clues on potential upward pressure on call values.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The market will be a little indecisive trying to build a consensus either with the corrective signal or the previous underlying trend bias, the result being a potential for a dramatic drop in volatility and a tight digestive session. <u>This is not a technical state to press it but one where patience pays.</u>
        <br/> <br/>
        
            -   EARLY SESSION INCREASE FOLLOWED BY MID SESSION DECREASE.<br />
            -   ALTHOUGH NOT AS COMMON THERE IS THE POTENTIAL FOR LATE SESSION EMOTIONAL PRICE INCREASE.<br />
        </div>`,
        },
        120: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT;  
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> on UP REVERSAL; 
        <span class="red">SELL PUTS</span> on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Option trading in a NEUTRAL POSITIVE TRANSITION (NPT) market state can be a period to look for opportunities to take a more aggressive stance on call buying.  There is an expectation for a Bull Trend move.  Therefore, calls may be bought on market breaks to appropriate levels as well as bought on a breakout through key resistance and perhaps adding to long call positions while positive structure holds.  The “tell” to the success of this strategy is if the market continues to hold positive structure.  Other factors to consider: look to cover short calls or other upside risk near the opening of the trading session.  Steady or higher volatility levels especially on calls may signal a higher probability of an upward market move.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The market should just “go” if it is indeed going to “Breakout” into a TREND state. Successful BREAKOUT or other Trend following strategies under these conditions are a “one-shot” opportunities, using aggressive trail stops. A market that “stalls” after a positive intra-day signal is more likely to continue in a lack luster sideways trade rather than produce a run later in the session. 
        <br/> <br/>
        
            -   EXPECTATION IS FOR A ONE WAY DIRECTIOANL PRICE INCREASE.<br />
            -	ANY BREAK IN STRUCTURE WILL PRODUCE A SIGNIFICANT DECREASE IN PRICE ACTION.<br />
        </div>`,
        },      
        130: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT ;
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> on UP REVERSAL; 
        <span class="red">SELL PUTS</span> on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        In a NEUTRAL POSITIVE EXTREME [NPE] state emotions are high and caution is advised. This is a market state where patience pays. Wait for the market to provide a “tell” to its directional objective before stepping in front of an emotional surge.  The market may indicate its intentions after the early part of the trading session and only then should a trader attempt to engage in an options strategy with an expectation of higher prices or one that would benefit from a neutral market environment.  Holding short option positions should be closely monitored from the opening of trading, looking for an early signal to a start of a more prolonged directional move or a negative exhaustive REVERSAL.  Volatility levels early in the trading session can be a signal for either a breakout or digestive trade during the trading session.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Opportunity in a NEUTRAL POSIITVE EXTREME market state is typically presented early in the session. If there is none at the beginning it is more likely to be followed by a difficult lack luster trade for the balance of the trade day and one to be avoided. Good negative reversal or positive breakout signals early however do have the potential to feed on themselves throughout the trade period.
        <br/> <br/>
        
        -	EXPECTATION IS FOR A PRICE DECREASE.<br/>
        -	POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” PRICE INCREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC DECREASE IN PRICE ACTION.<br/>
        
        </div>`,
        },        
        150: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT ;
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> on UP REVERSAL; 
        <span class="red">SELL PUTS</span> on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Option trading in a NEUTRAL NEGATIVE SHIFT (NNS) can prove difficult, because while there is a negative signal, any breaks are vulnerable to a positive reversal and a shift back into a digestive sideways trade.  Discipline is required as an early session breakout decline can be a signal to establish an options trade that would be short the market, however any failure or breakdown in negative structure is a signal to sell options, especially puts which may rapidly deteriorate in price due to a positive REVERSAL off lower levels.  Other factors to consider may be: short option positions brought into the trading day may be held until breakout conformation, then look to cover appropriate risk.  Look at ongoing volatility levels and skew of pricing on puts for clues on potential upward pressure on put values.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The market will be a little indecisive trying to build a consensus either with the corrective signal or the previous underlying trend bias with the potential for a dramatic drop in volatility and a tight digestive session. <u>This is not a technical state to press it but one where patience pays.</u>
        <br/> <br/>
        -	EARLY SESSION DECREASE FOLLOWED BY MID SESSION INCREASE.<br/>
        -	ALTHOUGH NOT AS COMMON THERE IS THE POTENTIAL FOR LATE SESSION EMOTIONAL PRICE DECREASE.<br/>
        
        
        </div>`,
        }, 
        160: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT ;
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> on UP REVERSAL; 
        <span class="red">SELL PUTS</span> on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Option trading in a NEUTRAL NEGATIVE TRANSITION (NNT) can be a period to look for opportunities to take a more aggressive stance on put buying.  The expectation is for a BEAR TREND move.  Therefore, puts may be bought on market rallies against appropriate PriceMap levels as well as bought on a breakout failure with opportunity to add to long put positions if negative structure holds.  This can be a very profitable strategy.  Let the market structure dictate position management.  If negative structure is holding stick with the position until it is not.  Other factors to consider: look to cover short puts or other downside risk near the opening of the trading session especially if the market has been holding negative structure overnight.  Steady or higher volatility levels especially on puts may signal a better chance of a downward market move.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The market should just “go” if it is indeed going to “Breakout” into a TREND state. Successful BREAKOUT or other Trend following strategies under these conditions are a “one-shot” opportunities, using aggressive trail stops. A market that “stalls” after a negative intra-day signal is more likely to continue in a lack luster sideways trade rather than fail later in the session. 
        <br/> <br/>
        -	DECREASE IN PRICE.<br/>
        -	EXPECTATION IS FOR A ONE WAY DIRECTIONAL PRICE DECREASE ANY REVERSAL OR BREAK IN STRUCTURE HOWEVER WILL PRODUCE A SIGNIFICANT INCREASE IN PRICE ACTION.<br/>
        
        </div>`,
        },        
        170: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT ;
            <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> on UP REVERSAL; 
        <span class="red">SELL PUTS</span> on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        In a NEUTRAL NEGATIVE EXTREME [NNE] market state emotions are high and caution is advised.  Expectation is for a positive reversal back into a digestive trade or a transition into a negative Bear Trend state. This is a market state where patience pays.  Wait for the market to provide a “tell” to its directional intention before stepping in front of an emotional surge.  The market may indicate its intentions after the early part of the trading session and only then should a trader attempt to engage in an options strategy with an expectation of a BREAKUOT failure and lower prices or one that would benefit from a neutral market environment after a positive REVERSAL signal.  Other factors to consider: Short option positions brought into the trading session should be monitored from the opening of trading as an early signal could be the start of a more prolonged directional move.  Volatility levels early in the trading session can be a signal for either a breakout or digestive trade
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Opportunity in a NEUTRAL NEGATIVE EXTREME market state is typically presented early in the session. If there is none at the beginning it is more likely to be followed by a difficult lack luster trade for the balance of the trade day and one to be avoided. Good positive reversal or negative breakout signals early however do have the potential to feed on the momentum throughout the trade period.
        <br/> <br/>
        
        -	INCREASE IN PRICE.<br/>
        -	THERE IS THE POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” DECREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC INCREASE IN PRICE ACTION.<br/>
        
        
        </div>`,
        },        
        210: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at UP;
            <span class="red">SELL PUTS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span> <span class="red">PUTS</span>/
        <span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BULL TREND CORRECTION (UPC) market state, is to seek opportunity selling puts on emotional market breaks.  A trader may have several opportunities during the session to buy back the previously sold puts on market rallies then reestablish put sales on additional breaks to appropriate levels.  This type of trading action can result in very profitable days even though the underlying market itself may end the trading session with little net change.  Traders entering the trading session with long or short option positions may want to employ a similar strategy, using rallies or breaks to exit calls and puts.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        This is one of the best tradable market states. Good volatility is expected with opportunity on both sides of the market. In addition, this market state does set up a longer-term trade as it has the potential to provide a signal to get back into the underlying positive trend at the beginning of a new advance.
        <br/> <br/>
        -	DECREASE IN PRICE.<br/>
        -	THERE IS THE POTENTIAL FOR AN LATE SESSION INCREASE IN THE DIRECTION OF THE UNDERLYING TREND.<br/>
        
        </div>`,
        },        
        220: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at UP;
            <span class="red">SELL PUTS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span> <span class="red">PUTS</span>/
        <span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        As with all extreme states, the emotional environment in BULL TREND EXTREME (UPE) can be difficult for options trading.  One of the best options strategies in this market state is for call sales that fade early session price spikes higher especially after a negative REVERSAL signal.  The likelihood of a reversal and then a sideways trade makes call sales possible the best choice over put purchases.  Caution must be employed and one may have to exit trades sooner than in other trading environments due to the emotional nature of this market state.  Traders entering the session with short option positions should consider holding such until a signal to exit is received.  Any long option positions may want to be covered early in the trading session as the extended nature of the market state makes it more likely to trade sideways or lower than higher.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The MKT is extended to the upside pressing the extreme. The expectation is for the euphoric buying to dissipate and produce a sharp drop in price back into a sideways low volatility trade condition. The ideal opportunity is for price and volatility to make its highs early in the session and break all day.
        <br/> <br/>
        
        -	PRICE DECREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” INCREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC DECREASE IN PRICE ACTION.<br/>
        
        </div>`,
        },        
        230: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at UP;
            <span class="red">SELL PUTS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span> <span class="red">PUTS</span>/
        <span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        When trading options in a BULL TREND POSIITVE EXTREME (UPPE) market state an appropriate strategy is to sell calls on an early session price spike higher.  The elevated market volatility levels can result in the prices received for these call sales to be very lucrative.  A trader may also consider buying puts on early upward price spikes, but the chance of a digestive trade even after a rejection off higher levels may make such put buys unprofitable.  However, if the market continues to gain downward structure as the trading session progresses, put buys could be an excellent and profitable trade, so monitor for signals of lower price action using negative market structure as the “tell” to potential downside follow through.  Traders coming into the session long calls or short puts should give serious consideration to exiting the trades on any upward price movement.  Conversely, consider holding short calls or long put positions until signaled to do otherwise. 
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Use caution buying into positive structure as an exhaustive turn is imminent. The market is extended and needs to either digest some of its recent gains or “correct” before it can produce a new trend move or a sustained negative sentiment shift.
        <br/> <br/>
        -	DECREASE IN PRICE.<br/>
        -	THERE IS THE POTENTIAL FOR HIEGHTENED “EMOTIONAL” INCREASE ESPECIALLY IF THE EARLY SESSION PRICE ACTION IS COUNTER TREND.<br/>
        
        
        </div>`,
        },
        240: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at UP;
            <span class="red">SELL PUTS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span> <span class="red">PUTS</span>/
        <span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Option trading in a BULL TREND ACCELERATION EXTREME (UPAE) market state is unique as it is one of the few times a trader may look to buy straddles or strangles at the session open and look to hold these positions for most of the trading period.  While a sideways trade is always a possibility, even a flat market may have dramatic price action late in the session.  It is not recommended to enter any short option positions in this environment as the possibility of a strong directional move outweighs the rewards of any option price deterioration that may occur.  A directionally long or short option positions a trader may enter the session with, should be held but incorporate an appropriate hedge at the open of the trading period.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        If the market does start to “flat-line” mid-session, beware of a late session “railroad” trend move into the close. Up or down the key “tell” will be the market maintaining positive or negative structure. Don’t FADE or fight this move as reactions counter to this momentum are expected to offer little relief.
        <br/> <br/>
        - INCREASE, HOWEVER THIS PRICE ACTION CAN BE IN EITHER DIRECTION WITH THE EXPECTATION OF A ONE-WAY MOVE.<br/>
        - THERE IS THE POTENTIAL FOR A DRAMATIC DECREASE IF THE EARLY SESSION PRICE DOES NOT PLAY OUT OR THERE IS A REVERSAL SIGNAL.<br/>
        
        
        </div>
            `,
        }, 
        250: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL</span> <span class="blue">CALLS</span> at UP;
            <span class="red">SELL PUTS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span> <span class="red">PUTS</span>/
        <span class="red">SELL</span> <span class="blue">CALLS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        In a BULL TREND DIGESTION (UPD) market state an options trader may look to be a seller of straddles or strangles near the open of the trading session.  It may be an excellent time for such sales due to the market possibly coming off a sharp advance and option price levels being elevated in anticipation of further gains.  If the market enters a period of choppy trading option prices could be vulnerable to a rapid decline in value.  A trader may also look to be a seller of calls and puts at appropriate levels on intraday rallies or breaks as well.  Traders entering the session with short options may look to hold positions until signaled to do otherwise.  Traders coming into the session with long options may do well to exit the positions at the open after a possible strong advance from the prior trading period.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Sharp breaks into support offer the best opportunity however they are expected to be more of a laborious turn and base building verses a “V” bottom REVERSAL. Be patient and work any position, as short choppy moves can dominate this market state.
        <br/> <br/>
        -	DECREASE IN PRICE EXPECTATION.<br/>
        
        
        </div>`,
        },       
        260: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT; 
            <span class="blue">BUY CALLS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY</span><span class="red">PUTS</span> on UP REVERSAL; 
        <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Options strategies in a BULL TREND (UPT) market state should look to capitalize on opportunities with the current positive foundation.  Calls can be purchased near the opening of the trading session as long as positive structure has held overnight.  Calls may also be purchased on market pullbacks to appropriate levels.  In addition, puts can be sold but only on market pullbacks, as this will minimize risk and maximize profits on such sales.  Traders entering the session long calls or short puts should be encouraged to stay with these positions until the market signals otherwise.  Volatility levels on calls should be monitored for clues to continued optimism.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        This is a general market state type that lays an underlying positive foundation for the trade period. No “new” technical signal has been produced but as long as the market holds positive structure the “trend is your friend”. All negative reactions should be deemed “corrective” with a shorter duration and all positive opportunities should be considered as having the potential to be the start of the next leg higher of the BULL TREND campaign.
        <br/> <br/>
        -	PRICE INCREASE EXPECTATION.<br/>
        -	STEADY PRICE TREND ACTION EXPECTATION.<br/>
        -	COST OF CARRY.<br/>
        
        
        </div>`,
        },       
        270: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT; 
            <span class="blue">BUY CALLS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY</span><span class="red">PUTS</span> on UP REVERSAL; 
        <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT
        
        
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BULL TREND POSITIVE (UPP) market state is to look to buy calls both on the opening of the trading session and also on any breaks to appropriate levels.  The expectation for the market to continue to hold structure and trade at progressively higher levels can make call buying the most effective strategy.  This can lead to a very profitable trading period by holding long call positions until the market signals to no longer do so.  If the market does not produce the higher levels that may be anticipated a trader should consider a hedge stagey or exit the long calls, as expectations are not occurring and a counter signal may be developing.  Traders entering the session with option positions may also expect higher levels but be willing to adjust your portfolio if the higher price action does not materialize.
            <div class="text-space"></div>

<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        If the market is going to “go” it should just continue to build optimism. If this show of strength does not materialize don’t force the long side as “non-performance” is the “tell” that something is “wrong” and the potential for a counter signal correction is real.
        <br/> <br/>
        -	STEADY INCREASE EXPECTATION.<br/>
        -	SLIGHT ACCELERATION IN PRICE TREND ACTION EXPECTATION.<br/>
        
        
        
        </div>`,
        },       
        280: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY CALLS</span> on UP BREAKOUT; 
            <span class="blue">BUY CALLS</span> at DP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY</span><span class="red">PUTS</span> on UP REVERSAL; 
        <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BULL TREND ACCELERATION (UPA) market state is to both buy calls and sell puts on the opening of the session and on any pullbacks to appropriate support levels.  This is an aggressive options strategy, but with possible expectations for the market to continue higher it can be a very effective one. If the market does not perform to expectations an option trader may consider hedging or exiting trades that were anticipating higher underlying market levels.  A trader needs to be disciplined when entering aggressive option strategies.  The risk reward may be acceptable, but any lapse in proper position management can result in quickly accelerating losses.  A trader entering the session with an options position may situate themselves for higher levels but should be prepared for changes in structure as well.	<br />
            <div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Remember that the market has produced a major positive signal and should just “go”. If not a digestive trade or counter signal reaction should be expected. If the market breaks positive structure early in the session and starts to establish negative intra-day structure the potential for a low volatility one-way break is real. These signature states are prone to counter signal negative sessions but can be the start of a new 5-8 session advance.<br/> <br/>
        -	PRICE INCREASE WITH THE TREND.<br/>
        -	REVERSAL SIGNALS ARE THE “TELL” TO AN END TO THE PRICE ACTION INCREASE AND THE LIKELYHOOD OF A DRAMATIC DECREASE.<br/>
        </div>`,
        },       
        320: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY</span><span class="red">PUTS</span> on DP BREAKOUT; 
            <span class="blue">BUY</span><span class="red">PUTS</span> at UP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span> on DP REVERSAL; 
        <span class="blue">BUY CALLS</span> on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BEAR TREND ACCELERATION (DNA) market state is to both buy puts and sell calls on the opening of the session and on any rallies to appropriate resistance levels.  This is an aggressive options strategy, but with possible expectations for the market to continue lower it can be a very effective one.  If the market does not perform to expectations an option trader may consider hedging or exiting trades that were anticipating lower underlying market levels.  A trader needs to be disciplined when entering aggressive option strategies.  The risk reward may be acceptable, but any lapse in proper position management can result in quickly accelerating losses.  A trader entering the session with an options position may situate themselves for lower levels but should be prepared for changes in structure as well.
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Remember the market has produced a major negative signal and should just “release”. If not a digestive trade or counter signal rally should be expected. If the market violates its negative structure early in the session and starts to establish positive intra-day structure the potential for a low volatility one-way rally is real.
        
        <br/> <br/>
        -	PRICE DECREASE WITH THE TREND.<br/>
        -	REVERSAL SIGNALS ARE THE “TELL” TO AN END TO THE PRICE ACTION DECREASE AND THE LIKELYHOOD OF A DRAMATIC INCREASE.<br/>
        
        </div>`,
        },       
        330: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY</span><span class="red">PUTS</span> on DP BREAKOUT; 
            <span class="blue">BUY</span><span class="red">PUTS</span> at UP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span> on DP REVERSAL; 
        <span class="blue">BUY CALLS</span> on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        In a BEAR TREND NEGATIVE (DNN) market state an appropriate options strategy is to look to buy puts both on the opening of the trading session and also on any rallies to appropriate resistance levels.  The expectation is for the market to continue to hold structure and trade at progressively lower levels can make put buying the most effective strategy.  This could lead to a very profitable trading period by holding long put positions until the market signals to no longer do so.  If the market does not produce the lower levels that are anticipated a trader should consider a hedge stagey or exit the long puts, as expectations are not occurring and a counter signal may be developing.  Traders entering the session with option positions may also expect lower levels but be willing to adjust their portfolio if the declining price action does not materialize.
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        If the market is going to “release”, it should just “go” and continue to “feed the fear” and pessimism. If not don’t force the sell side as any stabilization or positive signal is the “tell” that sentiment is shifting and the potential for a counter signal rally is real.
        <br/> <br/>
        -	STEADY DECREASE EXPECTATION. <br/>
        -	SLIGHT ACCELERATION IN PRICE TREND ACTION EXPECTATION.<br/>
        
        
        </div>`,
        },       
        340: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="blue">BUY</span> <span class="red">PUTS</span> on DP BREAKOUT; 
            <span class="blue">BUY</span> <span class="red">PUTS</span> at UP<br />
            <div class="text-space"></div>
             <strong> HEDGE </strong> = <span class="blue">BUY CALLS</span> on DP REVERSAL; 
        <span class="blue">BUY CALLS</span> on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Appropriate options strategies in a BEAR TREND (DNT) signature market state are ones where a trader would look to capitalize on opportunities with the current negative foundation.  Puts can be purchased near the opening of the trading session as long as negative structure has held overnight.  Puts may also be purchased on market rallies to appropriate resistance levels.  In addition, calls can be sold but only on market rallies, as this will minimize risk and maximize profits on such sales.  Traders entering the session short calls or long puts are encouraged to stay with these positions until the market signals otherwise.  Volatility levels on puts should be monitored for clues to continued downward pressure.
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        This is a PRIMARY Market State that provides an underlying negative foundation for the trade period. No new technical signal has been produced but as long as the negative structure is holding the “trend is your friend”. All positive reactions should be deemed “corrective” with short trade horizons and all sell opportunities should be considered as the potential to be the start of the next leg lower, of the BEAR TREND campaign.
        <br/> <br/>
        
        -	PRICE DECREASE EXPECTATION.<br/>
        -	STEADY PRICE TREND ACTION EXPECTATION.<br/>
        -	COST OF CARRY.<br/>
        
        
        
        </div>`,
        },       
        350: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> = <span class="red">SELL PUTS</span> at DP;  
            <span class="red">SELL</span> 
            <span class="blue">CALLS</span>     at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>     
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
             <span class="blue">CALLS</span>       
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/
             <span class="red">SELL PUTS</span>     
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BEAR TREND DIGESTION (DND) market state is to look to be a seller of straddles or strangles near the open of the trading session.  The best opportunities for such sales are after a sharp decline and option price levels are elevated in anticipation of further declines.  If the market enters a period of choppy trading after an emotional “flush”, option prices could be vulnerable to a rapid decline in value.  A trader may also look to be a seller of calls and puts at appropriate levels on intraday rallies and breaks as the expectation is for a sideways trading environment.  However, one must monitor if negative structure is maintained and be prepared to act if needed.  Traders entering the session short options may look to hold positions until signaled to do otherwise. Traders coming into the session long options may do well to exit positions at the open after a possible strong decline from the prior trading period.
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
Sharp rallies into resistance offer the best opportunity, however they are expected to be more of a laborious turn and top building verses a “spike” reversal. Be patient and work any position as short choppy moves can dominate this market state.        
        -	PRICE DECREASE EXPECTATION.<br/>
        
        
        
        </div>`,
        },       
        360: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong> <span class="blue">BUY</span>     
        
        <span class="red">PUTS</span> on DP BREAKOUT;   
         <span class="blue">BUY</span>     
        
        <span class="red">PUTS</span>at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY CALLS</span> 
        
                on DP REVERSAL; 
            <span class="blue">BUY CALLS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Option trading in a BEAR TREND ACCELERATION EXTREME (DNAE) market state is unique as it is one of the few times a trader may look to buy straddles or strangles at the session open and look to hold these positions for most of the trading period.  While a sideways trade is always a possibility, even a flat market may have dramatic price action late in the session.  It is not recommended to enter any short option positions in this environment as the possibility of a strong directional move outweighs the rewards of any option price deterioration that may occur.  Any long option positions a trader may enter the session with, should be held and any short option positions are recommended to be covered at the open of the trading.
        
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        If the market is truly “bad” it will remain hard pressed and offer little or no relief to buyers. Expect the price action to maintain negative structure with the expectation of an emotional “flush”. A break in the negative momentum followed by a low volatility rally has the potential to feed on itself as sellers that “missed” the prior session “big break” sell the market in the hole and feed the rise as they continue to get “stopped” out. Typical positive reversals in this signature state will remain firm into the session close before they fall back in to the overnight session.
        <br/> <br/>
        
        -	INCREASE, HOWEVER THIS PRICE ACTION CAN BE IN EITHER DIRECTION WITH THE EXPECTATION OF A ONE-WAY MOVE.<br/>
        -	THERE IS THE POTENTIAL FOR A DRAMATIC INCREASE IF THE EARLY SESSION PRICE WEAKNESS DOES NOT PLAY OUT OR THERE IS A REVERSAL SIGNAL.<br/>
        
        
        </div>`,
        },       
        370: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="red">SELL PUTS</span> on DP;   
        
        <span class="red">SELL</span> <span class="blue">CALLS</span>     
        at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>     
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
        
        <span class="blue">CALLS</span>     
        
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BEAR TREND NEGATIVE EXTREME (DNNE) market state is to sell puts on an early session price spike lower.  The depressed market levels can result in the prices received for these put sales to be very lucrative.  A trader may also consider buying calls on early downward price spikes, but the chance of a digestive trade even after a bounce off lower levels may make such call buys unprofitable.  However, if the market continues to gain upward momentum and build positive structure as the trading session progresses, call buys could be an excellent and profitable trade, so monitor for signals of higher price action.  Traders coming into the session short calls or long puts should give serious consideration to exiting the trades on any downward price movement.  Conversely, trader’s long calls or short puts should consider holding positions until signaled to do otherwise.
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The market is extended and needs to either digest some of the recent losses or “correct” before it can produce a new trend move south or a sustained sentiment shift.
        
        <br/> <br/>
        
        -	PRICE DECREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR HIEGHTENED “EMOTIONAL” INCREASE ESPECIALLY IF THE EARLY SESSION PRICE ACTION IS COUNTER TREND.<br/>
        </div>`,
        },       
        380: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="red">SELL PUTS</span> at DP;   
        
        <span class="red">SELL</span> <span class="blue">CALLS</span>     
        at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>     
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
             <span class="blue">CALLS</span>     
        
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        As with all extreme states, the emotional environment in BEAR TREND EXTREME (DNE) can be difficult for options trading.  There is a possibility for profitable put sales that fade early session price spikes lower.  The chance of a reversal and then a sideways trade makes the put sales the best possible choice over call purchases.  Caution must be employed and one may have to exit trades sooner than in other trading environments due to the emotional nature of this market state.  Traders entering the session with short option positions should consider holding such until a signal to exit is received.  Consider selling long option positions early in the trading session.
        
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        The MKT is extended to the downside pressing the extreme. The expectation is for the panic selling to stabilize and produce a sharp bounce in price back into a sideways low volatility trade condition. The ideal opportunity is for price to make its low and volatility its high early in the session and then have price rally and volatility break all day.
        
        
        <br/> <br/>
        
        -	PRICE INCREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” DECREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC INCREASE IN PRICE ACTION.<br/>
        
        </div>`,
        },       
        390: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="red">SELL PUTS</span> at DP;   
        
        <span class="red">SELL</span> <span class="blue">CALLS</span>     
        at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>     
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
             <span class="blue">CALLS</span>     
        
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        An appropriate options strategy in a BEAR TREND CORRECTION (DNC) market state is to seek opportunity selling calls on emotional market rallies.  A trader may have several opportunities during the session to buy back the previously sold calls on market breaks then reestablish call sales on additional rallies to appropriate levels.  This type of trading action can result in very profitable days even though the underlying market itself may end the trading session with little net change.  Traders entering the trading session with long or short option positions may want to employ a similar strategy, using rallies or breaks to exit calls and puts.
        
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        This is one of the best tradable market states. Good volatility is expected with opportunity on both sides of the market. In addition, this market state does set up a longer-term trade opportunity to get back into the underlying negative trend after the positive corrective signal has exhausted itself, having the potential to be the start of a new trend move south.
        
        <br/> <br/>
        
        -	PRICE INCREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR AN LATE SESSION DECREASE IN THE DIRECTION OF THE UNDERLYING TREND.<br/>
        
        
        </div>`,
        },       
        400: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="red">SELL PUTS</span> at DP;   
        
        <span class="red">SELL</span> <span class="blue">CALLS</span>      
        at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>      
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
             <span class="blue">CALLS</span>      
        
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Although option trading in a POSITIVE EXTREME (PE) state can be difficult, it can also present unique opportunities that can result in excellent profits.  Look to sell calls early in the trading session at appropriate resistance levels on emotional upward price spikes.  Inflated prices on call sales during these extreme trading conditions can result in one of the best calls selling environments available.  A break in positive market structure can often result in a rapid deterioration of call values.  If the market holds structure and continues higher option hedges may be required.  However, a trader may look to reestablish short call positions at higher appropriate resistance levels due to the excellent risk reward offered on possible overbought conditions.  The market may offer signals to direction early in the trading session, and a failed rally can also offer good opportunities to sell calls even at lower price levels than may have traded previously.
        
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Don’t be too eager to FADE negative momentum at support levels. Although a “pause” can be expected, it is more likely to be a sloppy mess at best if not a trap.
        
        <br/> <br/>
        
        -	PRICE DECREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” INCREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC DECREASE IN PRICE ACTION.<br/>
        
        
        
        </div>`,
        },       
        500: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="red">SELL PUTS</span> at DP;   
        
        <span class="red">SELL</span> <span class="blue">CALLS</span>      
        at UP<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
             <span class="blue">BUY</span>      
             <span class="red">PUTS</span>/<span class="red">SELL</span> 
             <span class="blue">CALLS</span>     
        
                on DP BREAKOUT; 
            <span class="blue">BUY CALLS</span>/<span class="red">SELL PUTS</span>
        on UP BREAKOUT
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        Although option trading in a NEGATIVE EXTREME (NE) state can be difficult, it can also present unique opportunities that can result in excellent profits.  Look to sell puts early in the trading session at appropriate support levels on emotional downward price spikes.  Inflated prices on put sales during these extreme trading conditions can result in one of the best put selling environments available.  A breach of the negative market structure can often result in a rapid deterioration of put values.  If the market holds structure and continues lower option hedges may be required.  However, a trader may look to reestablish short put positions at lower appropriate support levels due to the excellent risk reward offered on possible oversold conditions.  The market may offer signals to direction early in the trading session, and a failed break can also offer good opportunities to sell puts even at lower price levels than may have traded previously.
        
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        Don’t be too eager to FADE positive momentum at resistance levels. Although a “pause” is expected, it is more likely to be a sloppy mess at best, if not a trap.
        
        <br/> <br/>
        
        -	PRICE INCREASE EXPECTATION.<br/>
        -	THERE IS THE POTENTIAL FOR AN EARLY SESSION “EMOTIONAL” DECREASE HOWEVER ANY “EXHAUSTIVE” REVERSAL IS EXPECTED TO PRODUCE A DRAMATIC INCREASE IN PRICE ACTION.<br/>
        
        
        
        </div>`,
        },       
        900: {
            theme: `#NEU - NEUTRAL DIGESTION`,
            overview: `<div class="area area1">
            <strong> OPTIMAL </strong>
        <span class="blue">BUY CALLS</span> on UP BREAKOUT; 
        <span class="blue">BUY</span>  
        <span class="red">PUTS</span> on DP BREAKOUT<br />
            
            
            <div class="text-space"></div>
             <strong> HEDGE </strong> = 
        <span class="red">SELL</span>  	 <span class="blue">CALLS</span>     
         on UP REVERSAL; 
            <span class="red">SELL PUTS</span>
        on DP REVERSAL
            <br /><br />
            
            <span class="section-info-text-header">OPTIONS ANALYSIS</span><br />
            
            <div class="text-space"></div>
        A NEUTRAL DIGESTION TRANSITION (NEUT) market state can be one of the most difficult option trading environments.  A trader can find opportunity for profit, but since a market breakout or a continued sideways trade are both possibilities, incorrect trades can be costly.  The best course of action may be to be patient and wait for a market breakout, then purchase options and possibly add to the position as long as the market signals to do so by building positive or negative structure.  Traders coming into the session short options may want to cover near the open, as the risk of holding short positions may not justify the possible return.
        
<div class="text-space"></div>
<span class="section-info-text-header">DIRECTIONAL PRICE EXPECTATION</span><br />            <div class="text-space"></div>
        If the market is going to “go” it should continue to hold structure. If not a REVERSAL back into a messy sideways trade should be expected.
        <br/> <br/>
        
        -	BALANCED PRICE EXPECTATION.<br/>
        -	EXPECTATION IS FOR A ONE WAY DIRECTIOANL PRICE MOVE ANY REVERSAL OR BREAK IN STRUCTURE HOWEVER WILL PRODUCE A SIGNIFICANT DECREASE IN VOLATILITY.<br/>
        
        
        
        </div>`,
        }
        
    };
    return strategy[code] || strategy["error"];
}
