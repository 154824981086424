import { Fragment, useEffect, useState } from "react";
import './DashboardInsight.css';
import { useSelector } from 'react-redux'

const DashboardInsightTilesPart = (props) => {

    const [tileData,setTileData] = useState();

    useEffect(()=>{
        setTileData(props.tileData);
    },[props.tileData])

    const dataMode = useSelector((state) => state.dataMode.value);

    return (
        <Fragment>
                <div className="content-part">
                    <div key="1" className='block-div-insight-tile' style={{backgroundColor : tileData ? tileData.bgColor : "", color : tileData ? tileData.textColor : ""}}>
                        {/* <div className='block-active-cell-tile'>

                            <label className="trend-type-name-insight-tile">{tileData && tileData.infoBlockTrend}</label>

                            <div className="block-name-div-tile">
                                <label className="block-name-insight-tile">{tileData && tileData.infoBlockMark}</label>
                                <label className="market-name-tile">{tileData && tileData.infoBlockType}</label>
                            </div>
                        </div> */}
                        <div className="trend-item_trend">{tileData && tileData.infoBlockTrend}</div>
                        <div className="trend-item_mark">{tileData && tileData.infoBlockMark}</div>
                        <div className="trend-item_type">{tileData && tileData.infoBlockType}</div>
                    </div>

                    <div className="block-div-content">
                            <div className="futures-header" dangerouslySetInnerHTML={{__html: tileData && tileData.coinSymbol}}/>
                    </div>

                    <div className="spcae-item-content"></div>
                </div>
        </Fragment>
    )
}

export default DashboardInsightTilesPart;
