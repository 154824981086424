import { Fragment, useEffect, useState } from "react";
import './DashboardInsight.css';
import Structure from "./InsightData/structure";
import struct_img from "../../assets/playbook/MSB_901.png";
import { useSelector } from "react-redux";

const DashboardInsightStructure = (props) => {
    const {structureMetrices,trendNumber,headerFormater}= props;
    const [imgUrl, setImgUrl] = useState(struct_img);
    const [structureValue,setStructureValue] = useState()
    const rValue = useSelector((state) => state.rValue.value);

    useEffect(()=>{
        setStructureValue(Structure(trendNumber))
        if(trendNumber) loadImage(trendNumber)
    }, [trendNumber])

    const loadImage = (trendNumber) => {
        import(`../../assets/playbook/MSB_${trendNumber}.png`).then(image => {
          setImgUrl(image.default);
        });
      };

    return (
        <Fragment>
                <div className="content-text-area">
                        {structureValue && <div className="position_Relative"><div dangerouslySetInnerHTML={{__html: headerFormater(structureValue.theme)}}/><div className="r-value">R={rValue}</div></div>}
                        {structureValue && <p dangerouslySetInnerHTML={{__html: props.BreakupText(structureValue.overview)}}/>}
                </div>

                <div className="image-discription-div">
                    <div className="market-matrices-div">
                        <div className="header-div">Market Metrics</div>
                        <div className="sub-header-div">REWARD</div>
                        <div className="market-metrices-value">
                            <div>APMD</div>
                            <div>{structureMetrices.AMPD}</div>
                        </div>
                        <div className="market-metrices-value">
                            <div>1/2 APMD</div>
                            <div>{structureMetrices.HalfAMPD}</div>
                        </div>
                        <div className="market-metrices-value">
                            <div>APMDT</div>
                            <div>{structureMetrices.APMDTick}</div>
                        </div>

                        <div className="sub-header-div">RISK</div>
                        <div className="market-metrices-value">
                            <div>VAR</div>
                            <div>{structureMetrices.VAR}</div>
                        </div>
                        <div className="market-metrices-value">
                            <div>AD</div>
                            <div>{structureMetrices.AD}</div>
                        </div>
                        <div className="market-metrices-value">
                            <div>MSD</div>
                            <div>{structureMetrices.MSD}</div>
                        </div>
                    </div>
                    <div className="market-structure-div">
                        <div className="header-div">Market Structure Bias</div>
                        <div><img src={imgUrl}/></div>
                    </div>
                </div>
        </Fragment>
    )
}

export default DashboardInsightStructure;
