import { Fragment, useEffect, useState } from "react";
import DashboardFilter from "./DashboardFilter";
import dashboardFilterState from "./DashboardFilterState";
import { useDispatch ,useSelector} from 'react-redux'
import { setCurrentBlockData } from "../../store/blockDataSlice";
import { getSymbol } from "../dashboard/DashboardColorBlockDetails";
import { getColorBlockRValueRegime } from "../dashboard/assets/ColorBlockValues";

const DashboardFilterContainer = (props) => {

    const {dataList,datesFilter,getDatabyDate,getDatabyMarketName} = props;
    const [filterState, setFilterState] = useState(dashboardFilterState);
    const [selectedDropdownIndex,setSelectedDropdownIndex] = useState();

    const dataMode = useSelector((state)=> state.dataMode.value);
    const dispatch = useDispatch();

    useEffect(() => {
        if(dataList){
            getDateFilterList();
            setExcahngeDropDown();
        }
    }, [dataList])

    useEffect(()=>{
        if(filterState.cryptoBlockList){
            getNameDropDownOptions();
        }
    },[filterState.cryptoBlockList])

    const getDateFilterList = () => {

        let dateFilterList = datesFilter;

        let dateFilter = [];
        let dateType = "D";
        switch (dateType) {
            case "D":
                dateFilter = dateFilterList.d;
                break;
            case "W":
                dateFilter = dateFilterList.w;
                break;
            case "M":
                dateFilter = dateFilterList.m;
                break;
            default:
                return null;
        }


        dateFilter.sort(function (a, b) {
            return new Date(b) - new Date(a);
        });

        let finalDateFilterList = dateFilter.map((date, index) => {
            return ({ label: date, value: date })
        })

        getIndicatorFilterList(finalDateFilterList[0].label, dateType);
        getCryptoBlocksList(finalDateFilterList[0].label, dateType);

        setFilterState((prevState) => { return { ...prevState, dateFilterList: finalDateFilterList } });
    }

    /**
     * @param {Date} chosenDate  it fetches indicator filter list
     */
    const getIndicatorFilterList = () => {

        let indicatorListJson = dataList;
        let exchangeFilter = [];
        let rValueFilter = [];

        indicatorListJson.forEach((item) => {
            let filter = "";
            filter = item.MARKET;
            filter = filter.replace(/GDAX/g, "COINBASE");

            if (!exchangeFilter.includes(filter))
                exchangeFilter.push(filter);

            if (!rValueFilter.includes(getColorBlockRValueRegime(item)))
                rValueFilter.push(getColorBlockRValueRegime(item));

        });

        let indicatorFilterList = [{ label: "ALL", value: "ALL" },]

        let newFilterItem = {
            label: '', value: ""
        }


        for (let i = 0; i < exchangeFilter.length; i++) {

            newFilterItem.label = exchangeFilter[i];
            newFilterItem.value = exchangeFilter[i];

            indicatorFilterList.push(newFilterItem);
        }

        rValueFilter.forEach((filterArrItem) => {

            newFilterItem.label = filterArrItem;
            newFilterItem.value = filterArrItem;

            indicatorFilterList.push(newFilterItem);
        });
        setFilterState((prevState) => { return { ...prevState, indicatorFilterList: indicatorFilterList } });

        getCryptoBlocksList(indicatorFilterList);
    }

    const getCryptoBlocksList = () => {
        let indicatorListJson = dataList;

        setFilterState((prevState) => { return { ...prevState, cryptoBlockList: indicatorListJson } });
        dispatch(setCurrentBlockData(indicatorListJson[0]));
    }

    /**
     * @param {Block Object} block
     * @param {Block Name} blockName  handles the click event of blocks
     */
    const onClickBlockHandler = (block, blockName) => {
        let blockDetail = filterState.selectedBlock;
        blockDetail.block = block;
        blockDetail.blockName = blockName;
        dispatch(setCurrentBlockData(block));
        setFilterState((prevState) => { return { ...prevState, selectedBlock: blockDetail } });
    }

    const getNameDropDownOptions = () =>{
        let filteredName = [];
        for (let item = 0; item < filterState.cryptoBlockList.length; item++) {

            let names = filterState.cryptoBlockList[item].name.split(".");
            if (dataMode === "binance") {
                var obj={
                    label : `${names[1]} - ${filterState.cryptoBlockList[item].MARKET}`,
                    value : `${filterState.cryptoBlockList[item].name},${filterState.cryptoBlockList[item].description}`
                }
                filteredName.push(obj)
                setSelectedDropdownIndex(0);
            } else if (dataMode === "futures" || dataMode === "blockfills"){
                var obj={
                    label : `${names[0]} - ${dataList[item]?.description}`,
                    value : `${filterState.cryptoBlockList[item].name},${filterState.cryptoBlockList[item].description}`
                }
                filteredName.push(obj)
                setSelectedDropdownIndex(1);

            } else {
                var obj={
                    label : `${names[0]} - ${filterState.cryptoBlockList[item].MARKET}`,
                    value : `${filterState.cryptoBlockList[item].name},${filterState.cryptoBlockList[item].description}`
                }
                filteredName.push(obj)
                setSelectedDropdownIndex(1);
            }
        }

        setFilterState((prevState) => { return { ...prevState, indicatorFilterList: filteredName } });
    };

    const onClickMarketDropDown = (value) =>{
        let values = value.split(",");
        var currentBlockData = dataList.filter((item) => item.name === values[0] && item.description === values[1] && item.MARKET != "N/A");

        const abbreviatedName = currentBlockData[0]?.name?.split(".");
        let symbolObject = getSymbol(abbreviatedName[1]);
        let blockName = abbreviatedName?.length === 2 ? abbreviatedName[0] : symbolObject?.coinSymbol;

        let blockDetail = filterState.selectedBlock;
        blockDetail.block = currentBlockData[0];
        blockDetail.blockName = blockName;

        setFilterState((prevState) => { return { ...prevState, selectedBlock: blockDetail } });
        dispatch(setCurrentBlockData(currentBlockData[0]));
        //setSelectedDropdownIndex(filterState.binanceFilterList.findIndex(i=> i.value === value));
    };

    const setExcahngeDropDown = () =>{
        const uniqueMarketExcahnges = [];
        const uniqueRegime = [];
        const uniqueRegimeOrder = [
            ">UT1",
            "=UT1",
            "=CRX+",
            "=UP",
            "=DIR",
            "=DP",
            "=CRX-",
            "=DT1",
            "<DT1"
        ];

        dataList.map(item => item.MARKET)
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach((item)=>{
                if (item === 'BLOCKFILLS' || item === "N/A") return
                uniqueMarketExcahnges.push({
                    label : item,
                    value : item
                })
        });

        dataList.map(item => getColorBlockRValueRegime(item))
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach((item)=>{
                uniqueRegime.push({
                    label : item,
                    value : item
                })
            });
        uniqueRegime.sort((a, b) => uniqueRegimeOrder.indexOf(a.value) - uniqueRegimeOrder.indexOf(b.value));

        let exchangeAndRegimeArray = [];
        exchangeAndRegimeArray.push({ label:"All",value:"All"});
        exchangeAndRegimeArray.push({ label:"Exchanges",value:"Exchanges" });
        exchangeAndRegimeArray.push(...uniqueMarketExcahnges);
        exchangeAndRegimeArray.push({ label:"R",value:"R" });
        exchangeAndRegimeArray.push(...uniqueRegime);

        setFilterState((prevState) => { return { ...prevState, marketExchangeList: exchangeAndRegimeArray } });
    };

    const onClickMarketExchange = (value) =>{
        if (value === "All") {
            getCryptoBlocksList();
        } else if (filterState.marketExchangeList.findIndex(x => x.value === value) > filterState.marketExchangeList.findIndex(x => x.label ==="R")){
            let filteredData = [];
            for (var i = 0; i < dataList.length; i++) {
                if (value.includes(getColorBlockRValueRegime(dataList[i]))) {
                    filteredData.push(dataList[i]);
                }
            }
            setFilterState((prevState) => { return { ...prevState, cryptoBlockList: filteredData } });
        } else {
            var filteredArray = dataList.filter((item) => item.MARKET === value);
            setFilterState((prevState) => { return { ...prevState, cryptoBlockList: filteredArray } });
        }
    };

    return (
        <Fragment>
            <DashboardFilter
                filterState={filterState}
                onClickBlockHandler={onClickBlockHandler}
                dateFilterList={filterState.dateFilterList}
                getDatabyDate={getDatabyDate}
                getDatabyMarketName={getDatabyMarketName}
                onClickMarketDropDown={onClickMarketDropDown}
                onClickMarketExchange={onClickMarketExchange}
                selectedDropdownIndex={selectedDropdownIndex}
            />
        </Fragment>
    )
}

export default DashboardFilterContainer;
