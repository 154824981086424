export default function getMsbTextColor(posState) {

  const textColor = {
    NEU: "rgba(255,255,255)",
    NPS: "#00FFFF",
    NPT: "rgba(0,255,0)",
    NPE: "rgba(255,255,0)",
    NNS: "#FF66FF",
    NNT: "#E36C0A",
    NNE: "rgba(255,255,0)",
    UPC: "rgba(0,0,255)",
    UPE: "rgba(255,255,0)",
    UPPE: "rgba(255,255,0)",
    UPAE: "rgba(255,255,0)",
    UPD: "#984806",
    UPT: "rgba(255,255,255)",
    UPP: "rgba(0,255,0)",
    UPA: "rgba(0,0,255)",
    DNA: "rgba(255,0,0)",
    DNN: "rgba(255,192,0)",
    DNT: "rgba(255,255,255)",
    DND: "rgba(255,153,51)",
    DNAE: "rgba(255,255,0)",
    DNNE: "rgba(255,255,0)",
    DNE: "rgba(255,255,0)",
    DNC: "rgba(255,0,0)",
    PE: "rgba(0,0,255)",
    NE: "rgba(255,0,0)",
    NEUT: "rgba(255,255,255)",
  };

  return textColor[posState] || "white";
}
