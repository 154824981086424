import { Fragment } from "react";
import { useSelector } from "react-redux";
import DashboardDataContainer from "../dashboard-data/DashboardDataContainer";
import DashboardFilterContainer from "../dashboard-filter/DashboardFilterContainer";
import DashboardInsightContainer from "../dashboard-insight/DashboardInsightContainer";
import './Dashboard.css';

const Dashboard = (props) => {
    const {
        indicatorFilterListJson,
        datesFilter,
        getDatabyDate,
        latestDate,
        getDatabyMarketName,
        codeNumber,
        trendNumber,
        tileData,
        marketAttributesOneImages,
        marketAttributesTwoImages
    } = props;


    const planType = useSelector((state) => state.planType.value);

    return (
        <Fragment>
            <div className="dashboard-main-container">
                <div className="dashboard-main-container-content">
                    <DashboardFilterContainer
                        dataList={indicatorFilterListJson}
                        datesFilter={datesFilter}
                        getDatabyDate={getDatabyDate}
                        getDatabyMarketName={getDatabyMarketName}
                    />
                    <DashboardDataContainer />
                    <DashboardInsightContainer
                        codeNumber={codeNumber}
                        trendNumber={trendNumber}
                        tileData={tileData}
                        marketAttributesOneImages={marketAttributesOneImages}
                        marketAttributesTwoImages={marketAttributesTwoImages}
                        latestDate={latestDate}
                        planType = {planType}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard;
