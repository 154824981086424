import { PathConfig } from "../shared/app.config"

const apiGetList = "/live/getlistdata"
const apiGetLink = "/live/getlink"
const { API_BASE_URL } = PathConfig

export async function getDateFilterList(tableName) {
    const url = API_BASE_URL + apiGetList +"?getfilters=" + tableName
    return await fetch(url)
        .then((r) => r.text())
        .then((r) => JSON.parse(r.replace(/GDAX/g, "COINBASE")))
        .then((r) => r)
}

export async function getIndicatorFilterList(tableName, date, datetype) {
    const url = API_BASE_URL + apiGetList +`?getdata=${tableName}&date=${date}&datetype=${datetype}`
    return await fetch(url)
        .then((r) => r.text())
        .then((r) => JSON.parse(r.replace(/GDAX/g, "COINBASE")))
        .then((r) => {
            // Filter items with empty MARKET
            const filteredResponse = {...r, data: r.data.filter(item => !!item.MARKET)}
            return filteredResponse
        })
}

export async function FetchExportLinks(date,planType) {
    const url = API_BASE_URL + apiGetLink + "?date=" + date+"&plan=" + planType
    return await fetch(url)
        .then((r) => r.text())
        .then((r) => JSON.parse(r))
        .then((r) => r)
}
