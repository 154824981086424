import { createSlice } from '@reduxjs/toolkit'



export const planTypeSlice = createSlice({
  name: 'planType',
  initialState: {
    blockData: null
  },
  reducers: {
    setPlanType: (state,action) => {
      state.value = action.payload
    }
  },
})

export const {setPlanType} = planTypeSlice.actions

export default planTypeSlice.reducer