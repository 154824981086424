import React, { Fragment, useEffect, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import AppRoutes from "./routes/AppRoutes"
import { Provider } from 'react-redux'
import { store } from "./store/store"
import { authService } from "./services/AuthService"
import Header from "./shared-components/header/Header"
import './App.css'

function App() {
  const [user, setUser] = useState()

  useEffect(() => {
    const timerId = setInterval(async () => {
      if(JSON.parse(window.localStorage.getItem('userLoading'))) return
      const user = await authService.getUser()
      if (user) {
        setUser(user)
      } else {
        authService.login()
      }
      clearInterval(timerId)
    }, 1000)
    return () => clearInterval(timerId)
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {
            user
              ? <Header />
              : <div className="page-jss-loading"/>
          }
          <AppRoutes isLoggedIn={!!user}/>
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App
