import { Fragment, useEffect, useState } from "react";
import { getDateFilterList, getIndicatorFilterList } from "../../services/DashboardServices";
import Dashboard from "./Dashboard";
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedDate } from "../../store/selectedDateSlice";
import {  setDataMode } from "../../store/dataModeSlice";
import { setCurrentBlockData } from "../../store/blockDataSlice";
import {  getColorBlockRValue, getSymbol,getColorBlockTrendTypeObj } from "./DashboardColorBlockDetails";
import getMarketAttributesName, { FuturesDescriptionDate, getAttributesTypeByName } from "./DashboardMarketAttributes";
import getMsbTextColor from "./assets/MsbTextColor";
import { useLocation } from "react-router-dom";
import { checkDuePayment } from "../../services/stripeService";
import { PathConfig } from "../../shared/app.config";
import { setPlanType } from "../../store/planTypeSlice";
import Cookies from 'universal-cookie';
import { authService } from "../../services/AuthService";

const DashboardContainer = () => {
    const dataMode = useSelector((state) => state.dataMode.value)
    const blockData = useSelector((state) => state.blockData.value)
    const selectedDate = useSelector((state) => state.selectedDate.value)
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [indicatorFilterListJson, setIndicatorFilterListJson] = useState();
    const [codeNumber, setCodeNumber] = useState();
    const [trendNumber, setTrendNumber] = useState();
    const [marketAttributesOneImages, setMarketAttributesOneImages] = useState();
    const [marketAttributesTwoImages, setMarketAttributesTwoImages] = useState();
    const [tileData, setTileData] = useState({
        infoBlockTrend: "",
        infoBlockMark: "",
        infoBlockType: "",
        market: "",
        coinSymbol: "",
        currency: "",
        bgColor: "",
        textColor:""
    });
    const [listData, setListData] = useState();
    const [datesFilter, setDateFilter] = useState();
    const [latestDate, setLatestDate] = useState();
    const [isLoader,setIsLoader] = useState(true);

    const location = useLocation();
    useEffect(() =>{
        const search = location.search;
        const logoutRequested = new URLSearchParams(search).get('clearSession');

        if(logoutRequested) {
            authService.logout()
        }

        checkDuePayment().then((res)=>{
            if (res.logout){
                setIsLoader(true);
                authService.logout()
            } else if (res) {
                if(res.planType === "" || res.planType == null){
                    window.location.replace(`${PathConfig.AUTH_SERVICE_BASE_URL}/AccountDetail`)
                }
                dispatch(setPlanType(res.planType));
                if(res.makePayment)
                    window.location.replace(`${PathConfig.AUTH_SERVICE_BASE_URL}/AccountDetail`)
                else if(res.duePayment)
                    window.location.replace(`${PathConfig.AUTH_SERVICE_BASE_URL}/AccountDetail`)
            } else{
                window.location.replace(`${PathConfig.AUTH_SERVICE_BASE_URL}/AccountDetail`)
            }
        })
    },[]);

    useEffect(() => {
        let latestdate = null;
        dispatch(setDataMode("binance"));
        setIsLoader(true);



        getDateFilterList("binance").then((result) => {
            if (result) {

                result.filters.d.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });
                result.filters.w.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });
                result.filters.m.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });

                latestdate = result.filters.d[0];
                dispatch(setSelectedDate(latestDate));
                setLatestDate(latestdate);
                setDateFilter(result.filters);


                getIndicatorFilterList("binance", latestdate, "D").then((result) => {
                    if (result)
                        setListData(result.data?.filter(item => !!item.MARKET));
                        setIsLoader(false);
                });
            }
        })
    }, []);

    useEffect(() => {
        if (listData && listData.length>0) {
            setIndicatorFilterListJson(listData)
            dispatch(setCurrentBlockData(listData[0]))
        }
    }, [listData]);

    useEffect(() => {
        if (blockData && listData && listData.length>0) {
            getTrendNumberFromBlock(listData);
            getTileData();
            stateGraphImages(blockData);
            setCodeNumber(getColorBlockTrendTypeObj(blockData).code)
        }
    }, [blockData]);

    const getDatabyDate = (date) => {
        setIsLoader(true);

        getIndicatorFilterList(dataMode, date, "D").then((result) => {
            if (result){
                setListData(result.data);
                setIsLoader(false);
            }
        });
        dispatch(setSelectedDate(date));
    };

    const getDatabyMarketName = (value) => {

        setIsLoader(true);
        dispatch(setDataMode(value));
        let myDate = null;

        getDateFilterList(value).then((result) => {
            if (result) {
                // setListData(result.data);

                result.filters.d.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });
                result.filters.w.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });
                result.filters.m.sort(function (a, b) {
                    return new Date(b) - new Date(a);
                });

                myDate = result.filters.d[0];

                dispatch(setSelectedDate(myDate));
                setLatestDate(myDate);
                setDateFilter(result.filters);
                getIndicatorFilterList(value, myDate, "D").then((result) => {
                    if (result){
                        setListData(result.data);
                        setIsLoader(false);
                    }
                });
            }
        })


    }

    const getTileData = () => {
        let trendTypeData = getColorBlockTrendTypeObj(blockData);
        let blockTrend = trendTypeData.typeDescription;
        let blockMark = blockData.posState;
        let blockType = trendTypeData.mcsDescription;
        let bgColor = trendTypeData.bgColor;
        let textColor = getMsbTextColor(blockData.posState);

        const contactDateText = blockData.symbol
            ? ` - ${blockData.symbol}`
            : "";

        let market = "";
        let coinSymbol = "";
        let currency = "";

        if (dataMode == "futures" || dataMode == "mktgrid1") {
            const itemName = blockData.name.split(".")[0];
            if (itemName.indexOf(blockData.description) > -1 && contactDateText.indexOf(itemName) > -1) {
                coinSymbol =  `<span class="symbolInfoTitleSmallFutures"> ${blockData.description}</span>`;
            } else if (blockData.MARKET == "CASH INDEX" || blockData.MARKET == "FOREX") {
                coinSymbol =  `<span class="symbolInfoTitleSmallFutures">${itemName}</span>
                <div class="symbolInfoTitleSymbolFutures">
               ${blockData.description}</div>
                `;
            } else {
                let futuresDate = FuturesDescriptionDate(contactDateText);
                const isETF = blockData?.platform === 'ETF'
                coinSymbol =  `<span class="symbolInfoTitleSmallFutures">${itemName}</span>
                <span class="symbolInfoTitleSmallFutures">
                    ${isETF ? '' : futuresDate}
                </span>
                <div class="symbolInfoTitleSymbolFutures">
                    ${blockData.description}
                </div>`
            }
        }
        else if (dataMode == "binance" ) {
            let symbolObject = getSymbol(blockData.name.split(".")[1]);
            // market = blockData.MARKET;
            // // coinSymbol = symbolObject.coinSymbol;
            // currency = symbolObject.currency;

            coinSymbol = `<span class="symbolInfoTitleSymbol">
            ${symbolObject.coinSymbol}</span> <span class="symbolInfoTitleSymbol">
            ${symbolObject.currency}</span>
            <div class="symbolInfoTitleSmall"> ${blockData.MARKET} -
            ${blockData.description}
            </div>`
        }
        else {
            if(blockData.MARKET == "CASH INDEX" || blockData.MARKET == "FOREX") {
                //contactDateText
                const itemName = blockData.name.split(".")[0];

                coinSymbol =  `<span class="symbolInfoTitleSymbol">${itemName}</span>
                <div class="symbolInfoTitleSmall">
               ${blockData.description}</div>
                `;
            }
            else{
                let symbolObject = getSymbol(blockData.name.split(".")[0]);
                // market = blockData.MARKET;
                // coinSymbol = symbolObject.coinSymbol;
                // currency = symbolObject.currency;
                coinSymbol = `
                <span class="symbolInfoTitleSymbol">${symbolObject.coinSymbol}</span>
                <div class="symbolInfoTitleSmall">${blockData.MARKET} - ${blockData.description} </div>`
            }
        }

        setTileData((prevState) => {
            return {
                ...prevState, infoBlockTrend: blockTrend,
                infoBlockMark: blockMark,
                infoBlockType: blockType,
                bgColor: bgColor,
                market: market,
                coinSymbol: coinSymbol,
                currency: currency,
                textColor: textColor
            }
        });
    };



    const getTrendNumberFromBlock = (dataObj) => {
        let arr = dataObj;
        const curentObjectDateArr = arr.filter(
            (item) => item.name === blockData.name
        );

        const currentObject = curentObjectDateArr.filter(
            (item) => item.date === blockData.date
        );

        if (blockData.currentDate)
            currentObject[0].currentDate = blockData.currentDate;

        const trendtypeData = getColorBlockTrendTypeObj(currentObject[0]);
        const thisRValueNumber = +getColorBlockRValue(currentObject[0])
            .split("")
            .filter((item) => +item);
        const trendNumber =
            trendtypeData.code !== "error"
                ? trendtypeData.code + thisRValueNumber
                : "error";
        setTrendNumber(trendNumber);
    }

    const stateGraphImages = (data) => {
        let marketAttributesGraph1 = getMarketAttributesGraph(getColorBlockTrendTypeObj(data), 1);
        let marketAttributesGraph2 = getMarketAttributesGraph(getColorBlockTrendTypeObj(data), 2);

        setMarketAttributesOneImages(marketAttributesGraph1);
        setMarketAttributesTwoImages(marketAttributesGraph2);
    }

    const getMarketAttributesGraph = (data, column) => {
        const imagesBlock = [];
        let count = 0;

        Object.values(getMarketAttributesName(data.code)).forEach((marketAttributesItem) => {

            if ((column === 1 && count < 2) || (column === 2 && count >= 2)) {

                let imgSrc = "";
                if (marketAttributesItem) {
                    imgSrc = `${marketAttributesItem}.png`;
                }

                let imgType = getAttributesTypeByName(marketAttributesItem).type;
                let imgDescription = getAttributesTypeByName(marketAttributesItem).description;

                let imageObject = {
                    imgType: imgType,
                    imgName: imgSrc,
                    imgDescription: imgDescription,
                }

                imagesBlock.push(imageObject);
            }
            count++;
        });

        return imagesBlock;
    }

    return (
        <Fragment>
            {isLoader && <div className="page-jss-loading"><h1> Loading Data.... </h1></div> }
            {
                !isLoader && listData && datesFilter && <Dashboard
                        listData={listData}
                        datesFilter={datesFilter}
                        getDatabyDate={getDatabyDate}
                        getDatabyMarketName={getDatabyMarketName}
                        indicatorFilterListJson={indicatorFilterListJson}
                        codeNumber={codeNumber}
                        trendNumber={trendNumber}
                        tileData={tileData}
                        marketAttributesOneImages={marketAttributesOneImages}
                        marketAttributesTwoImages={marketAttributesTwoImages}
                        latestDate={latestDate}
                    />
            }
        </Fragment>
    )
}

export default DashboardContainer;
