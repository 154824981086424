import { useEffect } from "react";
import { Fragment, useState } from "react";
import './DashboardInsight.css';
import Strategy from "./InsightData/options";

const DashboardInsightOptions = (props) => {
    const [strategyValue,setStrategyValue] = useState();

    useEffect(()=>{
        setStrategyValue(Strategy(props.codeNumber))
    },[props.codeNumber])

    return (
        <Fragment>
                <div id="tab4" className="item">
                        <div className="area area1">
                            <p>
                                <div className="area area1 options-height">
                                    {strategyValue && <p dangerouslySetInnerHTML={{__html: props.BreakupText(strategyValue.overview)}}/>}
                                </div>
                            </p>
                        </div>
                    </div>
        </Fragment>
    )
}

export default DashboardInsightOptions;
