import { Fragment, useEffect, useState } from "react";
import { getColorBlockRRisk } from "../dashboard/DashboardColorBlockDetails";
import DashboardData from "./DashboardData";
import {useDispatch, useSelector} from "react-redux";
import { countDecimals, FuturesDescriptionDate } from "../dashboard/DashboardMarketAttributes";
import { SetRValue } from "../../store/rValueSlice";

const DashboardDataContainer = (props) => {

    const blockData = useSelector((state)=> state.blockData.value);
    const dataMode = useSelector((state) => state.dataMode.value);

    const [risk,setRisk] = useState();
    const [rText , setRText] = useState();
    const [rHead , setRHead] = useState();

    const dispatch = useDispatch();

    useEffect(()=>{
        if(blockData)
            getRiskData();
    },[blockData])

    const getRiskData = () => {
        let actualRisk = [];
        let risk = getColorBlockRRisk(blockData);
        if (risk) {
            let risks = risk.split(" ");
            if (risks.length === 6) {
                risks.forEach((item, index) => {
                    if (index < 5) {
                        if (item.includes("fr"))
                            actualRisk.push(item.replace("fr", ""))
                        else if (item.includes("px"))
                            actualRisk.push(item.replace("px", ""))
                    }
                })
            }
            setRisk(actualRisk);
        }
    }

    const renderPriceMapBlock = (text) => {
      const isFeaturesOrMktMode = dataMode === "futures" || dataMode === 'mktgrid1'
      let decCount = countDecimals(text);
      let ticksize = blockData.ticksize;
      let symbolFullName = blockData.name.split(".").slice(0, -1).join(" ");

      var actualvalue = text;
      if (isFeaturesOrMktMode) {
        if (
          text.includes(".") &&
          ticksize != undefined &&
          ticksize.includes("/") &&
          ticksize.split("/").length == 2
        ) {
          var multiplicationfactor = ticksize.split("/")[1];
          var precision = 0.1;

          if (multiplicationfactor == 32) {
            var value = Math.floor((Number(text) % 1) * multiplicationfactor);

            text = Math.floor(Number(text)).toString() + "-";
            if (value.toString().length == 1) {
              text += "0";
            } else if (value.toString().length == 0) {
              text += "00";
            }
            text = text + value.toString();
          } else {
            if (multiplicationfactor > 9) {
              precision = 0.01;
            }
            var value = Math.floor(Number(text) / precision) * precision;
            value = Math.round(value * 100000000) / 100000000;
            if (multiplicationfactor == 4) {
              var rem = Math.floor((Number(text) % 1) * 4) * 2;
              text = Math.floor(value).toString() + "-" + rem.toString();
            } else if (
              !value.toString().includes(".") &&
              multiplicationfactor < 10
            ) {
              if (multiplicationfactor == 4) {
                var rem = Math.floor((Number(text) % 1) * 4) * 2;
                text = value.toString() + "-" + rem.toString();
              } else {
                text = value.toString() + "-" + (value % 1).toString();
              }
            } else if (
              !value.toString().includes(".") &&
              multiplicationfactor > 9
            ) {
              text = value.toString() + "-00";
            } else {
              text = value.toString().replace(".", "-");
              if (
                value.toString().split(".")[1].length == 1 &&
                multiplicationfactor > 9
              ) {
                text = text + "0";
              }
            }
          }
        } else if (
          text.includes(".") &&
          ticksize != undefined &&
          ticksize.includes("/") &&
          ticksize.split("/").length == 3
        ) {
          var multiplicationfactor = ticksize
            .split("/")[1]
            .split(" ")[0];
          var multiplicationfactor2 = ticksize.split("/")[2];

          if (multiplicationfactor == 32) {
            var value = Math.floor((Number(text) % 1) * multiplicationfactor);
            var rem = ((Number(text) % 1) * multiplicationfactor) % 1;
            text = Math.floor(Number(text)).toString() + "-";
            if (value.toString().length == 1) {
              text += "0";
            } else if (value.toString().length == 0) {
              text += "00";
            }
            var precision = 0.1;
            text = text + value.toString();
            if (multiplicationfactor > 9) {
              precision = 0.01;
            }
            if (multiplicationfactor == 2) precision = 0.5;

            var remValue = Math.round(rem / precision) * precision;
            remValue = Math.round(remValue * 100000000) / 100000000;
            if (multiplicationfactor2 == 4) {
              var res = Math.floor(rem * 10);
              if (res == 4) {
                res = 5;
              } else if (res == 6) {
                res = 7;
              }
              text = text + res.toString();
            } else {
              text = text + Math.floor(remValue * 10).toString();
            }
          } else {
            var precision = 0.005;

            var value = Math.floor(Number(text) / precision) * precision;
            value = Math.round(value * 100000000) / 100000000;

            if (value % 1 == 0) text = value.toString() + "-000";
            else {
              text = value.toString().replace(".", "-");
              if (value.toString().split(".")[1].length == 2) {
                text = text + "0";
              }
            }
          }
        } else if (
          ticksize != undefined &&
          !ticksize.includes("/") &&
          !Number(ticksize).toString().includes('.') && !Number(ticksize).toString().includes('e')
        ) {
          text = Math.round(Number(text)).toString();
        } else if (
          text.includes(".") &&
          ticksize != undefined &&
          !ticksize.includes("/") &&
          (Number(ticksize).toString().includes('.') || Number(ticksize).toString().includes('e'))
        ) {
          var origticksize = ticksize
          ticksize = Number(ticksize).toString();
          var precision = Number(ticksize);
          var value = Math.round(Number(text) / precision) * precision;
          value = Math.round(value * 100000000) / 100000000;
          var decm_value = value % 1;
          decm_value = (
            Math.round(decm_value * 100000000) / 100000000
          ).toString();
          if (!decm_value.includes(".")) {
            decm_value += ".0";
          }
          if(ticksize.includes('.'))
          while((decm_value.split('.')[1]).length < (ticksize.split('.')[1]).length){
            decm_value = decm_value + '0'
          }
        else{
          while((decm_value.split('.')[1]).length < (origticksize.split('.')[1]).length){
            decm_value = decm_value + '0'
          }
          }
          text = Math.floor(value).toString() + "." + decm_value.split(".")[1];
        }
      }

      text = text.replace("-", "'");

      if (
        (actualvalue.includes(".") && +actualvalue == +blockData.r) ||
        actualvalue == blockData.r
      ) {
        let itemName = blockData.name.split(".")[0];
        let contactDateText = null;

        contactDateText = blockData.symbol ? ` - ${blockData.symbol}` : "";

        if (
          isFeaturesOrMktMode &&
          itemName.indexOf(blockData.description) == -1
        ) {
          let futuresDate = FuturesDescriptionDate(contactDateText);
          //if cash index remove futuresDate
          if (
            blockData.description.toLowerCase().includes("cash index") ||
            blockData.symbol.toLowerCase().includes("usdx") ||
            // remove futuresDate for ETF platform
            blockData?.platform === 'ETF'
          ) {
            setRText(text);
            dispatch(SetRValue(text));
            setRHead(symbolFullName);
          } else {
            setRText(text);
            dispatch(SetRValue(text));
            setRHead(`${symbolFullName} ${futuresDate}`);
          }
        } else {
          setRText(parseFloat(text).toFixed(decCount));
          dispatch(SetRValue(parseFloat(text).toFixed(decCount)));
          setRHead(symbolFullName);
        }
      }
      if (isFeaturesOrMktMode) {
        return text;
      } else {
        return parseFloat(text).toFixed(decCount);
      }
    };

    return (
        <Fragment>
            <DashboardData
              risk={risk}
              renderPriceMapBlock={renderPriceMapBlock}
              rText={rText}
              rHead={rHead}
            />
        </Fragment>
    )
}

export default DashboardDataContainer;
