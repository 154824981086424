import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './DashboardInsight.css';

const DashboardInsightDownload = (props) => {
  const {getDownloadTabLinks} = props;

  
  let selectedDate = useSelector((state) => state.selectedDate.value);

  useEffect(()=>{
    getDownloadTabLinks(selectedDate);
  },[selectedDate])
  
    return (
        <Fragment>
          <div className="main-header-div">
            <h2 className="download-header">DAILY DOWNLOADS</h2>
            <div className="all-selector-div">
            </div>

            <div className="downloadSection"></div>

            <h2 className="download-header-ninja"> INTEGRATIONS</h2>

            <div class="download-group-ninja"><div class="download-group-name">NinjaTrader </div>

                <div class="download-item download-item-1">
                  <a allow-downloads="" href="https://darmacapital.trade/wp-content/uploads/2023/08/NT_Integration.zip">Zip File</a></div>
                <div class="download-item download-item-2">
                <a href="https://www.youtube.com/watch?v=KCr0C6PIfGY" >Tutorial</a></div>

            </div>
            <div class="download-group-ninja1"><div class="download-group-name">Sierra Chart</div>

              
                <div class="download-item download-item-1">
                <a href="https://youtube.com/watch?v=gt5TyYRsUng&feature=share">Tutorial</a></div>

            </div>
            <div class="download-group-ninja2"><div class="download-group-name">JS Market Symbols</div>

              <div class="download-item download-item-1">
                <a allow-downloads="" href="https://darmacapital.trade/wp-content/uploads/2023/07/JS-MARKET-SYMBOLS.pdf">PDF File</a></div>


              </div>
          </div>
        </Fragment>
    )
}

export default DashboardInsightDownload;