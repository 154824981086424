import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authService } from '../../services/AuthService'

const SignInCallback = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.localStorage.setItem('userLoading', true)
    authService.userManager.signinRedirectCallback()
      .then(user => {
        navigate('/')
      })
      .catch(error => {
        console.error("[SignInCallback] Error: ", error)
      })
      .finally(() => {
        window.localStorage.setItem('userLoading', false)
      })
  }, [navigate])

  return (
    <div
      style={{
        color: '#fff',
        textAlign: 'center',
        'margin-top': 24,
        padding: 16
      }}
    >
      Logging in...
    </div>
  )
}

export default SignInCallback
