import { createSlice } from '@reduxjs/toolkit'



export const blockDataSlice = createSlice({
  name: 'blockData',
  initialState: {
    blockData: null
  },
  reducers: {
    setCurrentBlockData: (state,action) => {      
        state.value = action.payload  
    }
  },
})

export const {setCurrentBlockData} = blockDataSlice.actions

export default blockDataSlice.reducer