export const getColorBlockTrendTypeObj = (blockItemObj) => {
    const MCSTAG = blockItemObj.posState ? blockItemObj.posState : "ERROR";
  
    const trendtypeInfo = {
      NEU: {
        code: 100,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "NEUTRAL DIGESTION",
        bgColor: "rgb(118, 63, 24)",
      },
      NPS: {
        code: 110,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "NEUTRAL POSITIVE SHIFT",
          bgColor: "rgb(118, 63, 24)",
      },
      NPT: {
        code: 120,
        type: "TP",
        typeDescription: "TREND",
        mcsDescription: "NEUTRAL POSITIVE TRANSITION",
        bgColor: "rgb(118, 63, 24)",
      },
      NPE: {
        code: 130,
        type: "DVP",
        typeDescription: "NON TREND",
        mcsDescription: "NEUTRAL POSITIVE EXTREME",
        bgColor: "rgb(118, 63, 24)",
      },
      NNS: {
        code: 150,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "NEUTRAL NEGATIVE SHIFT",
          bgColor: "rgb(118, 63, 24)",
      },
      NNT: {
        code: 160,
        type: "TN",
        typeDescription: "TREND",
        mcsDescription: "NEUTRAL NEGATIVE TRANSITION",
          bgColor: "rgb(118, 63, 24)",
      },
      NNE: {
        code: 170,
        type: "DVN",
        typeDescription: "NON TREND",
        mcsDescription: "NEUTRAL NEGATIVE EXTREME",
        bgColor: "rgb(118, 63, 24)",
      },
      UPC: {
        code: 210,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "BULL TREND CORRECTION",
        bgColor: "rgb(255, 102, 255)",
      },
      UPE: {
        code: 220,
        type: "DVP",
        typeDescription: "NON TREND",
        mcsDescription: "BULL TREND EXTREME",
        bgColor: "rgb(0, 176, 240)",
      },
      UPPE: {
        code: 230,
        type: "DVP",
        typeDescription: "NON TREND",
        mcsDescription: "BULL TREND POSITIVE EXTREME",
        bgColor: "rgb(0, 0, 255)",
      },
      UPAE: {
        code: 240,
        type: "DVP",
        typeDescription: "NON TREND",
        mcsDescription: "BULL TREND ACCELERATION EXTREME",
        bgColor: "rgb(0, 255, 0)",
      },
      UPD: {
        code: 250,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "BULL TREND DIGESTION",
          bgColor: "#558ED5",
      },
      UPT: {
        code: 260,
        type: "TP",
        typeDescription: "TREND",
        mcsDescription: "BULL TREND",
        bgColor: "rgb(0, 0, 255)",
      },
      UPP: {
        code: 270,
        type: "TP",
        typeDescription: "TREND",
        mcsDescription: "BULL TREND POSITIVE",
        bgColor: "rgb(0, 0, 255)",
      },
      UPA: {
        code: 280,
        type: "TP",
        typeDescription: "TREND",
        mcsDescription: "BULL TREND ACCELERATION",
        bgColor: "rgb(0, 255, 0)",
      },
      DNA: {
        code: 320,
        type: "TN",
        typeDescription: "TREND",
        mcsDescription: "BEAR TREND ACCELERATION",
        bgColor: "rgb(255, 192, 0)",
      },
      DNN: {
        code: 330,
        type: "TN",
        typeDescription: "TREND",
        mcsDescription: "BEAR TREND NEGATIVE",
        bgColor: "rgb(255, 0, 0)",
      },
      DNT: {
        code: 340,
        type: "TN",
        typeDescription: "TREND",
        mcsDescription: "BEAR TREND",
        bgColor: "rgb(255, 0, 0)",
      },
      DND: {
        code: 350,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "BEAR TREND Digestion",
        bgColor: "rgb(153, 0, 0)",
      },
      DNAE: {
        code: 360,
        type: "DVN",
        typeDescription: "NON TREND",
        mcsDescription: "BEAR TREND ACCELERATION EXTREME",
        bgColor: "rgb(255, 153, 0)",
      },
      DNNE: {
        code: 370,
        type: "DVN",
        typeDescription: "NON TREND",
        mcsDescription: "BEAR TREND NEGATIVE EXTREME",
        bgColor: "rgb(255, 0, 0)",
      },
      DNE: {
        code: 380,
        type: "DVN",
        typeDescription: "NON TREND",
        mcsDescription: "BEAR TREND EXTREME",
        bgColor: "rgb(255, 0, 255)",
      },
      DNC: {
        code: 390,
        type: "NT",
        typeDescription: "NON TREND",
        mcsDescription: "BEAR TREND CORRECTION",
        bgColor: "rgb(0, 255, 255)",
      },
      PE: {
        code: 400,
        type: "DVP",
        typeDescription: "NON TREND",
        mcsDescription: "POSITIVE EXTREME",
        bgColor: "rgb(255, 255, 0)",
      },
      NE: {
        code: 500,
        type: "DVN",
        typeDescription: "NON TREND",
        mcsDescription: "NEGATIVE EXTREME",
        bgColor: "rgb(255, 255, 0)",
      },
      NEUT: {
        code: 900,
        type: "TNT",
          typeDescription: "PIVOTAL",//"TREND NON TREND"
          mcsDescription: "NEUT",//"NEUTRAL DIGESTION TRANSITION"
        bgColor: "rgb(102, 0, 204)",
      },
      NT: {
        code: 1000,
        type: "NT",
        bgColor: "#262626",
      },
      TP: {
        code: 2600,
        type: "TP",
        bgColor: "#6872C2",
      },
      TN: {
        code: 3200,
        type: "TN",
        bgColor: "rgb(153, 0, 0)",
      },
      DVP: {
        code: 4000,
        type: "DVP",
        bgColor: "#FFFF00",
      },
      DVN: {
        code: 5000,
        type: "DVN",
        bgColor: "#FFFF00",
      },
      TNT: {
        code: 5000,
        type: "TNT",
        bgColor: "#FFFF00",
      },
      ERROR: {
        code: "error",
        type: "error",
        bgColor: "#FF0000",
        typeDescription: "error",
        mcsDescription: "error",
      },
    };
    return trendtypeInfo[MCSTAG];
  }
  

  export const getColorBlockRValue=(blockItemObj)=> {
    const R = Number(blockItemObj.r);
    const rValue = {
      r9: "=R9",
      r8: "=R8",
      r7: "=R7",
      r6: "=R6",
      r5: "=R5",
      r4: "=R4",
      r3: "=R3",
      r2: "=R2",
      r1: "=R1",
    };
  
    if (R > Number(blockItemObj.ut1)) return rValue.r9;
    if (R === Number(blockItemObj.ut1)) return rValue.r8;
    if (R === Number(blockItemObj["crxplus"])) return rValue.r7;
    if (R === Number(blockItemObj.up)) return rValue.r6;
    if (R === Number(blockItemObj.dir)) return rValue.r5;
    if (R === Number(blockItemObj.dp)) return rValue.r4;
    if (R === Number(blockItemObj["crxminus"])) return rValue.r3;
    if (R === Number(blockItemObj.dt1)) return rValue.r2;
    if (R < Number(blockItemObj.dt1)) return rValue.r1;
    return "=ERROR";
  }

    export const getSymbol = (name) => {

      const AllSymbols = ["XBT", "BTC", "ETH", "USDT", "USDC", "BNB", "BUSD", "ADA", "XRP", "SOL", "DOGE", "DOT", "DAI", "TRX", "LEO", "SHIB", "WBTC", "AVAX", "MATIC", "UNI", "LTC", "FTT", "LINK", "CRO", "XLM", "NEAR", "ALGO", "BCH", "ATOM", "ETC", "XMR", "VET", "MANA", "FLOW", "HBAR", "ICP", "EGLD", "FIL", "THETA", "APE", "XTZ", "HNT", "SAND", "TUSD", "BSV", "AXS", "KCS", "ZEC", "USDP", "EOS", "MKR", "AAVE", "MIOTA", "HT", "USDN", "GRT", "BTT", "KLAY", "XEC", "USDD", "OKB", "WAVES", "FTM", "QNT", "NEO", "PAXG", "RUNE", "BAT", "CHZ", "ZIL", "LRC", "STX", "DASH", "GMT", "CAKE", "KSM", "FEI", "ENJ", "CELO", "CRV", "GALA", "AMP", "1INCH", "NEXO", "XDC", "HOT", "XEM", "SNX", "KAVA", "MINA", "DCR", "GT", "ONE", "AR", "KDA", "XYM", "QTUM", "GNO", "BTG", "COMP", "BORA", "IOST", "CVX", "AUDIO", "TFUEL", "ROSE", "OMG", "ICX", "STORJ", "TWT", "IOTX", "KNC", "ANKR", "ZRX", "GLM", "SRM", "RVN", "CEL", "JST", "SXP", "ONT", "MXC", "SC", "WOO", "LPT", "BTRST", "YFI", "GLMR", "WAXP", "SKL", "IMX", "BAL", "GUSD", "CHSB", "ENS", "ZEN", "NFT", "HIVE", "POLY", "XCH", "UMA", "LDO", "SCRT", "SUSHI", "DGB", "PLA", "ELON", "CSPR", "RSR", "MX", "LSK", "KEEP", "EPS", "BNT", "RNDR", "RENBTC", "VGX", "ORBS", "PEOPLE", "NU", "PUNDIX", "CKB", "MED", "OCEAN", "ONG", "CEEK", "ACA", "CHR", "REV", "WXT", "SUSD", "CELR", "XPRT", "REN", "XNO", "WIN", "POWR", "FLUX", "ILV", "CFX", "VLX", "ARDR", "SNT", "RLY", "C98", "COTI", "DYDX", "OGN", "MTL", "REP", "TEL", "DAO", "CVC", "DENT", "CTSI", "WRX", "SYS", "STEEM", "DAG", "USTC", "FXS"];
      let coinSymbol = name;
      let currency = "";

      AllSymbols.forEach(theSymbol => {

          if (name.indexOf(theSymbol) === 0 ) {
              coinSymbol = theSymbol;
              currency = name.replace(coinSymbol, '');

              return;
          }

      });
      let symbolObject = {
          coinSymbol: coinSymbol,
          currency: currency
      };
      return symbolObject;
  }

  export const getColorBlockRRisk = (blockData)=>{
    const R = blockData.r;
  
    const rRisk = {
      r9: "6fr 3fr 0px 6fr 10fr 0px",
      r8: "8fr 1fr 0px 6fr 10fr 0px",
      r7: "8fr 1fr 0px 6fr 10fr 0px",
      r6: "9fr 1fr 0px 5fr 10fr 0px",
      r5: "10fr 2fr 1fr 2fr 10fr 0px",
      r4: "10fr 5fr 0px 1fr 9fr 0px",
      r3: "10fr 6fr 0px 1fr 8fr 0px",
      r2: "10fr 6fr 0px 1fr 8fr 0px",
      r1: "10fr 6fr 0px 3fr 6fr 0px",
    };
  
    if (R >= blockData.ut1) return rRisk.r9;
    if (R === blockData.ut1) return rRisk.r8;
    if (R === blockData["crxplus"]) return rRisk.r7;
    if (R === blockData.up) return rRisk.r6;
    if (R === blockData.dir) return rRisk.r5;
    if (R === blockData.dp) return rRisk.r4;
    if (R === blockData["crx"]) return rRisk.r3;
    if (R === blockData.dt1) return rRisk.r2;
    if (R <= blockData.dt1) return rRisk.r1;
  }