import React, { Fragment, useEffect, useState } from "react"
import PlayBookLogo from '../../assets/dashboard/PlayBookLogo.png'
import { authService } from "../../services/AuthService"
import { PathConfig } from "../../shared/app.config"
import './Header.css'

const Header = () => {

  const [dateTime, setDateTime] = useState({ utcDateTime: '', localDateTime: '' })
  const [username , setUsername] = useState(getUserDetails()?.profile?.name || '')
  const [displayType,setDisplayType] = useState("none")

  useEffect(() => {
    const timerId = setInterval(() => {
      const userDetails = getUserDetails()
      const userName = userDetails?.profile?.name
      if (userName) {
        setUsername(userName)
        clearInterval(timerId)
      }
    }, 1000)

    return () => clearInterval(timerId)
  }, [username])

  useEffect(() => {
    const timerId = setInterval(headerdate, 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [dateTime])


  const headerdate = () => {
    var date = new Date()
    // utc
    var utcDateTime = (date.getUTCMonth() + 1) + "/" + (date.getUTCDate()) + "/" + date.getUTCFullYear() + ", " +
      (date.getUTCHours() % 12 === 0 ? 12 : (date.getUTCHours() % 12).toString().length < 2 ?  "0" + date.getUTCHours() % 12 : date.getUTCHours() % 12) + ":" + (date.getUTCMinutes().toString().length < 2 ? "0"+date.getUTCMinutes():date.getUTCMinutes())  + " " + (date.getUTCHours() > 12 ? 'PM' : 'AM')
    // local
    var localDateTime = (date.getMonth() + 1) + "/" + (date.getDate()) + "/" + date.getFullYear() + ", " +
      (date.getHours() % 12 === 0 ? 12 : (date.getHours() % 12).toString().length < 2 ?  "0" + date.getHours() % 12 : date.getHours() % 12) + ":" + (date.getMinutes().toString().length < 2 ? "0"+date.getMinutes() : date.getMinutes()) + " " + (date.getHours() > 12 ? 'PM' : 'AM')

    setDateTime({ utcDateTime: utcDateTime, localDateTime: localDateTime })
  }

  function getUserDetails () {
    let userDetails = JSON.parse(sessionStorage.getItem(`oidc.user:${PathConfig.AUTH_SERVICE_BASE_URL || window.location.origin}:jsservice-web-app`))
    return userDetails
  }

  const onClickName = () => {
    if(displayType === "block"){
      setDisplayType("none")
    }else{
      setDisplayType("block")
    }
  }

  const onClickLogOut = () => {
    authService.logout()
  }

  const onCLickMyAccount = () =>{
    window.location.replace(`${PathConfig.AUTH_SERVICE_BASE_URL}/AccountDetail`)
  }

  return (
    <Fragment>
      <div className="app-header main-header-bar">
        <div className="header-content-div">
        <nav className="">
          <div className="header-bar-inner">
            <div className="time-zone-div">
              <div>
                <span>UTC : </span>
                <span>{dateTime.utcDateTime}</span>
              </div>
              <div>
                <span>Local : </span>
                <span>{dateTime.localDateTime}</span>
              </div>
            </div>
            <a className="main-logo" href="/">
              <img src={PlayBookLogo} alt="Playbook logo" />
            </a>
            <div className="name-log-div">
              <div className="name-div" onClick={()=> onClickName()}>
                {`User: ${username}`}
              </div>
              <div className="logout-div" style={{ display : displayType}}>
                <div onClick={() => onCLickMyAccount()}>My Account</div>
                <hr/>
                <div onClick={()=> onClickLogOut()}>Logout</div>
              </div>
            </div>
          </div>
        </nav>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
